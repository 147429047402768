import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmCancelMembershipDialog from "./cancelMembership/ConfirmCancelMembershipDialog";
import CancelMembershipDialog from "./cancelMembership/CancelMembershipDialog";
import { getUserIdFromCookies } from "../../../../utils/extensions";
import MembershipCard from "../../../../components/MembershipCard";
import { cancelUserSubscription } from "../../../../services/user";

type Props = {
  activeMembershipName: any;
  handleRefresh: any;
  membershipDialog?: any;
  setMembershipDialog: any;
  userSubscriptions: any;
};

const ActiveMembershipCard = (props: Props) => {
  const userId = getUserIdFromCookies();
  const { setMembershipDialog, membershipDialog, userSubscriptions } = props;

  const [subscriptionStatus, setSubscriptionStatus] = useState<string>("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState<any>("");
  const [activeSubscriptions, setActiveSubscriptions] = useState<any>({});

  const [confirmCancelMembershipDialog, setConfirmCancelMembershipDialog] =
    useState<boolean>(false);
  const [activeCancelMembershipDialog, setActiveCancelMembershipDialog] =
    useState<boolean>(false);

  const handleCloseConfirmCancelMembershipDialog = async (res?: any) => {
    if (res?.action === "CANCEL") {
      await cancelUserSubscription({ userId }).catch((err) =>
        console.log("err")
      );

      setActiveCancelMembershipDialog(true);
    }
    setConfirmCancelMembershipDialog(false);
  };

  const handleCloseCancelMembershipDialog = () => {
    setActiveCancelMembershipDialog(false);
    props?.handleRefresh();
  };

  useEffect(() => {
    //Check the subscription status i.e is it Active or Cancelled
    for (let subscription of userSubscriptions) {
      if (subscription?.planType === props?.activeMembershipName) {
        setSubscriptionStatus(subscription?.status);
        setSubscriptionEndDate(subscription?.endDate);
      }
    }
    setActiveSubscriptions(userSubscriptions);
  }, [userSubscriptions]);
  //Stop scrolling when membershipDialog popup is open
  useEffect(() => {
    if (
      membershipDialog ||
      confirmCancelMembershipDialog ||
      activeCancelMembershipDialog
    ) {
      document.body.style.overflow = "hidden";
      document.body.classList.add("no-scroll");
      document.documentElement.classList.add("no-scroll");
    } else {
      document.body.style.overflow = "auto";
      document.body.classList.remove("no-scroll");
      document.documentElement.classList.remove("no-scroll");
    }

    return () => {
      document.body.style.overflow = "auto";
      document.body.classList.remove("no-scroll");
      document.documentElement.classList.remove("no-scroll");
    };
  }, [
    membershipDialog,
    confirmCancelMembershipDialog,
    activeCancelMembershipDialog,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {confirmCancelMembershipDialog && (
        <ConfirmCancelMembershipDialog
          handleDialog={handleCloseConfirmCancelMembershipDialog}
          activeDialog={confirmCancelMembershipDialog}
        />
      )}
      {activeCancelMembershipDialog && (
        <CancelMembershipDialog
          handleDialog={handleCloseCancelMembershipDialog}
          activeDialog={activeCancelMembershipDialog}
        />
      )}
      <MembershipCard
        setMembershipDialog={setMembershipDialog}
        setConfirmCancelMembershipDialog={setConfirmCancelMembershipDialog}
        activePlanName={props?.activeMembershipName}
        subscriptionStatus={subscriptionStatus}
        subscriptionEndDate={subscriptionEndDate}
        activeSubscriptions={activeSubscriptions}
      />
    </Box>
  );
};

export default ActiveMembershipCard;
