import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInput from "../../shared/components/TextInput";
import { EyeLightIcon, EyeOffLightIcon } from "../../assets/icons/icons";
import { resetPassword } from "../../services/authentication";
import { toastError, toastSuccess } from "../../utils/ToastMessage";
import { useTheme } from "@mui/material/styles";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { WikiLogo } from "../../assets/icons/icons";
import { LoginCoverImg } from "../../assets/images/image";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessageIcon from "../../assets/icons/ErrorMessageIcon/ErrorMessageIcon";
import { HomeCoverBg, HomeCoverBgHalf } from "../../assets/images/image";
import UiwikiLogo from "../../assets/icons/wikiLogoSvg/UiwikiLogo";

import loginImage1 from "../../assets/images/LoginImages/loginImage1.jpg";
import loginImage2 from "../../assets/images/LoginImages/loginImage2.jpg";
import loginImage3 from "../../assets/images/LoginImages/loginImage3.jpg";
import loginImage4 from "../../assets/images/LoginImages/loginImage4.jpg";
import loginImage5 from "../../assets/images/LoginImages/loginImage5.jpg";
import loginImage6 from "../../assets/images/LoginImages/loginImage6.jpg";
import loginImage7 from "../../assets/images/LoginImages/loginImage7.jpg";
import useEnterKeyPress from "../../utils/useEnterKeyPress";
import DefaultLoading from "../../shared/Loading/DefaultLoading";

type Props = {};

const ResetPasswordPage = (props: Props) => {
  // start I m taking this portion from ResetPassword.tsx
  const theme = useTheme();
  const navigate = useNavigate();
  const breakpoint_down_769 = useMediaQuery(theme.breakpoints.down(769));
  const [isImagesLoading, setIsImagesLoading] = useState(false);
  // End I m taking this portion from ResetPassword.tsx
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [confirmNewPasswordVisible, setconfirmNewPasswordVisible] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [error, setError] = useState<string | null>(null);

  // For Random Images on UI
  const [randomImageUrl, setRandomImageUrl] = useState<string | null>(null);
  const images = [
    loginImage1,
    loginImage2,
    loginImage3,
    loginImage4,
    loginImage5,
    loginImage6,
    loginImage7,
  ];

  // Function to get a random image
  const getRandomImage = () => {
    setIsImagesLoading(true);
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImageUrl(images[randomIndex]);
    setIsImagesLoading(false);
  };

  useEffect(() => {
    getRandomImage();
  }, []);

  const handleSavePassword = async () => {
    setErrors({});
    setError(null);

    if (!newPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: "New password is required.",
      }));
      return;
    }
    if (!confirmNewPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmNewPassword: "Confirmation password is required.",
      }));
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmNewPassword: "Passwords do not match.",
      }));
      return;
    }

    setErrors({});
    setIsLoading(true);
    await resetPassword({ password: newPassword }, id || "")
      .then((res: any) => {
        if (res?.response?.data?.success || res?.success) {
          toastSuccess("Successfully reset password.", res?.message);
          navigate("/login");
        } else {
          // toastError(
          //   "Ops! Something went wrong.",
          //   res?.response?.data?.message
          // );
          setError(
            res?.response?.data?.message || "Failed to reset the password."
          );
        }
      })
      .catch((err: any) => {
        console.log("err", err);
        // toastError(
        //   "Ops! Something went wrong.",
        //   "Failed to reset the password."
        // );
        setError("Oops! Something went wrong. Failed to reset the password.");
      });

    setNewPassword("");
    setConfirmNewPassword("");
    setIsLoading(false);
  };

  const handleEnterKeyPress = () => {
    !isLoading && handleSavePassword();
  };

  // Use the custom hook
  useEnterKeyPress(handleEnterKeyPress);

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "background.main",
        width: "100%",
        justifyContent: "center",
        maxWidth: "100%",
        minHeight: "100vh",
        height: "100%",
        overflow: "hidden",
        paddingX: { xs: "24px", sm: "0px", lg: "0px" },
        paddingBottom: { xs: "24px", sm: "0px", lg: "0px" },
        // border:"1px solid red"
      }}
    >
      <img
        style={{
          position: "absolute",
          // top: 0,
          // left: 0,
          width: "100vw",
          height: "100vh",
          // height: "1000px",
          objectFit: "cover",
          zIndex: -100,
        }}
        alt="background"
        // src={HomeCoverBg}
        src={HomeCoverBgHalf}
      />
      <Box
        sx={{
          minHeight: { xs: "100vh", lg: "auto" },
          width: "100%",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          // border: "1px solid red",
          // overflow:"hidden",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100vh",
          }}
        > */}
        {/* {!breakpoint_down_769 && (
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: { xs: "90%", md: "101%" },
                height: "100%",
                flex: 1,
              }}
            >
              {!isImagesLoading && (
                <img
                  style={{
                    aspectRatio: "720/679",
                    width: "105%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="signin cover"
                  src={randomImageUrl ? randomImageUrl : LoginCoverImg}
                />
              )}
            </Box>
          )} */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            // flex: 1.5,
            height: "100%",
            // bgcolor: "background.default",
            paddingTop: { xs: "60px", md: "80px" },
            paddingBottom: { xs: "0px", md: "80px" },
            // border:"1px solid red",
            overflowY: "scroll",
            scrollbarWidth: "none",
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "100%", sm: "426px", lg: "426px" },
              // maxHeight: { xs: "auto", sm: "625px", lg: "625px" },
              minHeight: { xs: "auto", sm: "541px", lg: "541px" },
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              position: "relative",
              // border:"1px solid red",
              // paddingX: { xs: "24px", sm: "0px", lg: "0px" },
              // overflowY:"scroll",
              gap: { xs: "24px", sm: "24px", lg: "24px" },
              border: "1px solid #3D3D3D",
              boxSizing: "border-box",
              borderRadius: "24px",
              bgcolor: "#1B1B1B",
              p: { xs: "24px", sm: "40px", lg: "40px" },
            }}
          >
            <Box
              onClick={() => {
                navigate("/");
              }}
              sx={{
                // mb: { xs: "12px", md: "81px" },
                // position: "absolute",
                // top: "60px",
                // width: "100%",
                width: "fit-content",
                // paddingY: { xs: "25px", sm: "0px", lg: "0px" },
                cursor: "pointer",
                // border: "1px solid red",
              }}
            >
              {/* <ImageContainer width={"54px"} height={"54px"} title="logo">
                  {WikiLogo}
                </ImageContainer> */}

              <UiwikiLogo />
            </Box>

            <Box
              sx={{
                margin: "0",
                padding: "0",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "24px", sm: "24px", lg: "24px" },
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                // minHeight: "100vh",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: { xs: "12px", sm: "12px", lg: "12px" },
                  textAlign: "center",
                  width: "100%",
                  // mb: { xs: "24px",sm:"0px",lg:"0px" },
                }}
              >
                <Typography
                  // variant="h3"
                  sx={{
                    fontWeight: "600",
                    color: "text.secondary",
                    fontSize: "24px",
                    lineHeight: "auto",
                    m: "0px",
                    p: "0px",
                  }}
                >
                  Set new password
                </Typography>
                <Typography
                  // variant="subtitle1"
                  sx={{
                    fontWeight: "400",
                    lineHeight: "auto",
                    color: "rgba(255, 255, 255, 0.5)",
                    fontSize: { xs: "14px", sm: "16px", lg: "16px" },
                    m: "0px",
                    p: "0px",
                    width: { xs: "100%", sm: "84%", lg: "84%" },
                    // border:"1px solid red",
                    margin: "0 auto",
                  }}
                >
                  Protect your account with a strong, unique password.
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: { xs: "24px", sm: "32px", lg: "32px" },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      // gap: "5px",
                      // border:"1px solid red"
                    }}
                  >
                    <TextInput
                      id="resetPasswordPageInputs"
                      textinputstyles={{
                        // border:"1px solid black",
                        margin: "0px",
                        padding: "0px",
                        height: "auto",
                        // gap: "11px",
                      }}
                      inputStyles={{
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        paddingLeft: "16px",
                        borderRadius: "14px",
                        height: "54px",
                        backgroundColor: "#1B1B1B",
                        color: "#FFFFFF",
                        border: errors.newPassword
                          ? "1px solid #E25454"
                          : "0px",
                      }}
                      inputWrapStyle={{
                        minWidth: "10px !important",
                        width: "100%",
                      }}
                      lableStyles={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                        lineHeight: "auto",
                      }}
                      labelAstrickStyle={{ color: "#E25454" }}
                      label="New Password"
                      placeholder="Password"
                      value={newPassword}
                      onChange={(e: any) => {
                        setNewPassword(e.target.value);
                      }}
                      icononclick={() =>
                        setNewPasswordVisible(!newPasswordVisible)
                      }
                      iconContainerStyles={{ right: "15px" }}
                      iconstyles={{ width: "24px", height: "24px" }}
                      icon={
                        !newPasswordVisible ? EyeLightIcon : EyeOffLightIcon
                      }
                      required
                      type={!newPasswordVisible ? "password" : "text"}
                    />
                    {errors.newPassword && (
                      <Typography
                        id="loginPassNotExist"
                        className="err_field"
                        color="#E25454"
                        variant="body2"
                        display="flex"
                        gap="4px"
                        marginTop={"12px"}
                        alignItems="center"
                        sx={{ width: "100%" }}
                        fontWeight={"400"}
                        fontSize="14px"
                        lineHeight={"16px"}
                        letterSpacing={"8%"}
                      >
                        <ErrorMessageIcon />
                        {errors.newPassword}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      // gap: "5px",
                      // border:"1px solid red",
                    }}
                  >
                    <TextInput
                      id="resetPasswordPageInputs"
                      textinputstyles={{
                        // border:"1px solid black",
                        margin: "0px",
                        padding: "0px",
                        height: "auto",
                        // gap: "11px",
                      }}
                      inputStyles={{
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        paddingLeft: "16px",
                        borderRadius: "14px",
                        height: "54px",
                        color: "#FFFFFF",
                        backgroundColor: "#1B1B1B",
                        border: errors.confirmNewPassword
                          ? "1px solid #E25454"
                          : "0px",
                      }}
                      inputWrapStyle={{
                        minWidth: "10px !important",
                        width: "100%",
                      }}
                      lableStyles={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                        lineHeight: "auto",
                      }}
                      labelAstrickStyle={{ color: "#E25454" }}
                      label="Confirm Password"
                      placeholder="Password"
                      value={confirmNewPassword}
                      onChange={(e: any) => {
                        setConfirmNewPassword(e.target.value);
                      }}
                      icononclick={() => {
                        setconfirmNewPasswordVisible(
                          !confirmNewPasswordVisible
                        );
                      }}
                      iconContainerStyles={{ right: "15px" }}
                      iconstyles={{ width: "24px", height: "24px" }}
                      icon={
                        !confirmNewPasswordVisible
                          ? EyeLightIcon
                          : EyeOffLightIcon
                      }
                      required
                      type={!confirmNewPasswordVisible ? "password" : "text"}
                    />
                    {errors.confirmNewPassword && (
                      <Typography
                        id="loginPassNotExist"
                        className="err_field"
                        color="#E25454"
                        variant="body2"
                        display="flex"
                        gap="4px"
                        marginTop={"12px"}
                        alignItems="center"
                        sx={{ width: "100%" }}
                        fontWeight={"400"}
                        fontSize="14px"
                        lineHeight={"16px"}
                        letterSpacing={"8%"}
                      >
                        <ErrorMessageIcon />
                        {errors.confirmNewPassword}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box sx={{ width: "100%" }}>
                  <Button
                    sx={{
                      // mt: { xs: "16px", sm: "0px", lg: "0px" },
                      width: "100%",
                      textAlign: "center",
                      textTransform: "none",
                      bgcolor: "#CCFF00",
                      border: "1px solid #718B08",
                      color: "#FFFFFF",
                      borderRadius: "14px",
                      height: "54px",
                      paddingY: "16px",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    onClick={() => {
                      !isLoading && handleSavePassword();
                    }}
                    endIcon={
                      isLoading && (
                        <Box
                          sx={{
                            width: "42px",
                            height: "42px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <DefaultLoading width="42px" height="42px" />
                        </Box>
                      )
                    }
                  >
                    <Typography
                      sx={{
                        color: "text.primary",
                        fontSize: "16px !important",
                        fontWeight: "600",
                        lineHeight: "auto",
                      }}
                      // variant="subtitle1"
                    >
                      {!isLoading && "Reset Password"}
                    </Typography>
                  </Button>
                  {error && (
                    <Typography
                      id="apiError"
                      className="err_field"
                      color="#E25454"
                      variant="body2"
                      display="flex"
                      gap="4px"
                      marginTop={"12px"}
                      alignItems="center"
                      sx={{ width: "100%" }}
                      fontWeight={"400"}
                      fontSize="14px"
                      lineHeight={"16px"}
                      letterSpacing={"8%"}
                    >
                      <ErrorMessageIcon />
                      {error}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default ResetPasswordPage;
