import React from "react";

const CopyComponentSvgIcon = () => {
  return (
    <svg
      width="95"
      height="95"
      viewBox="0 0 95 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.582 19.9773C39.8323 18.4105 40.2059 17.0361 40.7208 15.8327C43.4168 9.53117 49.4779 7.91602 63.0184 7.91602C83.1237 7.91602 87.082 15.8327 87.082 32.0385C87.082 43.541 86.1995 52.2513 79.1654 54.4685C77.9775 54.843 76.6143 55.1456 75.0503 55.416"
        stroke="#212121"
        stroke-width="5.9375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.6263 87.0827C55.418 87.0827 63.3346 81.1452 63.3346 59.3743C63.3346 37.6035 55.418 31.666 35.6263 31.666C15.8346 31.666 7.91797 36.2841 7.91797 59.3743C7.91797 82.4646 15.8346 87.0827 35.6263 87.0827Z"
        stroke="#212121"
        stroke-width="5.9375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.7083 35.625C27.7083 35.625 30.1014 45.1069 25.7292 49.4792C21.3569 53.8514 11.875 51.4583 11.875 51.4583"
        stroke="#212121"
        stroke-width="5.9375"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CopyComponentSvgIcon;
