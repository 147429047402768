import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { AlertTriangleIconSvg } from "../../assets/icons/AlertTriangleIconSvg";
import UseRadioGroup from "../../shared/components/RadioInput";
import TextAreaInput from "../../shared/components/TextAreaInput";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../shared/components/button/Buttons";
import { createComponentReport } from "../../services/component";
import { useGlobalContext } from "../../App";
import { toastError, toastSuccess } from "../../utils/ToastMessage";
import ErrorMessageIcon from "../../assets/icons/ErrorMessageIcon/ErrorMessageIcon";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  data: any;
};

const ReportDialog: React.FC<Props> = (props) => {
  const { userDetails } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const [reason, setReason] = useState<any>("");
  const [description, setDescription] = useState<string>("");
  const [reasonErrorMessage, setReasonErrorMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmitReport = async () => {
    if (!reason) {
      setReasonErrorMessage("Please select a reason for reporting.");
      return;
    } else {
      setReasonErrorMessage("");
    }
    if (!description.trim()) {
      setErrorMessage(
        "Please provide a detailed description of the issue you're reporting."
      );
      return;
    }
    setErrorMessage("");

    setLoading(true);
    await createComponentReport({
      title: reason,
      description: description,
      userId: userDetails?.id,
      componentId: props?.data?.id,
    })
      .then((res: any) => {
        toastSuccess("Report Submitted", "Your report has been successfully submitted. Thanks for helping us.");
      })
      .catch((err: any) => {
        // toastError("Ops! Something went wrong.", "Failed to submit report.");
        setErrorMessage(
          "Submission Failed, Oops! Something went wrong. Please try again later."
        );
      });
    setLoading(false);
    props?.handleDialog();
  };

  useEffect(() => {}, [props?.activeDialog]);
  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000, // Set zIndex below the dialog
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          <Box
            sx={{
              width: { xs: "90vw", sm: "610px", lg: "610px" },
              // maxWidth: "777px",
              maxWidth: "610px",
              padding: { xs: "24px", sm: "24px", lg: "24px" },
              height: "auto",
              // height: "634px",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              {/* Title section */}
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  mb: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "16px",
                  }}
                >
                  <AlertTriangleIconSvg
                    width="32"
                    height="32"
                    color="#CCFF00"
                  />
                  <Typography
                    sx={{
                      color: "text.secondary",
                      fontSize: "24px !important",
                      lineHeight: "28px",
                      fontWeight: "600",
                    }}
                  >
                    Report a problem
                  </Typography>
                </Box>

                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.handleDialog();
                  }}
                  sx={{
                    position: "absolute",
                    right: "24px",
                    top: "24px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // gap: "24px",
                  width: "100%",
                }}
              >
                <Typography
                  // variant="subtitle1"
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#FFFFFF",
                    opacity: "50%",
                    lineHeight: "auto",
                  }}
                >
                  Select a reason to reports this
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: { xs: "24px", sm: "24px", lg: "24px" },
                    mb: { xs: "24px", sm: "32.5px", lg: "32.5px" },
                  }}
                >
                  <UseRadioGroup
                    style={{
                      flexDirection: "column",
                    }}
                    radioInupStyle={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "start",
                      width: "100%",
                      border: "none",
                    }}
                    labelFontSize="16px"
                    radioValues={[
                      "Irrelevant Result/Incorrect tags",
                      "Copyright/Trademark violation",
                      "Wrong file or quality issue after downloading",
                      "Other",
                    ]}
                    radiosCaptions={[
                      "Irrelevant Result/Incorrect tags",
                      "Copyright/Trademark violation",
                      "Wrong file or quality issue after downloading",
                      "Other",
                    ]}
                    value={reason}
                    onChange={(e: any) => {
                      setReason(e);
                    }}
                  />
                  {reasonErrorMessage && (
                    <Typography
                      className="err_field"
                      id="reportNotExist"
                      color="#E25454"
                      variant="body2"
                      display={"flex"}
                      gap={"4px"}
                      marginTop={"12px"}
                      alignItems={"center"}
                      sx={{ width: "100%" }}
                      fontWeight={"400"}
                      fontSize="14px"
                      lineHeight={"16px"}
                      letterSpacing={"8%"}
                    >
                      <span>
                        <ErrorMessageIcon />
                      </span>
                      {reasonErrorMessage}
                    </Typography>
                  )}
                </Box>

                <Box>
                  <Box>
                    <TextAreaInput
                      id="reportDescriptionInput"
                      textinputstyles={{ gap: "12px" }}
                      inputStyles={{
                        height: "206px",
                        fontSize: "14px !important", // globally set index.css
                        lineHeight: "24px",
                        outline: "none",
                        padding: "16px",
                        borderRadius: "14px",
                        backgroundcolor: "#282828",
                      }}
                      placeholderStyle={{
                        fontSize: "16px !important",
                        lineHeight: "24px",
                      }}
                      value={description}
                      onChange={(e: any) => {
                        setDescription(e.target.value);
                      }}
                      placeholder="Describe your issue in details"
                      label="Description"
                    />
                    {errorMessage && (
                      <Typography
                        className="err_field"
                        id="reportNotExist"
                        color="#E25454"
                        variant="body2"
                        display={"flex"}
                        gap={"4px"}
                        marginTop={"12px"}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                        fontWeight={"400"}
                        fontSize="14px"
                        lineHeight={"16px"}
                        letterSpacing={"8%"}
                      >
                        {errorMessage && (
                          <>
                            <span>
                              <ErrorMessageIcon />
                            </span>
                            {errorMessage}
                          </>
                        )}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: { xs: "column", sm: "row", lg: "row" },
                      gap: { xs: "8px", md: "10px" },
                      mt: { xs: "24px", sm: "32.5px", lg: "32.5px" },
                      // border:"1px solid red",
                      maxWidth: { xs: "100%", sm: "342px", lg: "342px" },
                      width: "100%",
                      margin: "0 auto",
                    }}
                  >
                    <ButtonSecondary
                      onClick={(e) => {
                        e.stopPropagation();
                        props?.handleDialog();
                      }}
                      sx={{
                        height: "52px",
                        width: "100%",
                        padding: "15px 16px",
                        background: "transparent",
                        borderRadius: "14px",
                      }}
                      LabelStyle={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                        lineHeight: "auto",
                      }}
                      label="Cancel"
                    />
                    <ButtonPrimary
                      sx={{
                        height: "52px",
                        width: "100%",
                        padding: "15px 16px",
                        borderRadius: "14px",
                        border: "1px solid #526600",
                      }}
                      LabelStyle={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                        lineHeight: "auto",
                      }}
                      label="Submit"
                      isLoading={loading}
                      onClick={() => {
                        handleSubmitReport();
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default ReportDialog;
