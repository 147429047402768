import React from "react";

const RightTick = ({width , height}) => {
  return (
    <svg
      width={width || "213"}
      height={height || "213"}
      viewBox="0 0 213 213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M182.497 55.0766L104.491 129.886L79.4782 96.7637C78.7987 95.2224 77.0686 94.4499 75.5219 94.1316C73.8738 93.7923 71.9888 93.8674 70.6301 94.3946C69.1863 94.9547 68.0233 96.2686 67.3429 97.68C66.6645 99.0873 66.3567 100.822 66.9333 102.308L66.9724 102.409L67.0323 102.5L96.0911 146.286C96.7436 147.869 98.1059 149.051 99.7653 149.473C99.8 149.482 99.8347 149.49 99.8686 149.498L99.8698 149.499C101.613 149.906 103.445 149.426 104.766 148.217L104.769 148.214L194.21 65.4869C194.211 65.4862 194.212 65.4855 194.212 65.4847C195.51 64.2938 195.587 62.334 195.295 60.7409C194.991 59.0881 194.216 57.3726 193.237 56.3046L193.237 56.3046C192.679 55.6951 191.845 55.2241 190.974 54.8754C190.083 54.5188 189.063 54.2541 188.046 54.1058C187.031 53.9579 185.983 53.9205 185.043 54.0422C184.128 54.1606 183.184 54.4466 182.514 55.061L182.505 55.0687L182.497 55.0766Z"
        fill="#CCFF00"
        stroke="#CCFF00"
        stroke-width="2"
      />
      <path
        d="M156.16 154.476L161.212 148.875C170.811 136.646 176.019 121.546 176 106C176 92.1553 171.895 78.6215 164.203 67.1101C156.511 55.5987 145.579 46.6266 132.788 41.3285C119.997 36.0303 105.922 34.6441 92.3437 37.3451C78.765 40.046 66.2922 46.7129 56.5025 56.5025C46.7129 66.2922 40.046 78.765 37.3451 92.3437C34.6441 105.922 36.0303 119.997 41.3285 132.788C46.6266 145.579 55.5987 156.511 67.1101 164.203C78.6215 171.895 92.1553 176 106 176C121.546 176.019 136.646 170.811 148.875 161.212L156.16 154.476ZM53.5 106C53.5 95.6165 56.5791 85.4661 62.3479 76.8326C68.1166 68.199 76.316 61.4699 85.9091 57.4963C95.5022 53.5227 106.058 52.4831 116.242 54.5088C126.426 56.5345 135.781 61.5347 143.123 68.8769C150.465 76.2192 155.465 85.5738 157.491 95.7578C159.517 105.942 158.477 116.498 154.504 126.091C150.53 135.684 143.801 143.883 135.167 149.652C126.534 155.421 116.384 158.5 106 158.5C92.0761 158.5 78.7226 152.969 68.8769 143.123C59.0313 133.277 53.5 119.924 53.5 106Z"
        fill="white"
        fill-opacity="0.06"
      />
      <path
        d="M175.438 106V106.001C175.458 121.414 170.296 136.386 160.783 148.513L155.76 154.081L148.51 160.785C136.384 170.297 121.413 175.458 106.001 175.438H106C92.2664 175.438 78.8411 171.366 67.422 163.736C56.003 156.106 47.1028 145.261 41.8472 132.573C36.5916 119.885 35.2165 105.923 37.8958 92.4532C40.5751 78.9835 47.1884 66.6107 56.8996 56.8996C66.6107 47.1884 78.9835 40.5751 92.4532 37.8958C105.923 35.2165 119.885 36.5916 132.573 41.8472C145.261 47.1028 156.106 56.003 163.736 67.422C171.366 78.8411 175.438 92.2664 175.438 106ZM61.881 76.5206C56.0505 85.2465 52.9385 95.5054 52.9385 106C52.9385 120.073 58.5289 133.569 68.4799 143.52C78.4308 153.471 91.9272 159.061 106 159.061C116.495 159.061 126.753 155.949 135.479 150.119C144.205 144.289 151.006 136.001 155.022 126.306C159.038 116.61 160.089 105.941 158.042 95.6482C155.995 85.3553 150.941 75.9007 143.52 68.4799C136.099 61.0591 126.645 56.0055 116.352 53.9581C106.059 51.9107 95.39 52.9615 85.6942 56.9776C75.9985 60.9937 67.7115 67.7947 61.881 76.5206Z"
        stroke="white"
        stroke-opacity="0.12"
        stroke-width="1.12299"
      />
    </svg>
  );
};

export default RightTick;
