import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import PremiumIconSvg from "../assets/icons/PremiumIcon/PremiumIconSvg";
import { ImageContainer } from "../shared/components/ImageContainer";
import pricing_cover from "../assets/images/PricingPageImages/pricing_cover.png";
import PricingPageTopMobileImg from "../assets/images/PricingPageImages/PricingPageTopNewMobileImg.png";
import ExploreUIComponentImgPng from "../assets/images/PricingPageImages/ExploreUIComponentNewImg.png";
import ExploreUIComponentMobileImg from "../assets/images/PricingPageImages/ExploreUIcomponentNewMobileImg.png";
import CopyComponentImgPng from "../assets/images/PricingPageImages/CopyComponentNewImg.png";
import CopyComponentMobileImg from "../assets/images/PricingPageImages/CopyComponentNewMobileImg.png";
import PasteInFigmaImgPng from "../assets/images/PricingPageImages/PasteInFigmaNewImg.png";
import PasteInFigmaMobileImg from "../assets/images/PricingPageImages/PasteInFigmaNewMobileImg.png";
import CustomizeImgPng from "../assets/images/PricingPageImages/CustomizeNewImg.png";
import CustomizeMobileImg from "../assets/images/PricingPageImages/CustomizeNewMobileImg.png";
import Chip from "../shared/components/Chip";
import ExploreComponentStackSvg from "../assets/icons/PricingPageSvgImages/ExploreComponentStackSvg";
import CopyComponentSvgIcon from "../assets/icons/PricingPageSvgImages/CopyComponentSvgIcon";
import PasteComponentInFigmaSvgIcon from "../assets/icons/PricingPageSvgImages/PasteComponentInFigmaSvgIcon";
import EditComponentSvgIcon from "../assets/icons/PricingPageSvgImages/EditComponentSvgIcon";
import NewsletterSection from "../main/NewsletterSection";
import NewPricingPageMembershipPlanCards from "./setting/tabs/membership/newPricingPageMembershipPlans/NewPricingPageMembershipPlanCards";
import { useTheme } from "@mui/material/styles";
import { getUserIdFromCookies } from "../utils/extensions";
import { getUserActiveSubscription } from "../services/component";

const PricingNewScreen = () => {
  const theme = useTheme();
  const breakpoint_up_800 = useMediaQuery(theme.breakpoints.up(800));
  const breakpoint_down_1100 = useMediaQuery(theme.breakpoints.down(1100));
  const breakpoint_down_1280 = useMediaQuery(theme.breakpoints.down(1280));

  const [activePlan, setActivePlan] = useState<any>({});
  const [activeSubscriptions, setActiveSubscriptions] = useState<any>({});

  const userId = getUserIdFromCookies();
  const getSubscriptions = async () => {
    if (userId) {
      await getUserActiveSubscription(userId)
        .then((res: any) => {
          setActivePlan(res?.activeSubscriptionName);
          setActiveSubscriptions(res?.userSubscriptions);
        })
        .catch((err) => console.log("Error in getting subscriptions"));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getSubscriptions();
  }, []);

  return (
    <>
      <Box
        sx={{
          // border: "1px solid red",
          height: "auto",
          padding: breakpoint_down_1280
            ? "24px 40px 40px 40px"
            : "24px 80px 40px 80px",
          // : "10px 10px 40px 10px",
          margin: "0 auto",
          maxWidth: "1440px",
          "@media (max-width: 550px)": {
            padding: "10px 20px 40px 20px",
          },
        }}
      >
        {breakpoint_up_800 ? (
          <>
            {" "}
            <Box
              sx={{
                // border: "1px solid green",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  // maxHeight: "362px",
                  height: "auto",
                  // border: "1px solid green",
                }}
              >
                <Box
                  sx={{
                    // border: "1px solid red",
                    flex: 2,
                    borderRadius: "20px",
                    // height: "362px",
                    // minHeight: "200px",
                    // minWidth: "382px",
                  }}
                >
                  <ImageContainer
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "20px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    imageContainerStyles={{
                      backgroundColor: "#181818",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    title="Cover Image"
                    height={"100%"}
                  >
                    {pricing_cover}
                  </ImageContainer>
                </Box>
                <Box
                  sx={{
                    // border: "1px solid red",
                    display: "flex",
                    flex: 1,
                    // maxWidth: "420px",
                    borderRadius: "20px",
                    bgcolor: "#181818",
                    // justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "314px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      // border: "1px solid red",
                      margin: "119px 54px 120px 52px",
                      "@media (min-width: 800px) and (max-width: 1100px)": {
                        margin: "45px 39px 46px 24px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "32px",
                        "@media (min-width: 800px) and (max-width: 918px)": {
                          fontSize: "24px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        UI Wiki Pro
                        <PremiumIconSvg />
                      </Box>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8B8B8B",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      Boost your productivity with instant access to our library
                      of over 2500 components, and new components added daily.
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Price Cards */}
              <Box
                sx={{
                  // maxHeight: "554px",
                  // height: "100%",
                  maxHeight: "auto",
                  height: "auto",
                  // border: "1px solid green",
                  // display: "flex",
                  // flexDirection: "column",
                  // alignItems: "center",
                  // justifyContent: "center",
                }}
              >
                <NewPricingPageMembershipPlanCards
                  activePlan={activePlan}
                  activeSubscriptions={activeSubscriptions}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  // height: "362px",
                  // border: "1px solid green",
                }}
              >
                <Box
                  sx={{
                    // border: "1px solid red",
                    flex: 1,
                    // maxWidth:"420px",
                    borderRadius: "20px",
                    // height: "362px",
                    // minWidth: "382px",
                  }}
                >
                  <ImageContainer
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "20px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    imageContainerStyles={{
                      backgroundColor: "#181818",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    title="pricingPage Top Img"
                    height={"100%"}
                  >
                    {ExploreUIComponentImgPng}
                  </ImageContainer>
                </Box>
                <Box
                  sx={{
                    // border: "1px solid red",
                    display: "flex",
                    flex: 2,
                    borderRadius: "20px",
                    bgcolor: "#181818",
                    // justifyContent: "center",
                    // alignItems: "center",
                    // padding: "111px 88px 112px 80px",
                  }}
                >
                  <Box
                    sx={{
                      width: "fit-content",
                      // border: "1px solid red",
                      display: "flex",
                      gap: "64px",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "111px 88px 112px 80px",
                      "@media (min-width: 800px) and (max-width: 1100px)": {
                        margin: "65px 42px 66px 30px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "511px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Chip
                        sx={{
                          height: "auto",
                          borderRadius: "7px",
                          width: "73px",
                          padding: "6px 14px",
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          boxShadow: "none",
                        }}
                        titleStyles={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "0%",
                          color: "#8B8B8B",
                        }}
                        title="Explore"
                      />
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontWeight: "600",
                          fontSize: "32px",
                        }}
                      >
                        Explore UI Components
                      </Typography>
                      <Typography
                        sx={{
                          color: "#8B8B8B",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        Browse over 2500 premium Figma components, from simple
                        header to full layout templates - systematically
                        organized for quick discovery.
                      </Typography>
                    </Box>
                    {!breakpoint_down_1100 && (
                      <Box>
                        <ExploreComponentStackSvg />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  // height: "362px",
                  // border: "1px solid green",
                }}
              >
                <Box
                  sx={{
                    // border: "1px solid red",
                    display: "flex",
                    flex: 2,
                    borderRadius: "20px",
                    bgcolor: "#181818",
                    // justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "fit-content",
                      // border: "1px solid red",
                      display: "flex",
                      gap: "50px",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "111px 87px 112px 80px",
                      "@media (min-width: 800px) and (max-width: 1100px)": {
                        margin: "65px 42px 66px 30px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "511px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Chip
                        sx={{
                          height: "auto",
                          borderRadius: "7px",
                          // width: "73px",
                          padding: "6px 14px",
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          boxShadow: "none",
                        }}
                        titleStyles={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "0%",
                          color: "#8B8B8B",
                        }}
                        title="Copy"
                      />
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontWeight: "600",
                          fontSize: "32px",
                        }}
                      >
                        Copy in a Single Click
                      </Typography>
                      <Typography
                        sx={{
                          color: "#8B8B8B",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        Grab your chosen component instantly—no extra steps
                        required. With one tap, it’s on your clipboard and ready
                        to be added into any Figma project.
                      </Typography>
                    </Box>
                    {!breakpoint_down_1100 && (
                      <Box>
                        <CopyComponentSvgIcon />
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    // border: "1px solid red",
                    flex: 1,
                    borderRadius: "20px",
                    // height: "362px",
                    // minWidth: "382px",
                  }}
                >
                  <ImageContainer
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "20px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    imageContainerStyles={{
                      backgroundColor: "#181818",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    title="pricingPage Top Img"
                    height={"100%"}
                  >
                    {CopyComponentImgPng}
                  </ImageContainer>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  // height: "362px",
                  // border: "1px solid green",
                }}
              >
                <Box
                  sx={{
                    // border: "1px solid red",
                    flex: 1,
                    borderRadius: "20px",
                    // height: "362px",
                    // minWidth: "382px",
                  }}
                >
                  <ImageContainer
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "20px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    imageContainerStyles={{
                      backgroundColor: "#181818",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    title="pricingPage Top Img"
                    height={"100%"}
                  >
                    {PasteInFigmaImgPng}
                  </ImageContainer>
                </Box>
                <Box
                  sx={{
                    // border: "1px solid red",
                    display: "flex",
                    flex: 2,
                    borderRadius: "20px",
                    bgcolor: "#181818",
                    // justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "fit-content",
                      // border: "1px solid red",
                      display: "flex",
                      gap: "64px",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "111px 87px 112px 80px",
                      "@media (min-width: 800px) and (max-width: 1100px)": {
                        margin: "65px 42px 66px 30px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "511px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Chip
                        sx={{
                          height: "auto",
                          borderRadius: "7px",
                          // width: "73px",
                          padding: "6px 14px",
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          boxShadow: "none",
                        }}
                        titleStyles={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "0%",
                          color: "#8B8B8B",
                        }}
                        title="Paste"
                      />
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontWeight: "600",
                          fontSize: "32px",
                        }}
                      >
                        Paste into Figma Instantly
                      </Typography>
                      <Typography
                        sx={{
                          color: "#8B8B8B",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        Simply open Figma and drop the copied design right where
                        you need it. Skip the drag-and-drop hassle and jump
                        straight into creating.
                      </Typography>
                    </Box>
                    {!breakpoint_down_1100 && (
                      <Box>
                        <PasteComponentInFigmaSvgIcon />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  // height: "362px",
                  // border: "1px solid green",
                }}
              >
                <Box
                  sx={{
                    // border: "1px solid red",
                    display: "flex",
                    flex: 2,
                    borderRadius: "20px",
                    bgcolor: "#181818",
                    // justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "fit-content",
                      // border: "1px solid red",
                      display: "flex",
                      gap: "47px",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "111px 53px 112px 80px",
                      "@media (min-width: 800px) and (max-width: 1100px)": {
                        margin: "65px 42px 66px 30px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "530px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <Chip
                        sx={{
                          height: "auto",
                          borderRadius: "7px",
                          // width: "73px",
                          padding: "6px 14px",
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          boxShadow: "none",
                        }}
                        titleStyles={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "0%",
                          color: "#8B8B8B",
                        }}
                        title="Edit"
                      />
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontWeight: "600",
                          fontSize: "32px",
                        }}
                      >
                        Customize to Perfection
                      </Typography>
                      <Typography
                        sx={{
                          color: "#8B8B8B",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        Adjust colors, tweak layouts, and fine-tune every detail
                        to suit your project’s style. Everything remains fully
                        editable, giving you total creative control.
                      </Typography>
                    </Box>
                    {!breakpoint_down_1100 && (
                      <Box>
                        <EditComponentSvgIcon />
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    // border: "1px solid red",
                    flex: 1,
                    borderRadius: "20px",
                    // height: "362px",
                    // minWidth: "382px",
                  }}
                >
                  <ImageContainer
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "20px",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                    imageContainerStyles={{
                      backgroundColor: "#181818",
                      borderRadius: "20px",
                      overflow: "hidden",
                    }}
                    title="pricingPage Top Img"
                    height={"100%"}
                  >
                    {CustomizeImgPng}
                  </ImageContainer>
                </Box>
              </Box>
            </Box>
            {/* -----Newsletter Section----- */}
            <Box sx={{ width: "100%", mt: "80px" }}>
              <NewsletterSection />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              // border: "1px solid green",
              display: "flex",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // gap: "10px",
                // maxHeight: "362px",
                height: "auto",
                position: "relative",
                // border: "1px solid green",
              }}
            >
              <Box
                sx={{
                  // border: "1px solid red",
                  flex: 1,
                  borderRadius: "20px",
                  // height: "362px",
                  // minHeight: "200px",
                  // minWidth: "382px",
                }}
              >
                <ImageContainer
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  imageContainerStyles={{
                    backgroundColor: "#181818",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                  title="pricingPage Top Img"
                  height={"100%"}
                >
                  {PricingPageTopMobileImg}
                </ImageContainer>
              </Box>
              <Box
                sx={{
                  // border: "1px solid red",
                  // display: "flex",
                  // flex: 1,
                  width: "100%",
                  height: "206px",
                  borderRadius: "20px",
                  // bgcolor: "#181818",
                  // justifyContent: "center",
                  // alignItems: "center",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <Box
                  sx={{
                    // maxWidth: "314px",
                    // width: "100%",
                    width: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    // border: "1px solid red",
                    margin: "69px 44px 24px 24px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: "600",
                      fontSize: "24px",
                      lineHeight: "auto",
                      letterSpacing: "0%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      UI Wiki Pro
                      <PremiumIconSvg />
                    </Box>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#8B8B8B",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      "@media (max-width: 390px)": {
                        fontSize: "14px",
                      },
                      "@media (max-width: 346px)": {
                        fontSize: "13px",
                      },
                    }}
                  >
                    Boost your productivity with instant access to our library
                    of over 2500 components, and new components added daily.
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Price Cards */}
            <Box
              sx={{
                // maxHeight: "554px",
                // height: "100%",
                maxHeight: "auto",
                height: "auto",
                // border: "1px solid green",
                // display: "flex",
                // flexDirection: "column",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <NewPricingPageMembershipPlanCards
                activePlan={activePlan}
                activeSubscriptions={activeSubscriptions}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                // gap: "10px",
                // height: "362px",
                // border: "1px solid green",
                height: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  // border: "1px solid red",
                  flex: 1,
                  // maxWidth:"420px",
                  borderRadius: "20px",
                  // height: "362px",
                  // minWidth: "382px",
                }}
              >
                <ImageContainer
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  imageContainerStyles={{
                    backgroundColor: "#181818",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                  title="pricingPage Top Img"
                  height={"100%"}
                >
                  {ExploreUIComponentMobileImg}
                </ImageContainer>
              </Box>
              <Box
                sx={{
                  // border: "1px solid red",
                  // display: "flex",
                  // flex: 2,
                  borderRadius: "20px",
                  width: "100%",
                  height: "225px",
                  // bgcolor: "#181818",
                  // justifyContent: "center",
                  // alignItems: "center",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <Box
                  sx={{
                    width: "fit-content",
                    // border: "1px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "24px 44px 24px 24px",
                  }}
                >
                  <Box
                    sx={{
                      // maxWidth: "511px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Chip
                      sx={{
                        height: "auto",
                        borderRadius: "7px",
                        width: "73px",
                        padding: "6px 14px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        boxShadow: "none",
                      }}
                      titleStyles={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0%",
                        color: "#FFFFFF",
                      }}
                      title="Explore"
                    />
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "auto",
                        letterSpacing: "0%",
                        "@media (max-width: 365px)": {
                          fontSize: "18px",
                        },
                      }}
                    >
                      Explore UI Components
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8B8B8B",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        "@media (max-width: 390px)": {
                          fontSize: "14px",
                        },
                        "@media (max-width: 346px)": {
                          fontSize: "13px",
                        },
                      }}
                    >
                      Browse over 2500 premium Figma components, from simple
                      header to full layout templates - systematically organized
                      for quick discovery.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                // gap: "10px",
                // height: "362px",
                // border: "1px solid green",
                height: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  // border: "1px solid red",
                  // display: "flex",
                  // flex: 2,
                  borderRadius: "20px",
                  width: "100%",
                  height: "225px",
                  // bgcolor: "#181818",
                  // justifyContent: "center",
                  // alignItems: "center",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <Box
                  sx={{
                    width: "fit-content",
                    // border: "1px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "24px 44px 24px 24px",
                  }}
                >
                  <Box
                    sx={{
                      // maxWidth: "511px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Chip
                      sx={{
                        height: "auto",
                        borderRadius: "7px",
                        // width: "73px",
                        padding: "6px 14px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        boxShadow: "none",
                      }}
                      titleStyles={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0%",
                        color: "#FFFFFF",
                      }}
                      title="Copy"
                    />
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "auto",
                        letterSpacing: "0%",
                        "@media (max-width: 365px)": {
                          fontSize: "18px",
                        },
                      }}
                    >
                      Copy in a Single Click
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8B8B8B",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        "@media (max-width: 390px)": {
                          fontSize: "14px",
                        },
                        "@media (max-width: 346px)": {
                          fontSize: "13px",
                        },
                      }}
                    >
                      Grab your chosen component instantly—no extra steps
                      required. With one tap, it’s on your clipboard and ready
                      to be added into any Figma project.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  // border: "1px solid red",
                  flex: 1,
                  borderRadius: "20px",
                  // height: "362px",
                  // minWidth: "382px",
                }}
              >
                <ImageContainer
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  imageContainerStyles={{
                    backgroundColor: "#181818",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                  title="pricingPage Top Img"
                  height={"100%"}
                >
                  {CopyComponentMobileImg}
                </ImageContainer>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                // gap: "10px",
                // height: "362px",
                // border: "1px solid green",
                height: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  // border: "1px solid red",
                  flex: 1,
                  borderRadius: "20px",
                  // height: "362px",
                  // minWidth: "382px",
                }}
              >
                <ImageContainer
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  imageContainerStyles={{
                    backgroundColor: "#181818",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                  title="pricingPage Img"
                  height={"100%"}
                >
                  {PasteInFigmaMobileImg}
                </ImageContainer>
              </Box>
              <Box
                sx={{
                  // border: "1px solid red",
                  // display: "flex",
                  // flex: 2,
                  borderRadius: "20px",
                  width: "100%",
                  height: "225px",
                  // bgcolor: "#181818",
                  // justifyContent: "center",
                  // alignItems: "center",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <Box
                  sx={{
                    width: "fit-content",
                    // border: "1px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "24px 44px 24px 24px",
                  }}
                >
                  <Box
                    sx={{
                      // maxWidth: "511px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Chip
                      sx={{
                        height: "auto",
                        borderRadius: "7px",
                        // width: "73px",
                        padding: "6px 14px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        boxShadow: "none",
                      }}
                      titleStyles={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0%",
                        color: "#FFFFFF",
                      }}
                      title="Paste"
                    />
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "auto",
                        letterSpacing: "0%",
                        "@media (max-width: 365px)": {
                          fontSize: "18px",
                        },
                      }}
                    >
                      Paste into Figma Instantly
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8B8B8B",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        "@media (max-width: 390px)": {
                          fontSize: "14px",
                        },
                        "@media (max-width: 346px)": {
                          fontSize: "13px",
                        },
                      }}
                    >
                      Simply open Figma and drop the copied design right where
                      you need it. Skip the drag-and-drop hassle and jump
                      straight into creating.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                // gap: "10px",
                // height: "362px",
                // border: "1px solid green",
                height: "auto",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  // border: "1px solid red",
                  // display: "flex",
                  // flex: 2,
                  borderRadius: "20px",
                  width: "100%",
                  height: "225px",
                  // bgcolor: "#181818",
                  // justifyContent: "center",
                  // alignItems: "center",
                  position: "absolute",
                  bottom: "0",
                }}
              >
                <Box
                  sx={{
                    width: "fit-content",
                    // border: "1px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "24px 44px 24px 24px",
                  }}
                >
                  <Box
                    sx={{
                      // maxWidth: "530px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <Chip
                      sx={{
                        height: "auto",
                        borderRadius: "7px",
                        // width: "73px",
                        padding: "6px 14px",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        boxShadow: "none",
                      }}
                      titleStyles={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "0%",
                        color: "#FFFFFF",
                      }}
                      title="Edit"
                    />
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: "600",
                        fontSize: "24px",
                        lineHeight: "auto",
                        letterSpacing: "0%",
                        "@media (max-width: 365px)": {
                          fontSize: "18px",
                        },
                      }}
                    >
                      Customize to Perfection
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8B8B8B",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        "@media (max-width: 390px)": {
                          fontSize: "14px",
                        },
                        "@media (max-width: 346px)": {
                          fontSize: "13px",
                        },
                      }}
                    >
                      Adjust colors, tweak layouts, and fine-tune every detail
                      to suit your project’s style. Everything remains fully
                      editable, giving you total creative control.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  // border: "1px solid red",
                  flex: 1,
                  borderRadius: "20px",
                  // height: "362px",
                  // minWidth: "382px",
                }}
              >
                <ImageContainer
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "20px",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "cover",
                  }}
                  imageContainerStyles={{
                    backgroundColor: "#181818",
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                  title="pricingPage Img"
                  height={"100%"}
                >
                  {CustomizeMobileImg}
                </ImageContainer>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PricingNewScreen;
