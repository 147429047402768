import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import TextInput from "../shared/components/TextInput";
import { HomeCoverBgHalf } from "../assets/images/image";
import UiwikiLogo from "../assets/icons/wikiLogoSvg/UiwikiLogo";

import { toastError, toastSuccess } from "../utils/ToastMessage";
import ErrorMessageIcon from "../assets/icons/ErrorMessageIcon/ErrorMessageIcon";
import { useNavigate } from "react-router-dom";
import useEnterKeyPress from "../utils/useEnterKeyPress";
import { verifyEmailVerificationCode } from "../services/authentication";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useGlobalContext } from "../App";
import DefaultLoading from "../shared/Loading/DefaultLoading";
import ResendOtp from "../components/ResendOtp";

type Props = {};

const EmailVerificationPage = (props: Props) => {
  const { setUserDetails,setActiveLoginDialog } = useGlobalContext();
  const location = useLocation();
  const email = location.state?.email || "";
  const res: any = location.state?.res || {};
  const theme = useTheme();
  const navigate = useNavigate();

  const [verificationCode, setVerificationCode] = useState<string>("");
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  useEffect(() => {}, []);

  const handleEnterKeyPress = () => {
    !isLoading && handleVerificationCode();
  };

  // Use the custom hook
  useEnterKeyPress(handleEnterKeyPress);

  const handleVerificationCode = async () => {
    setError("");
    setIsLoading(true);
    if (!verificationCode) {
      setError("Please enter your Verification code to proceed.");
      // toastError("Please enter your Verification code to proceed.");
      setIsLoading(false);
      setVerificationCode("");
      return;
    }
    try {
      await verifyEmailVerificationCode({
        otp: verificationCode,
        email: email,
      })
        .then((res: any) => {
          // toastSuccess("Success", "Otp Verified Successfully.");
          localStorage.setItem("jstoken", res?.data?.token);
          Cookies.set("jstoken", res?.data?.token);
          Cookies.set("user", JSON.stringify(res?.data));
          Cookies.set("userId", JSON.stringify(res?.data?._id));
          localStorage.setItem("isFirstTimeUser", "true");
          setUserDetails(res?.data?.user);
          setVerificationCode("");
          setActiveLoginDialog(false)
          navigate("/");
        })
        .catch((err) => {
          // toastError("Error", err?.response?.data?.message);
          setError(err?.response?.data?.message);
        });
      // setVerificationCode("");
    } catch (error) {
      setError("An unexpected error occurred. Please try again later.");
      console.log("error");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "background.main",
        width: "100%",
        justifyContent: "center",
        maxWidth: "100%",
        minHeight: "100vh",
        height: "100%",
        // overflow: "hidden",
        paddingX: { xs: "24px", sm: "0px", lg: "0px" },
        pb: { xs: "24px", sm: "0px", lg: "0px" },
      }}
    >
      <img
        style={{
          position: "absolute",
          // top: 0,
          // left: 0,
          width: "100vw",
          height: "100vh",
          objectFit: "cover",
          zIndex: -100,
        }}
        alt="background"
        src={HomeCoverBgHalf}
      />
      <Box
        sx={{
          minHeight: { xs: "100vh", lg: "auto" },
          width: "100%",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              overflow: "scroll",
              scrollbarWidth: "none",
              //   paddingTop: { xs: "60px", md: "80px" },
              //   paddingBottom: { xs: "0px", md: "80px" },
              //   border: "1px solid green",
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "100%", sm: "426px", lg: "426px" },
                minHeight: { xs: "auto", sm: "408px", lg: "408px" },
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                gap: { xs: "24px", sm: "24px", lg: "24px" },
                border: "1px solid #3D3D3D",
                boxSizing: "border-box",
                borderRadius: "24px",
                bgcolor: "#1B1B1B",
                p: { xs: "24px", sm: "24px", lg: "24px" },
              }}
            >
              <Box
                onClick={() => {
                  navigate("/");
                }}
                sx={{
                  width: "fit-content",
                  cursor: "pointer",
                  //   border: "1px solid red",
                }}
              >
                <UiwikiLogo />
              </Box>
              <Box
                sx={{
                  margin: "0",
                  padding: "0",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "24px", sm: "24px", lg: "24px" },
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: { xs: "12px", sm: "12px", lg: "12px" },
                    textAlign: "center",
                    width: "100%",
                    // border: "1px solid red",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      color: "text.secondary",
                      fontSize: "24px",
                      lineHeight: "auto",
                      m: "0px",
                      p: "0px",
                    }}
                  >
                    Email sent!
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      lineHeight: "auto",
                      color: "rgba(255, 255, 255, 0.5)",
                      fontSize: { xs: "14px", sm: "16px", lg: "16px" },
                      width: { xs: "100%", sm: "80%", lg: "80%" },
                      m: "0px auto",
                      p: "0px",
                      //   border:"1px solid red"
                    }}
                  >
                    We have sent a verification code to your registered email.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: "24px", sm: "32px", lg: "32px" },
                    width: "100%",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        // borderRadius: "12px",
                        m: "0px",
                        p: "0px",
                        mb: "16px",
                      }}
                    >
                      <TextInput
                        id="requestResetPasswordPopupInput"
                        lableStyles={{
                          fontSize: "16px !important",
                          fontWeight: "600",
                          lineHeight: "auto",
                        }}
                        labelAstrickStyle={{ color: "#E25454" }}
                        label="Code"
                        placeholder="Enter verification code"
                        error={error}
                        inputStyles={{
                          borderRadius: "14px",
                          height: "54px",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          fontFamily: "Inter Tight",
                          padding: "15px 15px 15px 16px",
                          color:
                            error && !isInputFocused ? "#E25454" : "#FFFFFF",
                          border: error ? "1px solid #E25454" : "0px",
                        }}
                        textinputstyles={{
                          margin: "0px",
                          padding: "0px",
                          height: "auto",
                          // gap:"11px"
                        }}
                        inputWrapStyle={{
                          minWidth: "10px !important",
                          width: "100%",
                        }}
                        onChange={(e: any) => {
                          setVerificationCode(e.target.value);
                        }}
                        onFocus={() => setIsInputFocused(true)}
                        onBlur={() => setIsInputFocused(false)}
                        value={verificationCode}
                        required
                      />
                      {error && (
                        <Typography
                          className="err_field"
                          id="otpNotExist"
                          color="#E25454"
                          variant="body2"
                          display={"flex"}
                          gap={"4px"}
                          marginTop={"12px"}
                          alignItems={"center"}
                          sx={{ width: "100%" }}
                          fontWeight={"400"}
                          fontSize="14px"
                          lineHeight={"16px"}
                          letterSpacing={"8%"}
                        >
                          {error && (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <ErrorMessageIcon />
                              {error}
                            </span>
                          )}
                        </Typography>
                      )}
                    </Box>
                    <ResendOtp email={email} />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      id="signinButton"
                      sx={{
                        width: "100%",
                        height: "54px",
                        textAlign: "center",
                        textTransform: "none",
                        bgcolor: "#CCFF00",
                        color: "#FFFFFF",
                        borderRadius: "14px",
                        paddingY: "16px",
                        border: "1px solid #718B08",
                        "&:hover": {
                          backgroundColor: "primary.main",
                        },
                      }}
                      onClick={() => handleVerificationCode()}
                      endIcon={
                        isLoading && (
                          <Box
                            sx={{
                              width: "42px",
                              height: "42px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DefaultLoading />
                          </Box>
                        )
                      }
                    >
                      <Typography
                        sx={{
                          color: "text.primary",
                          fontWeight: "600",
                          fontSize: "16px !important",
                          lineHeight: "auto",
                        }}
                      >
                        {!isLoading && "Continue"}
                      </Typography>
                    </Button>
                    {error && (
                      <Typography
                        sx={{ width: "100%" }}
                        fontWeight={"400"}
                        variant="caption"
                        color="#E03137"
                      >
                        {/* {error} */}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default EmailVerificationPage;
