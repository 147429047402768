import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { resendEmailVerificationCode } from "../services/authentication";
import DefaultLoading from "../shared/Loading/DefaultLoading";

const ResendOtp = (props: any) => {
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const [isResending, setIsResending] = useState(false); // New state for loading indicator

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  const handleResend = async () => {
    if (!canResend || !props.email || isResending) return;

    setIsResending(true);
    try {
      await resendEmailVerificationCode({ email: props.email })
        .then((res) => console.log(res))
        .catch((err) => console.log("Error in resend the verification code"));
    } catch (error) {
      console.log("Error in resend the verification code");
    } finally {
      // Reset the timer after resending
      setIsResending(false);
      setTimer(30);
      setCanResend(false);
    }
  };

  return (
    <Box sx={{ margin: "0px", padding: "0", textAlign: "center" }}>
      {/* Adjust margin as needed */}
      <Typography
        sx={{
          lineHeight: "auto",
          color: "rgba(255, 255, 255, 0.5)",
          fontSize: { xs: "14px", sm: "14px", lg: "14px" },
          fontWeight: "400",
          width: { xs: "100%", sm: "100%", lg: "100%" },
        }}
      >
        {canResend ? (
          <span
            onClick={handleResend}
            style={{
              cursor: "pointer",
              color: "#fff",
              boxSizing: "border-box",
            }}
          >
            Didn't receive the email?{" "}
            <strong style={{ color: "#CCFF00", fontWeight: "400" }}>
              {/* Resend */}
              {isResending ? (
                <Box
                  component="span"
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "14px",
                    height: "auto",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <DefaultLoading />
                </Box>
              ) : (
                "Resend"
              )}
            </strong>
          </span>
        ) : (
          <span>
            Didn't receive the email?{" "}
            <strong style={{ fontWeight: "400", color: "#CCFF00" }}>
              Resend in {timer}s
            </strong>
          </span>
        )}
      </Typography>
    </Box>
  );
};

export default ResendOtp;
