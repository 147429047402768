import React from "react";

const PasteComponentInFigmaSvgIcon = () => {
  return (
    <svg
      width="121"
      height="121"
      viewBox="0 0 121 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.168 80.666V47.8952C20.168 20.1657 20.168 20.166 60.5013 20.166C100.835 20.166 100.835 20.166 100.835 47.8952V80.666"
        stroke="#212121"
        stroke-width="7.5625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M60.4987 100.833C15.1237 100.833 10.082 100.833 10.082 90.7493C10.082 80.666 10.082 80.666 40.332 80.666C45.3737 80.666 45.3737 85.7077 50.6675 85.7077H70.8341C75.8758 85.7077 75.8758 80.666 80.5645 80.666C110.915 80.666 110.915 80.666 110.915 90.7493C110.915 100.833 105.874 100.833 60.4987 100.833Z"
        stroke="#212121"
        stroke-width="7.5625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PasteComponentInFigmaSvgIcon;
