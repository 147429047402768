import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CloseSharp, Height } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { Cracker } from "../../assets/images/image";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../shared/components/button/Buttons";
import { useTheme } from "@mui/material/styles";
import CrackerIconSvg from "../../assets/icons/crackerSvg/CrackerIconSvg";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  setActiveDialog: any;
};

const PromoCodeCongratulationPopup: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {}, [props?.activeDialog]);

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
            maxWidth: { xs: "90%", sm: "610px", md: "610px" },
            width: "100%",
          }}
        >
          <Box
            sx={{
              // maxWidth:"610px",
              // maxWidth:{xs: "100%",sm: "610px"},
              width: "100%",
              padding: "24px",
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.setActiveDialog(false);
                  }}
                  sx={{
                    position: "absolute",
                    right: "24px",
                    top: "24px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              {/* <ImageContainer
                title={"Cracker Icon"}
                height={isXs ? "100px" : "122px"}
                width={isXs ? "100px" : "122px"}
                imageContainerStyles={{ mt: "30px" }}
              >
                {Cracker}
              </ImageContainer> */}

              <Box sx={{ mt: "30px" }}>
                {!isXs ? (
                  <CrackerIconSvg />
                ) : (
                  <CrackerIconSvg width="100" height="100" />
                )}
              </Box>

              <Box
                sx={{
                  // height: "50%",
                  // width:{xs: "446px",sm: "446px",md: "546px"},
                  // width:"546px",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "32px",
                  mt: "20px",
                  // marginX:"8px",
                  boxSizing: "border-box",
                  // border:"1px solid red",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    // border:"1px solid red",
                    // marginX:"8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", sm: "24px" },
                      fontWeight: "600",
                      lineHeight: "auto",
                      color: "#FFFFFF",
                      mb: "12px",
                      textAlign: "center",
                    }}
                  >
                    Woohoo! Promo Code Activated!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", sm: "16px" },
                      fontWeight: "400",
                      color: "#FFFFFF",
                      lineHeight: "auto",
                      // opacity: "50%",
                      width: { xs: "100%", sm: "83%", lg: "83%" },
                      textAlign: "center",
                      // border:"1px solid red",
                    }}
                  >
                    <span style={{ opacity: "50%" }}>
                      Congrats, you're Officially in the premium club! Time to
                      create designs that make the internet jealous.
                    </span>
                    🚀
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    // height: "300%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    // gap: "12px",
                    // mt:"32px",
                  }}
                >
                  <ButtonPrimary
                    label="Let's Design!"
                    sx={{
                      // width: "35%",
                      width: { xs: "100%", sm: "35%" },
                      color: "#000000",
                      border: "1px solid #526600",
                      borderRadius: "14px",
                      padding: "15px 16px",
                    }}
                    LabelStyle={{
                      fontSize: "16px !important",
                      fontWeight: "600",
                      lineHeight: "22px",
                    }}
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default PromoCodeCongratulationPopup;
