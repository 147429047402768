import React from "react";

const ComponentCopySuccessIconSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6858_8621)">
        <path
          d="M7 0C3.13425 0 0 3.13425 0 7C0 10.8658 3.13425 14 7 14C10.8658 14 14 10.8658 14 7C14 3.13425 10.8658 0 7 0ZM6.125 6.125C6.125 5.89294 6.21719 5.67038 6.38128 5.50628C6.54538 5.34219 6.76794 5.25 7 5.25C7.23206 5.25 7.45462 5.34219 7.61872 5.50628C7.78281 5.67038 7.875 5.89294 7.875 6.125V10.5C7.875 10.7321 7.78281 10.9546 7.61872 11.1187C7.45462 11.2828 7.23206 11.375 7 11.375C6.76794 11.375 6.54538 11.2828 6.38128 11.1187C6.21719 10.9546 6.125 10.7321 6.125 10.5V6.125ZM7 4.389C6.88506 4.38897 6.77126 4.3663 6.66508 4.32229C6.55891 4.27828 6.46244 4.21379 6.38119 4.1325C6.29994 4.05121 6.2355 3.95471 6.19154 3.84851C6.14758 3.74231 6.12497 3.6285 6.125 3.51356C6.12503 3.39863 6.1477 3.28482 6.19171 3.17865C6.23572 3.07247 6.30021 2.976 6.3815 2.89475C6.46279 2.8135 6.55929 2.74906 6.66549 2.7051C6.77169 2.66114 6.8855 2.63853 7.00044 2.63856C7.23256 2.63862 7.45515 2.73089 7.61925 2.89506C7.78334 3.05924 7.8755 3.28188 7.87544 3.514C7.87538 3.74612 7.78311 3.96871 7.61894 4.13281C7.45476 4.2969 7.23212 4.38906 7 4.389Z"
          fill="#CCFF00"
        />
      </g>
      <defs>
        <clipPath id="clip0_6858_8621">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ComponentCopySuccessIconSvg;
