import { Box, CircularProgress, Typography } from "@mui/material";
import { ImageContainer } from "../../../shared/components/ImageContainer";
import { CopyIcon, EyeIcon } from "../../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import { isBrowserSafari } from "../../../utils/extensions";
import { toastError, toastSuccess } from "../../../utils/ToastMessage";
import Cookies from "js-cookie";
import { colors } from "../../../utils/colors";
import { useGlobalContext } from "../../../App";
import { getComponentSourceCodeByComponentId } from "../../../services/component";
import { useState } from "react";
import PremiumIconSvg from "../../../assets/icons/PremiumIcon/PremiumIconSvg";
import ReactGA from "react-ga4";

type Props = {
  data: any;
  userSubscriptions?: any;
  isSubscribed?: any;
  scrollPosition?: any;
  filteredList?: any;
  onEyeIconClick?: any;
  length?: any;
};

const ComponentCard = (props: Props) => {
  const navigate = useNavigate();
  const { setActiveLoginDialog, setComponentCopiedpopupVisible } =
    useGlobalContext();
  const [loading, setLoading] = useState(false);
  const { data, isSubscribed, onEyeIconClick } = props;

  const backgroundImageKey = data?.documents[0]?.url;
  const backgroundImageUrl = backgroundImageKey
    ? `https://uiwiki.co/cdn-cgi/image/width=1500,fit=cover,quality=100,format=auto/${backgroundImageKey}`
    : "";

  const handleCopyFigmaCode = async () => {
    ReactGA.event("component_copied", { component_id: data?.id }); // Google analytics
    setLoading(true);

    if (!Cookies.get("jstoken")) {
      setActiveLoginDialog(true);
      setLoading(false);
      return;
    }

    if (isSubscribed || data?.license === "FREE") {
      try {
        const componentSourceCode: any =
          await getComponentSourceCodeByComponentId(data?.id);
        const htmlContent = componentSourceCode?.data?.figmaCode || "";

        if (!htmlContent) {
          toastError("Failed", "No content to copy.");
          setLoading(false);
          return;
        }
        // Create a Blob with the HTML content and specify the MIME type as 'text/html'
        const blob = new Blob([htmlContent], { type: "text/html" });
        const clipboardItem = new ClipboardItem({ "text/html": blob });
        // Copy the Blob to the clipboard

        if (isBrowserSafari()) {
          setTimeout(() => {
            navigator.clipboard
              .write([clipboardItem])
              .then(() => {
                setComponentCopiedpopupVisible(true);
              })
              .catch((err) => {
                toastError(
                  "Unable to copy component",
                  "We suggest using Google Chrome for the best experience."
                );
              });
          }, 0);
        } else {
          navigator.clipboard
            .write([clipboardItem])
            .then(() => {
              setComponentCopiedpopupVisible(true);
            })
            .catch((err) => {
              toastError(
                "Unable to copy component",
                "Verify whether the browser is preventing the copy to clipboard feature."
              );
            });
        }

        // Show the popup after copying
      } catch (error) {
        console.error("Copy failed:", error);
        toastError("Failed", "Something went wrong.");
      }
    } else {
      navigate("/pricing");
      toastError(
        "Not Subscribed!",
        "Please subscribe to copy premium components."
      );
    }

    setLoading(false);
  };

  const handleShortBlurb = (blurb: string) => {
    return blurb?.length > 52 ? `${blurb?.slice(0, 49)}...` : blurb;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        bgcolor: colors.primaryGrey,
        // width: "100%",
        // minWidth: "100%",
        // maxWidth: { xs: "100%", sm: "320px", md: "360px", lg: "100%" },
        width: "100%", // Fixed width
        // height: "327px", // Fixed height
        // aspectRatio: "4/3",
        height: "auto",

        // minWidth: "100%",
        // maxWidth: { xs: "100%", sm: "320px", md: "360px", lg: "100%" },
        minWidth: props.length <= 1 ? "360px" : "100%",
        maxWidth: "360px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          borderRadius: "12px",
          // overflow: "hidden",
          "&::before": {
            content: '""',
            display: "block",
            paddingTop: "75%", // 4:3 aspect ratio (3/4 = 0.75 * 100)
          },
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          "&:hover .overlay": {
            opacity: 1, // Make the overlay visible on hover
          },
          aspectRatio: "4/3",
          cursor: "pointer",
        }}
      >
        <Box
          className="overlay"
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            opacity: 0, // Start with the overlay hidden
            transition: "opacity 0.3s ease",
            borderRadius: "9px 9px 9.5px 9.5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            padding: "24px",
          }}
          onClick={() => {
            ReactGA.event("component_viewed", { component_id: data?.id }); // Google Analytics
            onEyeIconClick();
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "17px",
            }}
          >
            <Box
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                handleCopyFigmaCode();
              }}
              sx={{
                width: "46px",
                height: "46px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "white",
                cursor: "pointer",
              }}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "black",
                  }}
                  size={24}
                />
              ) : (
                <ImageContainer
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  title="copy"
                >
                  {CopyIcon}
                </ImageContainer>
              )}
            </Box>
            <Box
              onClick={(e: any) => {
                e.stopPropagation(); // Prevent click from bubbling up to the overlay
                ReactGA.event("component_viewed", { component_id: data?.id }); // Google Analytics
                onEyeIconClick();
              }}
              sx={{
                width: "46px",
                height: "46px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "white",
                cursor: "pointer",
              }}
            >
              <ImageContainer
                style={{
                  width: "25px",
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                title="eye"
              >
                {EyeIcon}
              </ImageContainer>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          alignItems: "flex-start",
          gap: "12px",
        }}
      >
        <Typography color={"white"} fontWeight={600} variant="subtitle1">
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            {data?.title}
            {data?.license === "PREMIUM" ? <PremiumIconSvg /> : ""}
          </Box>
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            overflow: "hidden",
          }}
          variant="body1"
          color={"rgba(255, 255, 255, 0.5)"}
        >
          {handleShortBlurb(data?.blurb)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ComponentCard;
