import { AddRounded } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserIconSvg } from "../../../../assets/icons/UserIconSvg";
import TextInput from "../../../../shared/components/TextInput";
import { ButtonPrimary } from "../../../../shared/components/button/Buttons";
import { colors } from "../../../../utils/colors";
import {
  EyeLightIcon,
  EyeOffLightIcon,
  TrashIcon,
} from "../../../../assets/icons/icons";
import ProfileDialog from "./ProfileDialog";
import { useGlobalContext } from "../../../../App";
import {
  deleteUserAccount,
  updateUserDetails,
} from "../../../../services/user";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";
import { ImageContainer } from "../../../../shared/components/ImageContainer";
import DeleteDialog from "./components/DeleteDialog";
import DefaultLoading from "../../../../shared/Loading/DefaultLoading";
import EyeOpenIcon from "../../../../assets/icons/EyeIcons/EyeOpenIcon";
import EyeOffIcon from "../../../../assets/icons/EyeIcons/EyeOffIcon";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import ErrorMessageIcon from "../../../../assets/icons/ErrorMessageIcon/ErrorMessageIcon";

type Props = {};

const Profile = (props: Props) => {
  const { userDetails, setUserDetails, activeEmailSentCongratulationPopup } =
    useGlobalContext();
  const navigate = useNavigate();

  const [showDeleteAccountUI, setShowDeleteAccountUI] =
    useState<boolean>(false);
  const [activeProfileDialog, setActiveProfileDialog] =
    useState<boolean>(false);
  const [activeDeleteProfileDialog, setActiveDeleteProfileDialog] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(true);

  const [profileImage, setProfileImage] = useState<any>("");
  const [avatar, setAvatar] = useState<any>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] =
    useState<boolean>(false);

  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [updateDetailsError, setUpdateDetailsError] = useState<string>("");

  const handelDelete = () => {
    // Check if the password is empty
    if (!confirmPassword) {
      setErrors({ password: "Please enter a password." });
      return;
    } else {
      setErrors({});
      setActiveDeleteProfileDialog(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && focusedField) {
      handleUpdateUserDetails(); // Only call when an input is focused
    }
  };

  const handleClickDeleteAccount = () => {
    setShowDeleteAccountUI(true);
  };

  const handleChangeProfile = (data?: any) => {
    if (data) {
      // window.location.reload();
      setProfileImage(data?.profile);
      setAvatar(data?.avatar);
    }
    setActiveProfileDialog(false);
  };

  const handleUpdateUserDetails = async () => {
    setErrors({});

    // Validate first name and last name
    if (!firstName) {
      setErrors((prev) => ({ ...prev, firstName: "First name is required." }));
      return;
    }
    if (!lastName) {
      setErrors((prev) => ({ ...prev, lastName: "Last name is required." }));
      return;
    }
    setLoading(true);
    await updateUserDetails({
      userId: userDetails?.id,
      firstName: firstName,
      lastName: lastName,
    })
      .then((res: any) => {
        toastSuccess(
          "Details Updated",
          "Your information has been successfully updated. You’re all set now!"
        );
        setUserDetails({ ...userDetails, firstName, lastName });
      })
      .catch((err: any) => {
        // toastError(
        //   "Ops! Something went wrong.",
        //   "Failed updating the user details."
        // );
        setUpdateDetailsError("Profile Update Error, Something went wrong while updating your info. Please try again later.");
      });
    setLoading(false);
  };

  const handleDeleteProfile = async () => {
    //if password is available then we have to delete the profile else update the profile image

    if (confirmPassword) {
      await deleteUserAccount({
        userId: userDetails?.id,
        password: confirmPassword,
      })
        .then((res: any) => {
          localStorage.removeItem("jstoken");
          Cookies.remove("jstoken");
          Cookies.remove("user");
          window.location.replace("/");
          toastSuccess(
            "Profile Deleted",
            "Your profile has been successfully removed. We’re sorry to see you go!"
          );
        })
        .catch((err: any) => {
          // toastError(
          //   "Ops! Something went wrong.",
          //   err?.response?.data?.message
          // );
          // toastError(             // New Toast message
          //   "Delete Failed.",
          //   "Sorry, something went wrong. Please try again later."
          // );
          setErrors({
            password: err?.response?.data?.message || "Incorrect Password.",
          });
        });
    } else {
      await updateUserDetails({
        userId: userDetails?.id,
        avatar: "",
      })
        .then((res: any) => {
          // window.location.reload();
          // Update the state to reflect the new avatar without the reload entire page
          setUserDetails({ ...userDetails, avatar: "" });
        })
        .catch((err: any) => {
          // toastError(
          //   "Ops! Something went wrong.",
          //   err?.response?.data?.message
          // );
          setErrors({ password: err?.response?.data?.message });
        });
    }

    setActiveDeleteProfileDialog(false);
  };

  useEffect(() => {}, [profileImage, avatar]);

  useEffect(() => {
    if (userDetails) {
      setProfileImage(userDetails?.avatar);
      setAvatar(userDetails?.secondaryAvatar);
      setFirstName(userDetails?.firstName);
      setLastName(userDetails?.lastName);
      setTimeout(() => {
        setLoadingDetails(false);
      }, 1000);
    }
  }, [userDetails]);

  //Stop scrolling when any popup is open
  useEffect(() => {
    if (
      activeDeleteProfileDialog ||
      activeProfileDialog ||
      activeEmailSentCongratulationPopup
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [
    activeDeleteProfileDialog,
    activeProfileDialog,
    activeEmailSentCongratulationPopup,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", lg: "fit-content" },
        gap: "24px",
        // border:"1px solid red"
      }}
    >
      {/* -----Delete Profile confirmation dialog----- */}
      <Box sx={{ position: "absolute" }}>
        {activeDeleteProfileDialog && (
          <DeleteDialog
            deleteAction={() => {
              handleDeleteProfile();
            }}
            title="Are you sure you want to delete your profile?"
            setIsDeleteDialogVisible={setActiveDeleteProfileDialog}
          />
        )}
      </Box>

      {!showDeleteAccountUI ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: { xs: "24px", sm: "48px 56px" },
              minWidth: { xs: "100%", sm: "460px" },
              maxWidth: { xs: "100%", sm: "460px" },
              maxHeight: "auto",
              height: "100%",
              borderRadius: "16px",
              bgcolor: "rgba(255, 255, 255, 0.06)",
              gap: "32px",
              alignItems: "center",
            }}
          >
            {loadingDetails ? (
              <Box
                sx={{
                  width: "100%",
                  minHeight: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "@media screen and (min-width: 2500px) and (max-width: 4000px)":
                    {
                      minHeight: "30vh",
                    },
                  "@media screen and (min-width: 1800px) and (max-width: 2499px)":
                    {
                      minHeight: "40vh",
                    },
                  "@media screen and (min-width: 1600px) and (max-width: 1700px)":
                    {
                      minHeight: "45vh",
                    },
                  "@media screen and (min-width: 1200px) and (max-width: 1300px)":
                    {
                      minHeight: "59vh",
                    },
                  "@media screen and (min-width: 1000px) and (max-width: 1159px)":
                    {
                      minHeight: "68vh",
                    },
                }}
              >
                {/* <CircularProgress
                  size={24}
                  sx={{
                    color: "#CCFF00",
                    marginRight: "12px",
                  }}
                /> */}
                <Box
                  sx={{
                    width: "34px",
                    height: "34px",
                  }}
                >
                  <DefaultLoading width="34px" height="34px" />
                </Box>
              </Box>
            ) : (
              <>
                {activeProfileDialog && (
                  <ProfileDialog
                    activeDialog={activeProfileDialog}
                    handleDialog={handleChangeProfile}
                  />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: "600",
                      fontSize: "18px",
                    }}
                    variant="subtitle1"
                  >
                    Profile
                  </Typography>
                </Box>

                {/* -----Profile image----- */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50%",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    bgcolor: "background.default",
                    position: "relative",
                    width: "144px",
                    height: "144px",
                    "&:hover .editIcon": {
                      opacity: 1,
                    },
                  }}
                >
                  {profileImage || avatar ? (
                    profileImage ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        alt="profile"
                        src={
                          profileImage instanceof File
                            ? URL.createObjectURL(profileImage)
                            : profileImage
                        }
                      />
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        alt="profile"
                        src={avatar}
                      />
                    )
                  ) : (
                    <UserIconSvg />
                  )}

                  {/* Trash Icon  */}
                  {profileImage && (
                    <Box
                      className="editIcon"
                      onClick={() => setActiveDeleteProfileDialog(true)}
                      sx={{
                        position: "absolute",
                        width: "40px",
                        height: "40px",
                        right: "0px",
                        top: "0px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        bgcolor: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "none",
                        // opacity: profileImage ? 0 : 1,
                        opacity: 1,
                      }}
                    >
                      <ImageContainer
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "24px",
                          height: "24px",
                        }}
                        title="trash"
                      >
                        {TrashIcon}
                      </ImageContainer>
                    </Box>
                  )}

                  {/* Add Icon */}
                  <Box
                    className="editIcon"
                    onClick={() => {
                      setActiveProfileDialog(true);
                    }}
                    sx={{
                      boxShadow: profileImage
                        ? "none"
                        : "inset 0px 0px 0px 1px #3D3D3D",
                      bgcolor: profileImage ? "white" : "#292929",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      width: "40px",
                      height: "40px",
                      right: "0px",
                      bottom: "0px",
                      borderRadius: "50%",
                      cursor: "pointer",
                      // opacity: profileImage ? 0 : 1,
                      opacity: 1,
                    }}
                  >
                    <AddRounded
                      style={{
                        color: !profileImage ? "#FFFFFF" : "#0C0C0C",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                  onKeyDown={handleKeyDown}
                >
                  <Box>
                    <TextInput
                      id="firstNameInputAccountSetting"
                      inputStyles={{
                        color: "#FFFFFF",
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                        padding: "15px 15px 15px 16px",
                        fontSize: "16px !important",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "-2%",
                      }}
                      lableStyles={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                        lineHeight: "22px",
                      }}
                      value={firstName}
                      onChange={(e: any) => {
                        setFirstName(e.target.value);
                      }}
                      label="First Name"
                      placeholder="First Name"
                      onFocus={() => setFocusedField("firstName")}
                      onBlur={() => setFocusedField(null)}
                    />
                    {errors?.firstName && (
                      <Typography
                        className="err_field"
                        id="loginPassNotExist"
                        color="#E25454"
                        variant="body2"
                        display={"flex"}
                        gap={"4px"}
                        marginTop={"10px"}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                        fontWeight={"400"}
                        fontSize="14px"
                        lineHeight={"16px"}
                        letterSpacing={"0%"}
                      >
                        {errors?.firstName && (
                          <>
                            <span>
                              <ErrorMessageIcon />
                            </span>
                            {errors?.firstName}
                          </>
                        )}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <TextInput
                      id="lastNameInputAccountSetting"
                      inputStyles={{
                        color: "#FFFFFF",
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                        padding: "15px 15px 15px 16px",
                        fontSize: "16px !important",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "-2%",
                      }}
                      lableStyles={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                        lineHeight: "22px",
                      }}
                      label="Last Name"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e: any) => {
                        setLastName(e.target.value);
                      }}
                      onFocus={() => setFocusedField("lastName")}
                      onBlur={() => setFocusedField(null)}
                    />
                    {errors?.lastName && (
                      <Typography
                        className="err_field"
                        id="loginPassNotExist"
                        color="#E25454"
                        variant="body2"
                        display={"flex"}
                        gap={"4px"}
                        marginTop={"10px"}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                        fontWeight={"400"}
                        fontSize="14px"
                        lineHeight={"16px"}
                        letterSpacing={"0%"}
                      >
                        {errors?.lastName && (
                          <>
                            <span>
                              <ErrorMessageIcon />
                            </span>
                            {errors?.lastName}
                          </>
                        )}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <TextInput
                      id="emailInputAccountSetting"
                      inputStyles={{
                        color: "#FFFFFF",
                        backgroundColor: "rgba(255, 255, 255, 0.06)",
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                        padding: "15px 15px 15px 16px",
                        fontSize: "16px !important",
                        fontWeight: "400",
                        lineHeight: "24px",
                        letterSpacing: "-2%",
                      }}
                      lableStyles={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                        lineHeight: "24px",
                      }}
                      label="Email"
                      placeholder="Email"
                      value={userDetails?.email}
                      disabled={true}
                      onFocus={() => setFocusedField("email")}
                      onBlur={() => setFocusedField(null)}
                    />
                  </Box>

                  <Box>
                    <ButtonPrimary
                      isLoading={loading}
                      sx={{
                        width: "100%",
                        padding: "16px 16px",
                        borderRadius: "14px",
                        border: "1px solid #718B08",
                      }}
                      LabelStyle={{
                        fontSize: "16px !important",
                        fontWeight: "600",
                        lineHeight: "auto",
                      }}
                      onClick={() => {
                        handleUpdateUserDetails();
                      }}
                      label="Save"
                    />
                    {updateDetailsError && (
                      <Typography
                        className="err_field"
                        id="updatedetailserror"
                        color="#E25454"
                        variant="body2"
                        display={"flex"}
                        gap={"4px"}
                        marginTop={"10px"}
                        alignItems={"center"}
                        sx={{ width: "100%" }}
                        fontWeight={"400"}
                        fontSize="14px"
                        lineHeight={"16px"}
                        letterSpacing={"0%"}
                      >
                        {updateDetailsError && (
                          <>
                            <span>
                              <ErrorMessageIcon />
                            </span>
                            {updateDetailsError}
                          </>
                        )}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>

          <Typography
            onClick={() => {
              handleClickDeleteAccount();
            }}
            variant="subtitle1"
            sx={{
              color: "#E25454",
              textAlign: "center",
              width: "100%",
              cursor: "pointer",
              fontSize: "16px !important ",
              textDecoration: "underline", // Add underline
              // marginBottom:"24px"
            }}
          >
            Delete my account
          </Typography>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: "24px", sm: "48px 56px" },
            minWidth: { xs: "100%", sm: "460px", lg: "460px" },
            maxWidth: { xs: "100%", sm: "460px", lg: "460px" },
            width: "100%",
            borderRadius: "24px",
            bgcolor: "rgba(255, 255, 255, 0.06)",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "16px",
                letterSpacing: "-2%",
              }}
              // variant="subtitle1"
            >
              Delete account
            </Typography>
            <Typography
              sx={{
                color: colors.primaryRed,
                fontSize: "16px !important",
                fontWeight: "500",
                lineHeight: "24px",
                letterSpacing: "-2%",
              }}
              // variant="body1"
            >
              Are you sure you want to permanently delete your account?
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", sm: "32px" },
              m: "0px",
              p: "0px",
            }}
          >
            {!userDetails?.googleId && (
              <Box>
                <TextInput
                  id="DeleteAccountUIInput"
                  textinputstyles={{
                    margin: "0px",
                    padding: "0px",
                    // gap: "11px",
                  }}
                  error={errors.password}
                  inputStyles={{
                    color: "#FFFFFF",
                    // backgroundColor: "rgba(255, 255, 255, 0.06)",
                    backgroundColor: "transparent",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    borderRadius: "16px",
                    fontSize: "16px !important",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "-2%",
                    padding: "15px 15px 15px 16px",
                    border: errors.password ? "1px solid #E25454" : "0px",
                  }}
                  value={confirmPassword}
                  onChange={(e: any) => {
                    setConfirmPassword(e.target.value);
                  }}
                  lableStyles={{
                    fontSize: "16px !important",
                    fontWeight: "500",
                    lineHeight: "24px",
                    letterSpacing: "-2%",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                  label="To confirm deletion, enter your current password."
                  placeholder="Enter your password"
                  iconContainerStyles={{
                    bottom: "15px",
                    top: "15px",
                    right: "15px",
                  }}
                  iconstyles={{ width: "24px", height: "24px" }}
                  icononclick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                  icon={
                    !confirmPasswordVisible ? <EyeOpenIcon /> : <EyeOffIcon />
                  }
                  type={!confirmPasswordVisible ? "password" : "text"}
                />
                {errors?.password && (
                  <Typography
                    className="err_field"
                    id="loginPassNotExist"
                    color="#E25454"
                    variant="body2"
                    display={"flex"}
                    gap={"4px"}
                    marginTop={"10px"}
                    alignItems={"center"}
                    sx={{ width: "100%" }}
                    fontWeight={"400"}
                    fontSize="14px"
                    lineHeight={"16px"}
                    letterSpacing={"0%"}
                  >
                    {errors?.password && (
                      <>
                        <span>
                          <ErrorMessageIcon />
                        </span>
                        {errors?.password}
                      </>
                    )}
                  </Typography>
                )}
              </Box>
            )}
            <ButtonPrimary
              label="Delete"
              sx={{
                borderRadius: "16px",
                border: "1px solid #718B08",
              }}
              LabelStyle={{
                fontSize: "16px !important",
                fontWeight: "600",
                lineHeight: "22px",
              }}
              onClick={handelDelete}
              // onClick={() => {
              //   setActiveDeleteProfileDialog(true);
              // }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Profile;
