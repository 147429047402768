import React from "react";

const ExploreComponentStackSvg = () => {
  return (
    <svg
      width="107"
      height="107"
      viewBox="0 0 107 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.04">
        <rect width="107" height="107" fill="#212121" />
        <path
          d="M95.8529 33.4381C95.8529 34.4636 94.025 36.2469 91.127 38.3869C82.3397 44.5051 72.8686 49.5773 62.9058 53.5006C59.9049 54.7278 56.7311 55.4799 53.4987 55.7298C50.2663 55.4799 47.0925 54.7278 44.0916 53.5006C34.1288 49.5773 24.6577 44.5051 15.8704 38.3869C12.9724 36.2469 11.1445 34.4636 11.1445 33.4381C11.1445 32.4127 12.9724 30.6294 15.8704 28.4894C24.6577 22.3712 34.1288 17.299 44.0916 13.3757C47.0925 12.1485 50.2663 11.3964 53.4987 11.1465C56.7311 11.3964 59.9049 12.1485 62.9058 13.3757C72.8603 17.2427 82.3311 22.2549 91.127 28.3111C94.025 30.6294 95.8529 32.4127 95.8529 33.4381Z"
          stroke="white"
          stroke-width="6.6875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M95.8529 78.0215C91.127 82.4798 62.9058 95.8548 53.4987 95.8548C44.0916 95.8548 15.8704 82.4798 11.1445 78.0215"
          stroke="white"
          stroke-width="6.6875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M95.8529 57.959C91.127 62.4173 62.9058 75.7923 53.4987 75.7923C44.0916 75.7923 15.8704 62.4173 11.1445 57.959"
          stroke="white"
          stroke-width="6.6875"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default ExploreComponentStackSvg;
