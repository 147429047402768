import { Box } from "@mui/material";
import React from "react";
import SelectedAvtarTickSvg from "../../../../../assets/icons/selectedAvtarTickSvg/SelectedAvtarTickSvg";

type Props = {
  data: any;
  selectedAvatar?: any;
  handleSelectAvatar: any;
  activeId?: any;
};

const Avatar = (props: Props) => {
  const isSelected = props.selectedAvatar?.key === props.data?.key || props.activeId === props.data?.key;
  return (
    <Box
      onClick={() => {
        props?.handleSelectAvatar(props?.data);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: { xs: "48px", sm: "140px" },
        height: { xs: "48px", sm: "140px" },
        borderRadius: "50%",
        cursor: "pointer",
        position: "relative",
        // boxShadow:
        //   props?.selectedAvatar !== ""
        //     ? props?.selectedAvatar?.key === props?.data?.key
        //       ? "inset 0px 0px 0px 4px #CCFF00"
        //       : "none"
        //     : props?.activeId === props?.data?.key
        //     ? "inset 0px 0px 0px 4px #CCFF00"
        //     : "none",
        // border:
        //   props?.selectedAvatar !== ""
        //     ? props?.selectedAvatar?.key === props?.data?.key
        //       ? "3px solid #CCFF00"
        //       : "none"
        //     : props?.activeId === props?.data?.key
        //     ? "3px solid #CCFF00"
        //     : "none",
      }}
    >
      <img
        style={{
          width: "100%",
          // width:"136px",
          // height: "136px",
          borderRadius: "50%",
        }}
        alt="avatar"
        src={props?.data?.value}
      />
      {isSelected && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            overflow:"hidden",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SelectedAvtarTickSvg/>
        </Box>
      )}
    </Box>
  );
};

export default Avatar;
