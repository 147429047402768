import { Box, Typography } from "@mui/material";
import { TickCircleIconSvg } from "../assets/icons/TickCircleIconSvg";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../shared/components/button/Buttons";
import MembershipDialog from "../pages/setting/tabs/membership/membershipDialog/MembershipDialog";
import { useEffect, useState } from "react";
import { getDateFormatted } from "../utils/extensions";

type Props = {
  activePlanName: string;
  setMembershipDialog: any;
  setConfirmCancelMembershipDialog: any;
  subscriptionStatus: any;
  subscriptionEndDate: any;
  activeSubscriptions: any;
};

const MembershipCard = (props: Props) => {
  let {
    activePlanName,
    setConfirmCancelMembershipDialog,
    subscriptionStatus,
    subscriptionEndDate,
    activeSubscriptions,
  } = props;
  const [membershipDialog, setMembershipDialog] = useState(false);

  let title = "";
  let amount = "";
  let endDateDescription = "";

  if (activePlanName === "MONTHLY") {
    title = "Monthly Membership";
    amount = "10/month";
  } else if (activePlanName === "YEARLY") {
    title = "Annual Membership";
    amount = "6/month*billed yearly";
  } else if (activePlanName === "LIFETIME") {
    title = "Lifetime Plan";
    amount = "250";
  } else if (activePlanName === "FREE") {
    title = "Membership";
    amount = "Free Trial";
  } else {
  }

  subscriptionEndDate = getDateFormatted(subscriptionEndDate);

  if (activePlanName === "FREE" && subscriptionStatus === "ACTIVE") {
    endDateDescription = `Free trial ends on ${subscriptionEndDate}`;
  } else if (subscriptionStatus === "ACTIVE") {
    endDateDescription = `Renews on ${subscriptionEndDate}`;
  } else if (subscriptionStatus === "CANCELLED") {
    endDateDescription = `Membership will end on ${subscriptionEndDate}`;
  }

  const handleCloseMembershipDialog = () => {
    setMembershipDialog(false);
  };
  useEffect(() => {
    if (membershipDialog) {
      document.body.style.overflow = "hidden";
      document.body.classList.add("no-scroll");
      document.documentElement.classList.add("no-scroll");
    } else {
      document.body.style.overflow = "auto";
      document.body.classList.remove("no-scroll");
      document.documentElement.classList.remove("no-scroll");
    }

    return () => {
      document.body.style.overflow = "auto";
      document.body.classList.remove("no-scroll");
      document.documentElement.classList.remove("no-scroll");
    };
  }, [membershipDialog]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        borderRadius: "16px",
        bgcolor: "rgba(255, 255, 255, 0.06)",
        maxWidth: { xs: "100%", sm: "460px" },
        minWidth: { xs: "100%", sm: "460px" },
        gap: "24px",
      }}
    >
      {/* -----Price section----- */}
      {membershipDialog && (
        <MembershipDialog
          handleDialog={handleCloseMembershipDialog}
          activeDialog={membershipDialog}
          activePlan={activePlanName}
          activeSubscriptions={activeSubscriptions}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Typography
            sx={{ fontWeight: "600", color: "primary.main" }}
            variant="body1"
          >
            {title}
          </Typography>
        </Box>

        <Typography
          sx={{
            fontSize: "58px",
            color: "primary.main",
            fontWeight: "600",
            display: "flex",
            alignItems: "flex-start",
            gap: "6px",
          }}
          variant="subtitle1"
        >
          $
          <span
            style={{
              fontSize: "57px",
              lineHeight: "100%",
              color: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              alignItems: "flex-end",
              gap: "6px",
            }}
          >
            {amount?.split("/")[0]}
            <span
              style={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "100%",
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              {`${amount?.split("/")[1] ? `/` + amount?.split("/")[1] : ""}`}
            </span>
          </span>
        </Typography>
      </Box>

      {/* *****Seperator***** */}
      <Box
        sx={{
          flex: 1,
          height: "1px",
          border: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      />

      {/* ------Pricing Details------ */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography sx={{ fontWeight: "600", color: "white" }} variant="h4">
          Benefits
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <TickCircleIconSvg />
          <Typography
            sx={{ fontWeight: "400", color: "white" }}
            variant="subtitle1"
          >
            Access to 2500+ premium components
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <TickCircleIconSvg />
          <Typography
            sx={{ fontWeight: "400", color: "white" }}
            variant="subtitle1"
          >
            Unlimited downloads per day
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <TickCircleIconSvg />
          <Typography
            sx={{ fontWeight: "400", color: "white" }}
            variant="subtitle1"
          >
            {activePlanName === "LIFETIME"
              ? "Daily updates to components"
              : "Weekly components release"}
          </Typography>
        </Box>
      </Box>

      {activePlanName === "LIFETIME" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Typography sx={{ fontWeight: "600", color: "white" }} variant="h4">
            Additional Benefits
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <TickCircleIconSvg />{" "}
            <Typography
              sx={{ fontWeight: "400", color: "white" }}
              variant="subtitle1"
            >
              Lifetime priority support
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <TickCircleIconSvg />{" "}
            <Typography
              sx={{ fontWeight: "400", color: "white" }}
              variant="subtitle1"
            >
              Special discount on upcomming features
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            <TickCircleIconSvg />{" "}
            <Typography
              sx={{ fontWeight: "400", color: "white" }}
              variant="subtitle1"
            >
              Early access to beta tools
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography sx={{ fontWeight: "600", color: "white" }} variant="h4">
              Additional Benefits
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <TickCircleIconSvg />{" "}
              <Typography
                sx={{ fontWeight: "400", color: "white" }}
                variant="subtitle1"
              >
                Fast support
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <TickCircleIconSvg />{" "}
              <Typography
                sx={{ fontWeight: "400", color: "white" }}
                variant="subtitle1"
              >
                Cancel Anytime: No commitments
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "20px",
              lineHeight: "22px",
              fontWeight: "400",
              color: "#E25454",
              mt: "8px",
              mb: "8px",
            }}
          >
            {endDateDescription}
          </Typography>
        </>
      )}

      {activePlanName === "LIFETIME" ? (
        <></>
      ) : activePlanName === "FREE" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            mt: "8px",
          }}
        >
          <ButtonPrimary
            LabelStyle={{
              fontSize: "16px !important",
              fontWeight: "600",
              lineHeight: "22px",
            }}
            label="Upgrade to Pro"
            onClick={() => {
              setMembershipDialog(true);
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            mt: "8px",
          }}
        >
          <ButtonPrimary
            LabelStyle={{
              fontSize: "16px !important",
              fontWeight: "600",
              lineHeight: "22px",
            }}
            label={
              activePlanName === "MONTHLY"
                ? "Upgrade to Annual"
                : activePlanName === "YEARLY"
                ? "Upgrade to Lifetime"
                : "Upgrade" // Fallback text
            }
            onClick={() => {
              setMembershipDialog(true);
            }}
          />
          {subscriptionStatus === "ACTIVE" ? (
            <ButtonSecondary
              LabelStyle={{
                fontSize: "16px !important",
                fontWeight: "600",
                lineHeight: "22px",
              }}
              onClick={() => {
                setConfirmCancelMembershipDialog(true);
              }}
              label="Cancel Membership"
            />
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MembershipCard;
