import React from "react";
import { IconColor } from "./interface/ColorInterface";

export const TickCircleIconSvg: React.FC<IconColor> = ({
  color,
  width ,
  height ,
  transform,
}) => (
  <svg
    width={width || "18"}
    height={height || "18"}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z"
      fill={color || "#CCFF00"}
    />
    <path
      d="M7.93312 11.6842C7.78312 11.6842 7.64062 11.6242 7.53562 11.5192L5.41312 9.39672C5.19563 9.17922 5.19563 8.81922 5.41312 8.60172C5.63062 8.38422 5.99062 8.38422 6.20812 8.60172L7.93312 10.3267L11.7881 6.47172C12.0056 6.25422 12.3656 6.25422 12.5831 6.47172C12.8006 6.68922 12.8006 7.04922 12.5831 7.26672L8.33062 11.5192C8.22562 11.6242 8.08312 11.6842 7.93312 11.6842Z"
      fill={color || "#CCFF00"}
    />
  </svg>
);
