import React from "react";

const PremiumCrownNewIconSvg = ({width="16",height="16"}) => {
  return (
    <svg
    width={width}
    height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6825_8705)">
        <path
          d="M6.85056 1.70135C7.4006 0.8872 8.5994 0.8872 9.14944 1.70135C9.50553 2.22842 10.1745 2.44577 10.7723 2.22866C11.6959 1.89331 12.6657 2.59794 12.6322 3.57991C12.6104 4.21562 13.0239 4.78464 13.6352 4.96043C14.5795 5.23197 14.9499 6.37208 14.3456 7.14679C13.9543 7.64832 13.9543 8.35168 14.3456 8.85321C14.9499 9.62792 14.5795 10.768 13.6352 11.0396C13.0239 11.2154 12.6104 11.7844 12.6322 12.4201C12.6657 13.4021 11.6959 14.1067 10.7723 13.7713C10.1745 13.5542 9.50553 13.7716 9.14944 14.2986C8.5994 15.1128 7.4006 15.1128 6.85056 14.2986C6.49447 13.7716 5.82554 13.5542 5.22766 13.7713C4.30412 14.1067 3.33428 13.4021 3.36783 12.4201C3.38955 11.7844 2.97613 11.2154 2.36482 11.0396C1.42055 10.768 1.0501 9.62792 1.65443 8.85321C2.04567 8.35168 2.04567 7.64832 1.65443 7.14679C1.0501 6.37208 1.42055 5.23197 2.36482 4.96043C2.97613 4.78464 3.38955 4.21562 3.36783 3.57991C3.33428 2.59794 4.30412 1.89331 5.22766 2.22866C5.82554 2.44577 6.49447 2.22842 6.85056 1.70135Z"
          fill="#8D8D8D"
        />
        <g clip-path="url(#clip1_6825_8705)">
          <path
            d="M11.4473 10.1003L11.2934 10.7678C11.2619 10.904 11.1407 11.0003 11.0009 11.0003H5.00094C4.86114 11.0003 4.73994 10.904 4.70844 10.7678L4.55454 10.1003H11.4473ZM12.1934 6.86778L11.5859 9.50028H4.41594L3.80844 6.86778C3.78174 6.75168 3.82614 6.63048 3.92184 6.55938C4.01784 6.48828 4.14654 6.48078 4.24974 6.53988L6.10644 7.60098L7.75104 5.13408C7.80474 5.05368 7.89354 5.00388 7.98984 5.00028C8.08704 4.99608 8.17854 5.03988 8.23764 5.11608L10.1663 7.59588L11.7344 6.55068C11.8367 6.48288 11.9702 6.48348 12.0713 6.55338C12.1727 6.62328 12.2207 6.74808 12.1934 6.86778Z"
            fill="#0C0C0C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6825_8705">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_6825_8705">
          <rect
            width="9.6"
            height="9.6"
            fill="white"
            transform="translate(3.19922 3.19922)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PremiumCrownNewIconSvg;
