import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import TextInput from "../shared/components/TextInput";
import { ButtonPrimary } from "../shared/components/button/Buttons";
import { subscribeUserToNewsletter } from "../services/user";
import {
  toastError,
  toastNotification,
  toastSuccess,
} from "../utils/ToastMessage";
import { NewsletterCoverBagkground } from "../assets/images/image";
import useEnterKeyPress from "../utils/useEnterKeyPress";
import { isValidEmail } from "../utils/extensions";
import ErrorMessageIcon from "../assets/icons/ErrorMessageIcon/ErrorMessageIcon";

type Props = {};

const NewsletterSection = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleSubscribeUserToNewsletter = async () => {
    setError("");
    setLoading(true);
    if (!email) {
      // toastError("Please enter your email address to proceed.");
      setError("Please enter your email address.");
      setLoading(false);
      return;
    }
    if (!isValidEmail(email)) {
      // toastError("Please enter a valid email address.");
      setError("Please enter a valid email address.");
      setLoading(false);
      return;
    }
    try {
      await subscribeUserToNewsletter({ email: email })
        .then((res: any) => {
          if (res?.isSubscribed) {
            toastSuccess(
              "Subscribed",
              "Thanks for subscribing to the newsletter. We will send some sweet content your way soon."
            );
          } else {
            toastNotification(res?.response?.data?.message);
          }
        })
        .catch((err: any) => {
          if (
            err?.response?.data?.message === "This email is already Subscribed."
          ) {
            // toastNotification(err?.response?.data?.message);
            setError(err?.response?.data?.message);
          } else {
            // toastError(
            //   "Ops! Something went wrong.",
            //   err?.response?.data?.message
            // );
            // setError(err?.response?.data?.message);
            setError("Subscription Failed, Oops! Something went wrong. Please try again later.");
          }
        });
    } catch (error: any) {
      // toastError(
      //   "Ops! Something went wrong.",
      //   "Failed to subscribe. Please try again."
      // );
      // setError("Failed to subscribe. Please try again.");
      setError("Subscription Failed, Oops! Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
      setEmail("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubscribeUserToNewsletter();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        gap: { xs: "24px", md: "32px" },
        borderRadius: "24px",
        bgcolor: "#1b1b1b",
        // boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
        padding: { xs: "40px 24px", sm: "40px", lg: "80px" },
        backgroundImage: `url(${NewsletterCoverBagkground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "end",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: { xs: "12px", md: "24px" },
          maxWidth: { xs: "100%", sm: "90%", md: "60%", lg: "60%" },
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            color: "text.secondary",
            fontSize: "58px",
            background:
              "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          variant="h1"
        >
          {/* Join our newsletter and stay updated! */}
          Join our community and claim free products
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            color: "rgba(255, 255, 255, 0.5)",
            maxWidth: { xs: "100%", lg: "80%" },
            width: "100%",
          }}
          variant="subtitle1"
        >
          {/* No Spam. Only valuable content and updates on our products. Join
          5,000+ other creators who enjoy our insights. */}
          No Spam. Only sweet content and updates of our products.
          <br />
          Join 55,000+ other creators in our community
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "16px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: { xs: "100%", sm: "339px" } }}>
          <TextInput
            id="newsletterInput"
            inputStyles={{
              backgroundColor: "#1B1B1B",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "22px",
              width: "100%",
              color: "#FFFFFF",
              padding: "16px",
              height: "52px",
            }}
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value);
            }}
            placeholderFontSize="18px"
            placeholder="Email address..."
            onKeyDown={handleKeyDown}
          />
          {error && (
            <Typography
              className="err_field"
              id="newsletterinputerror"
              color="#E25454"
              variant="body2"
              display={"flex"}
              gap={"4px"}
              marginTop={"12px"}
              alignItems={"center"}
              sx={{ width: "100%" }}
              fontWeight={"400"}
              fontSize="14px"
              lineHeight={"16px"}
              letterSpacing={"8%"}
            >
              {error && (
                <>
                  <span>
                    <ErrorMessageIcon />
                  </span>
                  <span style={{ textAlign: "left" }}>{error}</span>
                </>
              )}
            </Typography>
          )}
        </Box>

        <ButtonPrimary
          LabelStyle={{
            fontSize: "16px !important",
            lineHeight: "22px",
            fontWeight: "600",
          }}
          sx={{
            width: { xs: "100%", sm: "126px" },
            maxWidth: { xs: "100%", sm: "339px" },
          }}
          onClick={() => {
            handleSubscribeUserToNewsletter();
          }}
          isLoading={loading}
          label="Submit"
        />
      </Box>
    </Box>
  );
};

export default NewsletterSection;
