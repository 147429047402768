import React from "react";

const DeleteProfileIconSvg = () => {
  return (
    <svg
      width="133"
      height="133"
      viewBox="0 0 133 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6814_30775)">
        <path
          d="M65.9054 10C38.9469 10 17 31.9469 17 58.9054C17 85.8639 38.9469 107.811 65.9054 107.811C92.8868 107.811 114.834 85.8639 114.834 58.9054C114.834 31.9469 92.8868 10 65.9054 10Z"
          fill="white"
          fill-opacity="0.03"
        />
        <path
          d="M17.5076 58.9054C17.5076 32.2272 39.2272 10.5076 65.9054 10.5076C92.6066 10.5076 114.326 32.2274 114.326 58.9054C114.326 85.5835 92.6066 107.303 65.9054 107.303C39.2272 107.303 17.5076 85.5836 17.5076 58.9054Z"
          stroke="white"
          stroke-opacity="0.12"
          stroke-width="1.01515"
        />
        <g clip-path="url(#clip1_6814_30775)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M64.9717 36.6727C62.5365 36.6727 60.2011 37.6401 58.4792 39.362C56.7573 41.0839 55.7899 43.4193 55.7899 45.8545C55.7899 48.2897 56.7573 50.6251 58.4792 52.347C60.2011 54.0689 62.5365 55.0363 64.9717 55.0363C67.4069 55.0363 69.7423 54.0689 71.4642 52.347C73.1861 50.6251 74.1535 48.2897 74.1535 45.8545C74.1535 43.4193 73.1861 41.0839 71.4642 39.362C69.7423 37.6401 67.4069 36.6727 64.9717 36.6727ZM52.1172 45.8545C52.1172 42.4453 53.4715 39.1757 55.8822 36.765C58.2929 34.3543 61.5625 33 64.9717 33C68.3809 33 71.6505 34.3543 74.0612 36.765C76.4719 39.1757 77.8262 42.4453 77.8262 45.8545C77.8262 49.2637 76.4719 52.5333 74.0612 54.944C71.6505 57.3547 68.3809 58.709 64.9717 58.709C61.5625 58.709 58.2929 57.3547 55.8822 54.944C53.4715 52.5333 52.1172 49.2637 52.1172 45.8545Z"
            fill="#3D3D3D"
          />
          <path
            d="M51.421 65.4856C55.1304 63.6309 59.2213 62.6668 63.3685 62.6699C67.5158 62.6668 71.6066 63.6309 75.316 65.4856C76.3327 65.994 77.3131 66.5654 78.2519 67.1955C82.6747 70.164 82.6747 76.5444 78.2519 79.5129C77.3131 80.143 76.3327 80.7144 75.316 81.2228C71.6066 83.0775 67.5158 84.0416 63.3685 84.0385C59.2213 84.0416 55.1304 83.0775 51.421 81.2228C50.8529 80.9387 50.296 80.6349 49.7515 80.3122C44.4053 77.144 44.4053 69.5644 49.7515 66.3962C50.296 66.0735 50.8529 65.7697 51.421 65.4856Z"
            fill="#3D3D3D"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M82.1273 62.4331C79.9167 62.4331 78.4331 64.0467 78.4331 65.6722C78.4331 66.26 78.1996 66.8238 77.7839 67.2395C77.3682 67.6552 76.8044 67.8887 76.2165 67.8887C75.6287 67.8887 75.0649 67.6552 74.6492 67.2395C74.2335 66.8238 74 66.26 74 65.6722C74 61.2687 77.8095 58 82.1273 58C86.4451 58 90.2546 61.2716 90.2546 65.6722C90.2546 67.2385 89.7581 68.6867 88.9188 69.8895C88.4046 70.6284 87.8017 71.3377 87.249 71.9672L86.9535 72.307C86.5203 72.7928 86.0995 73.2894 85.6915 73.7965C84.731 75.0171 84.3439 75.8653 84.3439 76.5864V77.9489C84.3439 78.5367 84.1103 79.1005 83.6946 79.5162C83.279 79.9319 82.7152 80.1654 82.1273 80.1654C81.5394 80.1654 80.9757 79.9319 80.56 79.5162C80.1443 79.1005 79.9108 78.5367 79.9108 77.9489V76.5835C79.9108 74.2901 81.1284 72.4252 82.213 71.051C82.6741 70.4629 83.1824 69.8836 83.6405 69.3605L83.9065 69.0531C84.3952 68.5114 84.8549 67.9441 85.2837 67.3538C85.6301 66.8612 85.8178 66.2744 85.8215 65.6722C85.8215 64.0467 84.3379 62.4331 82.1273 62.4331ZM79.8812 83.8596C79.8812 83.2718 80.1147 82.708 80.5304 82.2923C80.9461 81.8766 81.5099 81.6431 82.0978 81.6431H82.1273C82.7152 81.6431 83.279 81.8766 83.6946 82.2923C84.1103 82.708 84.3439 83.2718 84.3439 83.8596C84.3439 84.4475 84.1103 85.0113 83.6946 85.427C83.279 85.8426 82.7152 86.0762 82.1273 86.0762H82.0978C81.5099 86.0762 80.9461 85.8426 80.5304 85.427C80.1147 85.0113 79.8812 84.4475 79.8812 83.8596Z"
            fill="#CCFF00"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_6814_30775">
          <rect width="132.5" height="132.5" fill="white" />
        </clipPath>
        <clipPath id="clip1_6814_30775">
          <rect
            width="55"
            height="55"
            fill="white"
            transform="translate(38 31)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteProfileIconSvg;
