import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";

type Props = {};

const Licensing = (props: Props) => {
  const privacyContent = [
    {
      title: "License Grant",
      paragraphs: [
        `UI Wiki grants you a non-exclusive, non-transferable, worldwide license to use, modify, and incorporate the Components into your personal or commercial projects ("End Products").`,
      ],
    },
    {
      title: "Permitted Uses",
      paragraphs: [
        `Personal and Commercial Projects: You may use the Components in an unlimited number of personal and commercial projects.`,
        `Modification: You are permitted to modify, alter, or adapt the Components to fit your project needs.`,
        `Distribution of End Products: You may distribute, license, or sell End Products that incorporate the Components, provided that the Components are not the primary value of the product.`,
      ],
    },
    {
      title: "Prohibited Uses",
      paragraphs: [
        `Resale or Sub-Licensing of Components: You may not resell, redistribute, or sub-license the Components in their original or modified form as standalone assets.`,
        `Stock or Template Platforms: You are prohibited from including the Components in any online or offline archive or database, including stock design or template platforms.`,
        `Trademark Use: The Components may not be used as part of a trademark, service mark, or logo without explicit written permission from UI Wiki.`,
      ],
    },
    {
      title: "Intellectual Property Rights",
      paragraphs: [
        `All rights, titles, and interests in and to the Components remain the exclusive property of UI Wiki. This Agreement does not transfer any intellectual property rights to you, except for the limited license granted herein.`,
      ],
    },
    {
      title: "Termination",
      paragraphs: [
        `UI Wiki reserves the right to terminate this Agreement and your access to the Components at any time if you breach any terms of this Agreement. Upon termination, you must cease all use of the Components and destroy any copies in your possession.`,
      ],
    },
    {
      title: "Disclaimer of Warranties",
      paragraphs: [
        `The Components are provided "as is" without warranty of any kind, express or implied. UI Wiki does not warrant that the Components will meet your requirements or that their operation will be uninterrupted or error-free.`,
      ],
    },
    {
      title: "Limitation of Liability",
      paragraphs: [
        `In no event shall UI Wiki be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use the Components, even if advised of the possibility of such damages.`,
      ],
    },
    {
      title: "Governing Law",
      paragraphs: [
        `This Agreement shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.`,
      ],
    },
    {
      title: "Contact Information",
      paragraphs: [
        `For any questions regarding this Licensing Agreement, please contact us at contact@uiwiki.co.`,
      ],
    },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "24px", sm: "40px", md: "80px" },
          alignItems: "center",
          gap: { xs: "32px", sm: "60px" },
          width: "100%",
          maxWidth: "862px",
        }}
      >
        {/* -------Header Section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "flex-start", sm: "center" },
            textAlign: { xs: "start", sm: "center" },
            gap: "16px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "32px !important",
              width: "60%",
              lineHeight: "110%",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            variant="h1"
          >
            Licensing
          </Typography>
        </Box>

        {/* -------Content section------- */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: { xs: "24px", sm: "32px" },
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              style={{
                marginBottom: "18px",
                color: "white",
                fontWeight: "600",
              }}
            >
              UI Wiki Licensing Agreement
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "primary.main", fontWeight: "400", mb: "18px" }}
            >
              Effective Date: 15 November 2024
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "400",
                color: "rgba(255, 255, 255, 0.5)",
                lineHeight: "24px",
              }}
            >
              This Licensing Agreement ("Agreement") outlines the terms under
              which you ("User") may use the design components ("Components")
              provided by UI Wiki ("Provider"). UI Wiki is a product of Omega
              Orion Pvt. Ltd., the legal entity responsible for the licensing
              and distribution of the Components. By accessing or using the
              Components, you agree to comply with the terms set forth in this
              Agreement.
            </Typography>
          </Box>
          {privacyContent?.map((section: any, index: number) => (
            <Box key={index}>
              <Typography
                variant="subtitle1"
                style={{
                  marginBottom: "12px",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                {section.title}
              </Typography>
              {section?.paragraphs?.map((paragraph: string, pIndex: number) => (
                <Typography
                  variant="subtitle1"
                  key={pIndex}
                  style={{
                    marginBottom:
                      pIndex === section.paragraphs.length - 1 ? "0" : "24px",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    lineHeight: "24px",
                  }}
                >
                  {paragraph}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
        <Typography
          variant="subtitle1"
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontWeight: "400",
          }}
        >
          By using the Components, you acknowledge that you have read,
          understood, and agree to be bound by the terms of this Licensing
          Agreement.
        </Typography>
      </Box>
    </Box>
  );
};

export default Licensing;
