import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CrossIcon from "../../assets/icons/Cross Icon/CrossIcon";
import ComponentCopySuccessIconSvg from "../../assets/icons/componentCopySucessIcon/ComponentCopySuccessIconSvg";

type CopySuccessPopupProps = {
  message: string;
  subtitle: string;
  onClose: () => void;
};

const ComponentCopySuccessPopup = (props: CopySuccessPopupProps) => {
  useEffect(() => {
    // Automatically close the popup after 5 seconds
    const timer = setTimeout(() => {
      props?.onClose();
    }, 5000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, [props?.onClose]);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 5000,
          pointerEvents: "none", // This allows clicks to pass through
        }}
      >
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
          }}
        />
      </Box>

      <Box
        sx={{
          // border: "1px solid red",
          maxWidth: { xs: "90%", sm: "497px", lg: "497px" },
          width: { xs: "90%", sm: "497px", lg: "497px" },
          position: "fixed",
          bottom: "50px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 6000,
          pointerEvents: "auto", // This ensures the popup is clickable
        }}
      >
        {/* Mani content Box */}
        <Box
          sx={{
            maxWidth: { xs: "90%", sm: "497px", lg: "497px" },
            width: { xs: "90%", sm: "497px", lg: "497px" },
            backgroundColor: "#0C0C0C",
            border: "1px solid #131313",
            borderRadius: "12px",
            zIndex: 6000,
            padding: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            {/* Cancel Icon  */}
            <Box
              onClick={(e) => {
                e.stopPropagation();
                props?.onClose();
              }}
              sx={{
                position: "absolute",
                right: "12px",
                top: "12px",
                cursor: "pointer",
                color: "white",
              }}
            >
              <CrossIcon width="14" height="14" />
            </Box>

            {/* Icon  */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: { xs: "0px", sm: "3px", lg: "3px" },
              }}
            >
              <ComponentCopySuccessIconSvg />
            </Box>

            <Box>
              <Typography
                sx={{
                  mb: "3px",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "auto",
                  letterSpacing: "0%",
                  color: "#FFF",
                }}
              >
                {props.message}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                  color: "#858585",
                }}
              >
                {props.subtitle}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>

    //   <Box
    //     sx={{
    //       maxWidth: { xs: "90%", sm: "497px", lg: "497px" },
    //       width: "100%",
    //       position: "fixed",
    //       bottom: "50px",
    //       left: "50%",
    //       transform: "translateX(-50%)",
    //       backgroundColor: "#0C0C0C",
    //       border: "1px solid #131313",
    //       color: "white",
    //       borderRadius: "12px",
    //       zIndex: 6000,
    //       padding: "12px",
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         display: "flex",
    //         // justifyContent: "center",
    //         position: "relative",
    //         //   border: "1px solid red",
    //         gap: "10px",
    //       }}
    //     >
    //       {/* Cancel Icon  */}
    //       <Box
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           props?.onClose();
    //         }}
    //         sx={{
    //           position: "absolute",
    //           right: "0px",
    //           top: "0px",
    //           cursor: "pointer",
    //           color: "white",
    //         }}
    //       >
    //         <CrossIcon width="14" height="14" />
    //       </Box>

    //       {/* Icon  */}
    //       <Box
    //         sx={{
    //           display: "flex",
    //           justifyContent: "center",
    //           mt: { xs: "0px", sm: "3px", lg: "3px" },
    //         }}
    //       >
    //         <ComponentCopySuccessIconSvg />
    //       </Box>

    //       <Box>
    //         <Typography
    //           sx={{
    //             mb: "3px",
    //             fontSize: "16px",
    //             fontWeight: "500",
    //             lineHeight: "auto",
    //             letterSpacing: "0%",
    //             color: "#FFF",
    //           }}
    //         >
    //           {props.message}
    //         </Typography>
    //         <Typography
    //           sx={{
    //             fontSize: "14px",
    //             fontWeight: "500",
    //             lineHeight: "22px",
    //             letterSpacing: "0%",
    //             color: "#858585",
    //           }}
    //         >
    //           {props.subtitle}
    //         </Typography>
    //       </Box>
    //     </Box>
    //   </Box>
  );
};

export default ComponentCopySuccessPopup;
