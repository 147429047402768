import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import TextInput from "../shared/components/TextInput";
import { ImageContainer } from "../shared/components/ImageContainer";
import { WikiLogo } from "../assets/icons/icons";
import { LoginCoverImg } from "../assets/images/image";
import { requestResetPassword } from "../services/authentication";
import Emailicon from "../assets/icons/email/@icon";
import { HomeCoverBg, HomeCoverBgHalf } from "../assets/images/image";
import UiwikiLogo from "../assets/icons/wikiLogoSvg/UiwikiLogo";

import { toastError, toastSuccess } from "../utils/ToastMessage";
import loginImage1 from "../assets/images/LoginImages/loginImage1.jpg";
import loginImage2 from "../assets/images/LoginImages/loginImage2.jpg";
import loginImage3 from "../assets/images/LoginImages/loginImage3.jpg";
import loginImage4 from "../assets/images/LoginImages/loginImage4.jpg";
import loginImage5 from "../assets/images/LoginImages/loginImage5.jpg";
import loginImage6 from "../assets/images/LoginImages/loginImage6.jpg";
import loginImage7 from "../assets/images/LoginImages/loginImage7.jpg";
import useEnterKeyPress from "../utils/useEnterKeyPress";
import ResetPasswordRecoveryMailSentPopup from "./ResetPasswordRecoveryMailSentPopup";
import { isValidEmail } from "../utils/extensions";
import ErrorMessageIcon from "../assets/icons/ErrorMessageIcon/ErrorMessageIcon";
import Footer from "../main/Footer";
import { useGlobalContext } from "../App";
import { useNavigate } from "react-router-dom";

type Props = {};

const ResetPassword = (props: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    activeEmailSentCongratulationPopup,
    setActiveEmailSentCongratulationPopup,
    setEmailForRecoveryPassoword,
  } = useGlobalContext();
  const breakpoint_down_769 = useMediaQuery(theme.breakpoints.down(769));

  const [isImagesLoading, setIsImagesLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  // Error handling states
  const [error, setError] = useState<string>();
  const [randomImageUrl, setRandomImageUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeCongratulationPopup, setActiveCongratulationPopup] =
    useState(false);

  // For Random Images on UI
  const images = [
    loginImage1,
    loginImage2,
    loginImage3,
    loginImage4,
    loginImage5,
    loginImage6,
    loginImage7,
  ];

  // Function to get a random image
  const getRandomImage = () => {
    setIsImagesLoading(true);
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImageUrl(images[randomIndex]);
    setIsImagesLoading(false);
  };

  useEffect(() => {
    getRandomImage();
  }, []);

  const handleEnterKeyPress = () => {
    !isLoading && handleResetPassword();
  };

  // Use the custom hook
  useEnterKeyPress(handleEnterKeyPress);

  const handleResetPassword = async () => {
    setError("");
    if (!email) {
      setError("Please enter your email address to proceed.");
      // toastError("Please enter your email address to proceed.");
      return;
    }
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      // toastError("Please enter a valid email address.");
      return;
    }
    try {
      let res = await requestResetPassword({ username: email });
      if (res.success) {
        setActiveEmailSentCongratulationPopup(true);
        setEmailForRecoveryPassoword(email);
      } else {
        setError("Ops! No account found with this email address. Please check and try again.");
        // toastError(
        //   "Ops! No account found with this email address. Please check and try again."
        // );
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again later.");
      console.log("error");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "background.main",
        width: "100%",
        justifyContent: "center",
        maxWidth: "100%",
        minHeight: "100vh",
        height: "100%",
        // overflow: "hidden",
        paddingX: { xs: "24px", sm: "0px", lg: "0px" },
        pb: { xs: "24px", sm: "0px", lg: "0px" },
      }}
    >
      <img
        style={{
          position: "absolute",
          // top: 0,
          // left: 0,
          width: "100vw",
          height: "100vh",
          // height: "1000px",
          objectFit: "cover",
          zIndex: -100,
        }}
        alt="background"
        // src={HomeCoverBg}
        src={HomeCoverBgHalf}
      />
      <Box
        sx={{
          minHeight: { xs: "100vh", lg: "auto" },
          width: "100%",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          // border: "1px solid red",
          // overflow:"hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100vh",
            // border: "1px solid yellow",
          }}
        >
          {/* {!breakpoint_down_769 && (
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "90%", md: "101%" },
              height: "100%",
              flex: 1,
            }}
          >
            {!isImagesLoading && (
              <img
                style={{
                  aspectRatio: "720/679",
                  width: "105%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="signin cover"
                // src={randomImageUrl ? randomImageUrl["url"] : LoginCoverImg}
                src={randomImageUrl ? randomImageUrl : LoginCoverImg}
              />
            )}
          </Box>
        )} */}

          {activeEmailSentCongratulationPopup && (
            <ResetPasswordRecoveryMailSentPopup
              activeDialog={activeEmailSentCongratulationPopup}
              setActiveDialog={setActiveEmailSentCongratulationPopup}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              // flex: 1.5,
              height: "100%",
              overflow: "scroll",
              scrollbarWidth: "none",
              // bgcolor: "background.default",
              paddingTop: { xs: "60px", md: "80px" },
              paddingBottom: { xs: "0px", md: "80px" },
              // paddingX: { xs: "24px", sm: "0px", lg: "0px" },
              // border: "1px solid green",
            }}
          >
            <Box
              sx={{
                maxWidth: { xs: "100%", sm: "426px", lg: "426px" },
                // maxHeight: { xs: "auto", sm: "625px", lg: "625px" },
                minHeight: { xs: "auto", sm: "440px", lg: "440px" },
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                // position: "relative",
                // border:"1px solid red",
                gap: { xs: "24px", sm: "24px", lg: "24px" },
                border: "1px solid #3D3D3D",
                boxSizing: "border-box",
                borderRadius: "24px",
                bgcolor: "#1B1B1B",
                p: { xs: "24px", sm: "40px", lg: "40px" },
              }}
            >
              <Box
                onClick={() => {
                  navigate("/");
                }}
                sx={{
                  // mb: { xs: "0px", md: "81px" },
                  // position: { xs: "absolute", sm: "static", lg: "static" },
                  // top: "60px",
                  // width: "100%",
                  width: "fit-content",
                  // paddingY: { xs: "25px", sm: "0px", lg: "0px" },
                  cursor: "pointer",
                  // border:"1px solid red"
                }}
              >
                {/* <ImageContainer width={"54px"} height={"54px"} title="logo">
                  {WikiLogo}
                </ImageContainer> */}

                <UiwikiLogo />
              </Box>
              <Box
                sx={{
                  margin: "0",
                  padding: "0",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  // gap: { xs: "40px", sm: "32px", lg: "32px" },
                  gap: { xs: "24px", sm: "24px", lg: "24px" },
                  // alignItems: "flex-start",
                  alignItems: "center",
                  // width: { xs: "100%", sm: "403px", lg: "480px" },
                  width: "100%",
                  justifyContent: "center",
                  // paddingX: { xs: "0px", sm: "24px", lg: "24px" },
                  // marginTop: { xs: "-345px", sm: "0px", lg: "0px" },
                  // marginY: { xs: "12px", sm: "auto" },
                  // border:"1px solid red"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: { xs: "12px", sm: "12px", lg: "12px" },
                    textAlign: "center",
                    width: "100%",
                    // mb: { xs: "24px", md: "32px" },
                    // mb: { xs: "24px",sm:"0px",lg:"0px" },
                    // border:"1px solid red",
                  }}
                >
                  <Typography
                    // variant="h3"
                    sx={{
                      fontWeight: "600",
                      color: "text.secondary",
                      fontSize: "24px",
                      lineHeight: "auto",
                      // fontFamily: "Inter Tight",
                      // border:"1px solid red",
                      m: "0px",
                      p: "0px",
                    }}
                  >
                    Reset password?
                  </Typography>
                  <Typography
                    // variant="subtitle1"
                    sx={{
                      fontWeight: "400",
                      lineHeight: "auto",
                      color: "rgba(255, 255, 255, 0.5)",
                      fontSize: { xs: "14px", sm: "16px", lg: "16px" },
                      // border:"1px solid red",
                      m: "0px",
                      p: "0px",
                    }}
                  >
                    Enter your email and we'll send you instructions on how to
                    reset your password.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: { xs: "24px", sm: "32px", lg: "32px" },
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // gap: "24px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        borderRadius: "12px",
                        // maxWidth: "520px",
                        m: "0px",
                        p: "0px",
                        // height:"fit-content",
                        // height: "95px",
                        // border: "1px solid red",
                      }}
                    >
                      <TextInput
                        id="requestResetPasswordPopupInput"
                        lableStyles={{
                          fontSize: "16px !important",
                          fontWeight: "600",
                          lineHeight: "auto",
                        }}
                        labelAstrickStyle={{ color: "#E25454" }}
                        label="Email"
                        placeholder="Email"
                        inputStyles={{
                          borderRadius: "14px",
                          height: "54px",
                          fontSize: "14px !important",
                          fontWeight: "400",
                          lineHeight: "24px",
                          paddingLeft: "16px",
                          fontFamily:"Inter Tight",
                          // color: errors.loginId ? "#E25454" : "#FFFFFF",
                          // border: errors.loginId ? "1px solid #E25454" : "0",
                          color: "#FFFFFF",
                        }}
                        textinputstyles={{
                          // border:"1px solid black",
                          margin: "0px",
                          padding: "0px",
                          height: "auto",
                          // gap:"11px"
                        }}
                        inputWrapStyle={{
                          minWidth: "10px !important",
                          width: "100%",
                        }}
                        onChange={(e: any) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        required
                      />
                      {error && (
                        <Typography
                          className="err_field"
                          id="emailNotExist"
                          color="#E25454"
                          variant="body2"
                          display={"flex"}
                          gap={"4px"}
                          marginTop={"12px"}
                          alignItems={"center"}
                          sx={{ width: "100%" }}
                          fontWeight={"400"}
                          fontSize="14px"
                          lineHeight={"16px"}
                          letterSpacing={"8%"}
                        >
                          {error && (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <ErrorMessageIcon />
                              {error}
                            </span>
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // gap: "5px",
                      // maxWidth: "520px",
                      // marginTop: "16px",
                    }}
                  >
                    <Button
                      id="signinButton"
                      sx={{
                        width: "100%",
                        height: "54px",
                        textAlign: "center",
                        textTransform: "none",
                        bgcolor: "#CCFF00",
                        color: "#FFFFFF",
                        borderRadius: "14px",
                        paddingY: "16px",
                        border: "1px solid #718B08",
                        "&:hover": {
                          backgroundColor: "primary.main",
                        },
                      }}
                      onClick={() => handleResetPassword()}
                    >
                      <Typography
                        // variant="subtitle1"
                        sx={{
                          color: "text.primary",
                          fontWeight: "600",
                          fontSize: "16px !important",
                          lineHeight: "auto",
                        }}
                      >
                        Send Password Reset Email
                      </Typography>
                    </Button>
                    {error && (
                      <Typography
                        sx={{ width: "100%" }}
                        fontWeight={"400"}
                        variant="caption"
                        color="#E03137"
                      >
                        {/* {error} */}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ResetPassword;
