import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CloseSharp, Height } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { SignUp_Happy } from "../../assets/images/image";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../shared/components/button/Buttons";
import { oneMonthFreeTrial } from "../../services/authentication";
import { getUserIdFromCookies } from "../../utils/extensions";
import { toastSuccess } from "../../utils/ToastMessage";
import { useTheme } from "@mui/material/styles";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  setActiveDialog: any;
};

const HomeCongratulationPopUp: React.FC<Props> = (props) => {
  const userId = getUserIdFromCookies();
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOneMonthFreeTrial = async () => {
    // await oneMonthFreeTrial({ userId })
    //   .then(() => {
    //     toastSuccess("Success", "Free Subscription Activated.");
    //     props?.setActiveDialog(false);
    //   })
    //   .catch((err) => console.log("err", err));
  };
  useEffect(() => {}, [props?.activeDialog]);

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
            maxWidth: { xs: "100%", sm: "610px", lg: "610px" },
            width: { xs: "90%", sm: "100%", lg: "100%" },
            // border:"1px solid red",
          }}
        >
          <Box
            sx={{
              maxWidth:  { xs: "100%", sm: "610px", lg: "610px" },
              width: "100%",
              padding: {
                xs:"52px 24px 24px 24px",
                sm:"52px 27px 24px 26px",
                lg:"52px 27px 24px 26px",
              },
              height: "auto",
              // height: "425px",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "100%",
                width: "100%",
                justifyContent: "center",
                // border:"1px solid red"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.setActiveDialog(false);
                  }}
                  sx={{
                    position: "absolute",
                    right: {xs:"20px",sm:"24px",lg:"24px"},
                    top: {xs:"20px",sm:"24px",lg:"24px"},
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              {/* <Box sx={{ marginTop: { xs: "52px", sm: "0px", lg: "0px" } }}> */}
              <Box>
                {!isXs ? (
                  <ImageContainer
                    title={"Cracker Icon"}
                    height={"154px"}
                    width={"154px"}
                  >
                    {SignUp_Happy}
                  </ImageContainer>
                ) : (
                  <ImageContainer
                    title={"Cracker Icon"}
                    height={"120px"}
                    width={"120px"}
                  >
                    {SignUp_Happy}
                  </ImageContainer>
                )}
              </Box>

              <Box
                sx={{
                  // height: "50%",
                  // height: "179px",
                  height: "auto",
                  // width: "557px",
                  width: { xs: "100%", sm: "557px", lg: "557px" },
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "24px", sm: "32px", lg: "32px" },
                  // paddingX: { xs: "24px", sm: "0px", lg: "0px" },
                  mt: { xs: "24px", sm: "16px", lg: "16px" },
                  // border:"1px solid red",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // border:"1px solid red",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", sm: "24px", lg: "24px" },
                      fontWeight: "600",
                      color: "#FFFFFF",
                      mb: "12px",
                      textAlign: "center",
                      lineHeight: {xs:"28px",sm:"auto",lg:"auto"},
                    }}
                  >
                    Get 1 Month Free!
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px !important",
                      fontWeight: "400 !important",
                      lineHeight: "auto",
                      color: "#FFFFFF",
                      opacity: "50%",
                      width:{xs:"100%", sm:"80%",lg:"80%"},
                      // padding: "0 41px",
                      // paddingX: { xs: "2px" },
                      textAlign: "center",
                      // border:"1px solid red",
                    }}
                  >
                    Kickstart your creative journey with 1 month of premium
                    access - absolutely free, no credit card needed!
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // border:"1px solid red",
                    display: "flex",
                    // height: "300%",
                    flexDirection: {
                      xs: "column-reverse",
                      sm: "row",
                      lg: "row",
                    },
                    height: "auto",
                    width: "100%",
                    maxWidth: "342px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    margin:"0 auto",
                    // mb: { xs: "24px", sm: "0px", lg: "0px" },
                  }}
                >
                  <ButtonSecondary
                    label="Dismiss"
                    sx={{ 
                      // width: { xs: "100%", sm: "35%", lg: "35%" },
                      width: "100%",
                      background:"transparent",
                      fontSize: {xs:"16px !important", sm:"16px !important",lg:"16px !important"},
                      // fontWeight: { xs: "700", sm: "600", lg: "600" },
                      lineHeight: "22px",
                      color: "#000000",
                      padding:"15px 16px",
                      borderRadius:"14px",
                      border:"1px solid #3D3D3D",
                    }}
                    LabelStyle={{
                      fontSize: "16px !important", 
                      fontWeight: "600",
                      lineHeight: "22px",
                      color: "#FFFFFF",
                    }}
                    onClick={() => {
                      props?.setActiveDialog(false);
                    }}
                  />
                  <ButtonPrimary
                    label="Try now for $0"
                    sx={{
                      // width: { xs: "100%", sm: "100%", lg: "35%" },
                      width: "100%",
                      padding:"15px 16px",
                      borderRadius:"14px",
                      fontSize: {xs:"16px !important", sm:"16px !important",lg:"16px !important"},
                      border:"1px solid #526600",
                    }}
                    LabelStyle={{
                      fontSize: "16px !important", 
                      lineHeight: "22px",
                      color: "#000000",
                      fontWeight:"600",
                    }}
                    onClick={() => {
                      handleOneMonthFreeTrial();
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default HomeCongratulationPopUp;
