import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PurchaseCard from "./PurchaseCard";
import { getUserPurchases } from "../../services/component";
import { ImageContainer } from "../../shared/components/ImageContainer";
import { RainIcon } from "../../assets/icons/icons";
import DefaultLoading from "../../shared/Loading/DefaultLoading";
import { getUserIdFromCookies } from "../../utils/extensions";
import CongratulationPopup from "./CongratulationPopup";

type Props = {};

const Purchases = (props: Props) => {
  const userId = getUserIdFromCookies();
  const [loading, setLoading] = useState(true);
  const [activeCongratulationPopup, setActiveCongratulationPopup] =
    useState(false);
  const [purchaseList, setPurchaseList] = useState<any>([]);

  useEffect(() => {
    getUserPurchases(userId)
      ?.then((res: any) => {
        setLoading(false);
        setPurchaseList(res);
      })
      .catch((err: any) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingY: { xs: "24px", sm: "40px", md: "80px" },
        gap: "32px",
        height: "100%",
      }}
    >
      <Box
        sx={{
          margin:"0",
          padding:"0",
          boxSizing:"border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: { xs: "12px", sm: "24px", lg: "24px" },
          paddingX: { xs: "24px", sm: "40px", md: "80px" },
        }}
      >
        <Typography
          sx={{
            margin:"0",
            padding:"0",
            boxSizing:"border-box", 
            fontWeight: "600",
            // fontSize: "58px",
            fontSize: "32px",
            background:
              "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 120%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
          // variant="h1"
        >
          Your Purchases
        </Typography>
        <Typography
          sx={{
            margin:"0",
            padding:"0",
            boxSizing:"border-box",
            fontWeight: "400",
            fontSize: "16px",
            color: "rgba(255, 255, 255, 0.5)",
          }}
          // variant="subtitle1"
        >
          Manage your subscriptions, downloads and invoices
        </Typography>
      </Box>

      {activeCongratulationPopup && (
        <CongratulationPopup
          activeDialog={activeCongratulationPopup}
          setActiveDialog={setActiveCongratulationPopup}
        />
      )}

      {loading ? (
        <Box
          sx={{
            width: "350px",
            height: "350px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "42px",
              height: "42px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DefaultLoading />
          </Box>
        </Box>
      ) : purchaseList?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: { xs: "0px", sm: "24px" },
            backgroundColor: "rgba(255, 255, 255, 0.06)",
            padding: "24px",
            gap: "32px",
            maxWidth: "690px",
            width: "100%",
          }}
        >
          {purchaseList?.map((item: any, index: number) => {
            return (
              <>
                <PurchaseCard data={item} />

                {index !== purchaseList?.length - 1 && (
                  // *****Seperator*****
                  <Box
                    sx={{
                      flex: 1,
                      height: "1px",
                      border: "1px solid rgba(255, 255, 255, 0.06)",
                    }}
                  />
                )}
              </>
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            width: "350px",
            height: "350px",
            aspectRatio: "1/1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ImageContainer title="rain">{RainIcon}</ImageContainer>
        </Box>
      )}
    </Box>
  );
};

export default Purchases;
