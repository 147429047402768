import React from "react";
import { IconColor } from "../interface/ColorInterface";

const UiwikiLogo: React.FC<IconColor> = ({
  color,
  width,
  height,
  transform,
}) => {
  return (
    <svg
      width={width || "62"}
      height={height || "62"}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3636 3.25416C28.8514 1.24048 33.1486 1.24048 36.6364 3.25416L52.2104 12.2458C55.6982 14.2595 57.8468 17.981 57.8468 22.0083V39.9917C57.8468 44.019 55.6982 47.7405 52.2104 49.7542L36.6364 58.7458C33.1486 60.7595 28.8514 60.7595 25.3636 58.7458L9.78958 49.7542C6.30178 47.7405 4.15321 44.019 4.15321 39.9917V22.0083C4.15321 17.981 6.30178 14.2595 9.78957 12.2458L25.3636 3.25416Z"
        fill="#2C2C2C"
      />
      <path
        d="M43.3465 27.3105V44.0525H35.1203V41.396C33.42 43.5448 31.0971 44.6193 28.1514 44.6193C25.2058 44.6193 22.9188 43.6629 21.2904 41.7502C19.6619 39.8374 18.8477 37.1218 18.8477 33.6033V19.2578H27.3253V33.3554C27.3253 34.8667 27.6486 36.0001 28.2951 36.7558C28.9417 37.4878 29.8398 37.8538 30.9893 37.8538C32.1149 37.8538 33.0369 37.4524 33.7553 36.6495C34.4977 35.823 34.8689 34.6305 34.8689 33.072V27.3105H43.3465Z"
        fill="white"
      />
      <path
        d="M34.8438 19.2578H43.3148V25.5908H34.8438L34.8438 19.2578Z"
        fill="#CCFF00"
      />
    </svg>
  );
};

export default UiwikiLogo;
