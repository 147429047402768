import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../../shared/components/button/Buttons";
import { useNavigate } from "react-router-dom";
import { ImageContainer } from "../../../../../shared/components/ImageContainer";
import { HeartBrokenImage } from "../../../../../assets/images/image";

type Props = {
  activeDialog: any;
  handleDialog?: any;
};

const CancelMembershipDialog: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed", // Ensure the backdrop covers the entire screen
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              border: "1px solid red",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed", // Position the dialog above the backdrop
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the dialog
            zIndex: 6000, // Higher zIndex than the backdrop
          }}
        >
          {/* ------Dialog card------ */}
          <Box
            sx={{
              maxWidth: { xs: "90vw", sm: "610px", lg: "610px" },
              width: { xs: "90vw", sm: "610px", lg: "610px" },
              padding: { xs: "24px", sm: "24px" },
              height: "auto",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
            }}
          >
            <CloseSharp
              onClick={(e) => {
                e.stopPropagation();
                props?.handleDialog();
              }}
              sx={{
                position: "absolute",
                right: { xs: "24px", sm: "24px" },
                top: { xs: "24px", sm: "24px" },
                cursor: "pointer",
                color: "white",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: "24px", sm: "0px", lg: "0px" },
                alignItems: "center",
                mt: { xs: "16px", sm: "0px", lg: "0px" },
                // pt: { xs: "0px", sm: "56px" },
                // border:"1px solid red"
              }}
            >
              <ImageContainer
                style={{
                  width: "116px",
                  height: "116px",
                }}
                imageContainerStyles={{marginTop:"36px",}}
                title="heart broken"
              >
                {HeartBrokenImage}
              </ImageContainer>
              
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  gap: { xs: "16px", sm: "12px" },
                  mt: { xs: "0px", sm: "18px", lg: "18px" },
                  mb:{ xs: "0px", sm: "32.5px", lg: "32.5px" },
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    maxWidth: { xs: "100%", sm: "70%" },
                    textAlign: "center",
                    fontWeight:"600",
                    fontSize: { xs: "24px", sm: "24px" },
                    lineHeight: { xs: "28px", sm: "auto" },
                  }}
                  // variant="h3"
                >
                  Sorry to see you go 🙁
                </Typography>
                <Typography
                  // variant="body1"
                  sx={{
                    color: "rgba(255, 255, 255, 0.5)",
                    fontWeight: "400",
                    fontSize:"16px",
                    maxWidth:  { xs: "100%", sm: "80%" },
                    textAlign: "center",
                    lineHeight: "auto",
                    // letterSpacing: "-2%",
                  }}
                >
                  Your plan has been successfully canceled. You will no longer
                  be charged for your membership.
                </Typography>
              </Box>

              {/* ------Action buttons container------ */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column-reverse", sm: "row" },
                  alignItems: "center",
                  gap: { xs: "12px", sm: "10px" },
                  justifyContent: "center",
                  // mt: { xs: "0px", sm: "32px" },
                  width: { xs: "100%", sm: "428px" },
                }}
              >
                 <ButtonSecondary
                  onClick={() => {
                    navigate("/");
                  }}
                  sx={{
                    height: "52px",
                    width: "100%",
                    padding:"15px 16px",
                    borderRadius:"14px",
                    background:"transparent",
                  }}
                  LabelStyle={{
                    fontSize:"16px !important",
                    fontWeight:"600",
                    // lineHeight:"22px",
                    color: "#FFFFFF",
                  }}
                  label="Continue"
                />

                <ButtonPrimary
                  onClick={() => {
                    navigate("/pricing");
                  }}
                  sx={{
                    height: "52px",
                    width: "100%",
                    padding:"15px 16px",
                    borderRadius:"14px",
                    border:"#526600"
                  }}
                  LabelStyle={{
                    fontWeight:"600",
                    // lineHeight:"22px",
                    color: "#000000",
                    fontSize:"16px !important",
                  }}
                  label="Choose plan"
                />


                {/* <ButtonPrimary
                  onClick={() => {
                    navigate("/pricing");
                  }}
                  sx={{
                    width: { xs: "100%", sm: "217px" },
                    padding: "15px 16px",
                    borderRadius: "14px",
                    border: "1px solid #526600",
                  }}
                  LabelStyle={{
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  label="Choose plan"
                /> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default CancelMembershipDialog;

const useStyles = makeStyles(() =>
  createStyles({
    dropdown: {
      width: "100%",
      minWidth: "200px",
    },
    placeholder: {
      color: "#A0AEC0",
      fontSize: "14px",
      fontWeight: "300 !important",
      fontFamily: "Outfit",
    },
    inputContainer: {
      flex: 1,
      maxWidth: "408px",
      width: "100%",
    },
    statusContainer: {
      flex: 1,
      maxWidth: "235px",
      width: "100%",
    },
  })
);
