import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleIcon } from "../assets/icons/icons";
import useEnterKeyPress from "../utils/useEnterKeyPress";
import { isValidEmail } from "../utils/extensions";
import TextInput from "../shared/components/TextInput";
import { ImageContainer } from "../shared/components/ImageContainer";
import { colors } from "../utils/colors";
import {
  registerUser,
  sendEmailVerificationCode,
} from "../services/authentication";
import DefaultLoading from "../shared/Loading/DefaultLoading";
import { handleGoogleSignIn, HOST_NAME } from "../utils/constants";
import Cookies from "js-cookie";
import { useGlobalContext } from "../App";
import { HomeCoverBgHalf } from "../assets/images/image";
import UiwikiLogo from "../assets/icons/wikiLogoSvg/UiwikiLogo";

import loginImage1 from "../assets/images/LoginImages/loginImage1.jpg";
import loginImage2 from "../assets/images/LoginImages/loginImage2.jpg";
import loginImage3 from "../assets/images/LoginImages/loginImage3.jpg";
import loginImage4 from "../assets/images/LoginImages/loginImage4.jpg";
import loginImage5 from "../assets/images/LoginImages/loginImage5.jpg";
import loginImage6 from "../assets/images/LoginImages/loginImage6.jpg";
import loginImage7 from "../assets/images/LoginImages/loginImage7.jpg";
import ErrorMessageIcon from "../assets/icons/ErrorMessageIcon/ErrorMessageIcon";
import EyeOpenIcon from "../assets/icons/EyeIcons/EyeOpenIcon";
import EyeOffIcon from "../assets/icons/EyeIcons/EyeOffIcon";

const SignUp = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { setUserDetails } = useGlobalContext();

  // loading state
  const [showLoading, setShowLoading] = useState(false);
  const [isImagesLoading, setIsImagesLoading] = useState(false);

  // user details states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [emailBorderColor, setEmailBorderColor] = useState("#3D3D3D");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const signinButtonRef = useRef(null);

  // Error handling states
  const [error, setError] = useState<string>();
  const [errors, setErrors] = useState<Record<string, string>>({});

  // const coverImageList = [];

  const [randomImageUrl, setRandomImageUrl] = useState<string | null>(null);

  const inputField1Ref = useRef(null);
  const inputField2Ref = useRef(null);

  const handleEnterKeyPress = () => {
    !showLoading && handleRegister();
  };

  const validateFields = (): boolean => {
    const newErrors: Record<string, string> = {};

    if (!loginId.trim() || !isValidEmail(loginId)) {
      newErrors.loginId = "Please enter a valid email address.";
    }

    if (!password.trim()) {
      newErrors.password = "Password is required.";
    }

    if (!firstName.trim()) {
      newErrors.firstName = "First name is required.";
    }

    if (!lastName.trim()) {
      newErrors.lastName = "Last name is required.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async () => {
    setShowLoading(true);
    if (validateFields()) {
      setError("");
      try {
        const data = {
          email: loginId,
          password: password,
          firstName: firstName,
          lastName: lastName,
        };
        await registerUser(data)
          .then((res: any) => {
            if (
              res?.data?.token !== undefined &&
              res?.data?.token !== "" &&
              res?.data?.token !== null
            ) {
              handleSendEmailVerificationCode(res);
              setShowLoading(false);
            }
          })
          .catch((err) => {
            console.log("err");
            setError(err?.response?.data?.message);
          });
      } catch (error: any) {
        console.log("err");
      } finally {
        setShowLoading(false);
      }
    } else {
      setShowLoading(false);
    }
  };

  const handleSendEmailVerificationCode = async (res: any) => {
    try {
      await sendEmailVerificationCode({ email: loginId })
        .then(() =>
          navigate("/verify-email", {
            state: { email: loginId, res: res },
          })
        )
        .catch((err: any) => console.log("err"));
    } catch (error) {
      console.log("err");
    }
  };

  useEnterKeyPress(handleEnterKeyPress);

  const images = [
    loginImage1,
    loginImage2,
    loginImage3,
    loginImage4,
    loginImage5,
    loginImage6,
    loginImage7,
  ];

  // Function to get a random image
  const getRandomImage = () => {
    setIsImagesLoading(true);
    const randomIndex = Math.floor(Math.random() * images.length);
    setRandomImageUrl(images[randomIndex]);
    setIsImagesLoading(false);
  };

  useEffect(() => {
    getRandomImage();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "background.main",
        width: "100%",
        justifyContent: "center",
        maxWidth: "100%",
        minHeight: "100vh",
        height: "100%",
        // overflow: "hidden",
        paddingX: { xs: "24px", sm: "0px", lg: "0px" },
        pb: { xs: "24px", sm: "0px", lg: "0px" },
      }}
    >
      <img
        style={{
          position: "absolute",
          // top: 0,
          // left: 0,
          width: "100vw",
          height: "100vh",
          // height: "1000px",
          objectFit: "cover",
          zIndex: -100,
        }}
        alt="background"
        // src={HomeCoverBg}
        src={HomeCoverBgHalf}
      />
      <Box
        sx={{
          minHeight: { xs: "100vh", lg: "auto" },
          width: "100%",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          // border: "1px solid red",
          // overflow:"hidden",
        }}
      >
        {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "100vh",
        }}
      > */}
        {/* {!breakpoint_down_769 && (
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "90%", md: "100%" },
              height: "100%",
              flex: 1,
            }}
          >
            {!isImagesLoading && (
              <img
                style={{
                  aspectRatio: "720/679",
                  width: "101%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="signin cover"
                src={randomImageUrl ? randomImageUrl : LoginCoverImg}
              />
            )}
          </Box>
        )} */}

        <Box
          className="custom-scrollbar"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            // flex: 1.5,
            height: "100%",
            // bgcolor: "background.default",
            // overflow: "scroll",
            scrollbarWidth: "none",
            paddingTop: { xs: "60px", md: "80px" },
            paddingBottom: { xs: "40px", md: "80px" },
            // border: "1px solid green",
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "100%", sm: "426px", lg: "426px" },
              // maxHeight: { xs: "auto", sm: "625px", lg: "625px" },
              minHeight: { xs: "auto", sm: "726px", lg: "726px" },
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              // gap: { xs: "0px", md: "67px" },
              gap: { xs: "24px", sm: "24px", lg: "24px" },
              border: "1px solid #3D3D3D",
              boxSizing: "border-box",
              borderRadius: "24px",
              bgcolor: "#1B1B1B",
              p: { xs: "24px", sm: "40px", lg: "40px" },
            }}
          >
            <Box
              onClick={() => {
                navigate("/");
              }}
              sx={{
                // mb: { xs: "12px", md: "81px" },
                // position: { xs: "absolute", sm: "static", lg: "static" },
                // top: "60px",
                // width: "100%",
                width: "fit-content",
                // paddingY: { xs: "20px", sm: "0px", lg: "0px" },
                cursor: "pointer",
                // border: "1px solid red",
              }}
            >
              {/* <ImageContainer width={"54px"} height={"54px"} title="logo">
                {WikiLogo}
              </ImageContainer> */}

              <UiwikiLogo />
            </Box>
            <Box
              sx={{
                margin: "0",
                padding: "0",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                gap: { xs: "24px", sm: "24px", lg: "24px" },
                alignItems: "center",
                // width: { xs: "100%", sm: "404px", lg: "480px" },
                width: "100%",
                justifyContent: "center",
                // paddingX: "24px",
                // mt: { xs: "32px", sm: "0px", lg: "0px" },
                // marginY: { md: "auto" },
                // padding: { xs: "24px", sm: "0px" },
                // paddingTop: { xs: "0px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // gap: "12px",
                  textAlign: "center",
                  width: "100%",
                  // mb: { xs: "40px", md: "32px" },
                }}
              >
                <Typography
                  // variant="h1"
                  sx={{
                    fontSize: "24px",
                    fontWeight: "600",
                    color: "text.secondary",
                    lineHeight: "auto",
                  }}
                >
                  Sign up for UI Wiki
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "24px", sm: "32px", lg: "32px" },
                  // alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // gap: "12px",
                    width: "100%",
                    // border: "1px solid green",
                    // mb: { xs: "40px", sm: "0px", lg: "0px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      width: "100%",
                      // border: "1px solid green",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // flexWrap: "wrap",
                        gap: "12px",
                        boxSizing: "border-box",
                        width: "100%",
                        // border: "1px solid red",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          borderRadius: "12px",
                          boxSizing: "border-box",
                          maxWidth: "100%",
                          flex: 1,
                          m: "0px",
                          p: "0px",
                          // height:"fit-content",
                          // height: "95px",
                          // border: "1px solid green",
                        }}
                      >
                        <TextInput
                          id="SignupInputFields"
                          lableStyles={{
                            fontSize: "16px !important",
                            fontWeight: "600",
                            lineHeight: "auto",
                          }}
                          labelAstrickStyle={{ color: "#E25454" }}
                          inputWrapStyle={{
                            minWidth: "10px !important",
                            width: "100%",
                          }}
                          label="First Name"
                          placeholder="First Name"
                          onChange={(e: any) => {
                            setFirstName(e.target.value);
                          }}
                          onNext={inputField1Ref}
                          value={firstName}
                          required
                          inputStyles={{
                            borderRadius: "14px",
                            height: "54px",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            color: "#FFFFFF",
                            border: errors.firstName
                              ? "1px solid #E25454"
                              : "0px",
                          }}
                          textinputstyles={{
                            // border:"1px solid black",
                            margin: "0px",
                            padding: "0px",
                            height: "auto",
                            // gap: "11px",
                          }}
                        />
                        {errors.firstName && (
                          <Typography
                            className="err_field"
                            id="loginFirstNameNotExist"
                            // color="red"
                            color="#E25454"
                            variant="body2"
                            display={"flex"}
                            gap={"4px"}
                            marginTop={"12px"}
                            alignItems={"center"}
                          >
                            {errors.firstName && (
                              <>
                                <span>
                                  <ErrorMessageIcon />
                                </span>
                                {errors.firstName}
                              </>
                            )}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          borderRadius: "12px",
                          boxSizing: "border-box",
                          maxWidth: "100%",
                          flex: 1,
                          m: "0px",
                          p: "0px",
                          // height:"fit-content",
                          // height: "95px",
                          // border: "1px solid green",
                        }}
                      >
                        <TextInput
                          id="SignupInputFields"
                          lableStyles={{
                            fontSize: "16px !important",
                            fontWeight: "600",
                            lineHeight: "auto",
                          }}
                          labelAstrickStyle={{ color: "#E25454" }}
                          inputWrapStyle={{
                            minWidth: "10px !important",
                            width: "100%",
                          }}
                          label="Last Name"
                          placeholder="Last Name"
                          onChange={(e: any) => {
                            setLastName(e.target.value);
                          }}
                          onNext={inputField1Ref}
                          value={lastName}
                          required
                          inputStyles={{
                            borderRadius: "14px",
                            height: "54px",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            color: "#FFFFFF",
                            border: errors.lastName
                              ? "1px solid #E25454"
                              : "0px",
                          }}
                          textinputstyles={{
                            // border:"1px solid black",
                            margin: "0px",
                            padding: "0px",
                            height: "auto",
                            // gap: "11px",
                          }}
                        />
                        {errors.lastName && (
                          <Typography
                            className="err_field"
                            id="lastNameNotExist"
                            // color="red"
                            color="#E25454"
                            variant="body2"
                            display={"flex"}
                            gap={"4px"}
                            marginTop={"12px"}
                            alignItems={"center"}
                          >
                            {errors.lastName && (
                              <>
                                <span>
                                  <ErrorMessageIcon />
                                </span>
                                {errors.lastName}
                              </>
                            )}
                          </Typography>
                        )}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        borderRadius: "12px",
                        // maxWidth: "480px",
                        // border: "1px solid red",
                        m: "0px",
                        p: "0px",
                        // height:"fit-content",
                        // height: "95px",
                      }}
                    >
                      <TextInput
                        id="SignupInputFields"
                        lableStyles={{
                          fontSize: "16px !important",
                          fontWeight: "600",
                          lineHeight: "auto",
                        }}
                        labelAstrickStyle={{ color: "#E25454" }}
                        label="Email"
                        placeholder="Email"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setLoginId(value);

                          if (isValidEmail(value)) {
                            setEmailBorderColor("#CF0");
                          } else if (value.includes("#")) {
                            setEmailBorderColor("#E25454");
                          } else {
                            setEmailBorderColor("#3D3D3D");
                          }
                        }}
                        onNext={inputField1Ref}
                        value={loginId}
                        required
                        sx={{
                          // fontSize: "16px",
                          // fontWeight: "400",
                          // lineHeight: "24px",
                          "&::placeholder": {
                            color: errors.loginId ? "#E25454" : "#888",
                          },
                        }}
                        inputWrapStyle={{
                          minWidth: "10px !important",
                          width: "100%",
                        }}
                        inputStyles={{
                          borderRadius: "14px",
                          height: "54px",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          paddingLeft: "16px",
                          color:
                            emailBorderColor === "#E25454"
                              ? "#E25454"
                              : "#FFFFFF",
                          border: errors.loginId ? "1px solid #E25454" : "0px",
                        }}
                        textinputstyles={{
                          // border:"1px solid black",
                          margin: "0px",
                          padding: "0px",
                          height: "auto",
                          // gap: "11px",
                        }}
                      />
                      {errors.loginId && (
                        <Typography
                          className="err_field"
                          id="loginIdNotExist"
                          color="#E25454"
                          variant="body2"
                          display={"flex"}
                          gap={"4px"}
                          marginTop={"12px"}
                          alignItems={"center"}
                        >
                          {errors.loginId && (
                            <>
                              <span>
                                <ErrorMessageIcon />
                              </span>
                              {errors.loginId}
                            </>
                          )}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      borderRadius={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        borderRadius: "12px",
                        // maxWidth: "480px",
                        // border: "1px solid red",
                        m: "0px",
                        p: "0px",
                        // height:"fit-content",
                        // height: "95px",
                      }}
                    >
                      <TextInput
                        id="SignupInputFields"
                        lableStyles={{
                          fontSize: "16px !important",
                          fontWeight: "600",
                          lineHeight: "auto",
                        }}
                        labelAstrickStyle={{ color: "#E25454" }}
                        label="Password"
                        placeholder="Password"
                        icononclick={() => setPasswordVisible(!passwordVisible)}
                        icon={
                          !passwordVisible ? <EyeOpenIcon /> : <EyeOffIcon />
                        }
                        type={!passwordVisible ? "password" : "text"}
                        onChange={(e: any) => {
                          setPassword(e.target.value);
                        }}
                        onNext={inputField2Ref}
                        value={password}
                        required
                        inputStyles={{
                          borderRadius: "14px",
                          height: "54px",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          paddingLeft: "16px",
                          color: "#FFFFFF",
                          border: errors.password ? "1px solid #E25454" : "0px",
                        }}
                        inputWrapStyle={{
                          minWidth: "10px !important",
                          width: "100%",
                        }}
                        textinputstyles={{
                          // border:"1px solid black",
                          margin: "0px",
                          padding: "0px",
                          height: "auto",
                          // gap: "11px",
                        }}
                        iconContainerStyles={{ right: "15px" }}
                        iconstyles={{ width: "17px", height: "15px" }}
                      />
                      {errors.password && (
                        <Typography
                          className="err_field"
                          id="loginPassNotExist"
                          color="#E25454"
                          variant="body2"
                          display={"flex"}
                          gap={"4px"}
                          marginTop={"12px"}
                          alignItems={"center"}
                        >
                          {errors.password && (
                            <>
                              <span>
                                <ErrorMessageIcon />
                              </span>
                              {errors.password}
                            </>
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    margin: "0px",
                    padding: "0px",
                    // border: "1px solid green",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px",
                      width: "100%",
                      // height: "54px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      // border: "1px solid red",
                      // gap: "5px",
                      // maxWidth: "480px",
                      // marginTop: { xs: "0px", sm: "16px", lg: "16px" },
                      // mb: { xs: "24px", sm: "0px", lg: "0px" },
                    }}
                  >
                    <Button
                      id="signinButton"
                      sx={{
                        width: "100%",
                        height: "54px",
                        textAlign: "center",
                        textTransform: "none",
                        bgcolor: "primary.main",
                        color: "#FFFFFF",
                        borderRadius: "14px",
                        border: "1px solid #718B08",
                        paddingY: "16px",
                        "&:hover": {
                          backgroundColor: "primary.main",
                        },
                      }}
                      ref={signinButtonRef}
                      onClick={() => handleRegister()}
                      endIcon={
                        showLoading && (
                          <Box
                            sx={{
                              width: "42px",
                              height: "42px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DefaultLoading />
                          </Box>
                        )
                      }
                    >
                      <Typography
                        sx={{
                          color: "text.primary",
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "auto",
                        }}
                        // variant="subtitle1"
                      >
                        {!showLoading && "Sign up"}
                      </Typography>
                    </Button>
                    {errors && (
                      <Typography
                        sx={{ width: "100%" }}
                        fontWeight={"400"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={"4px"}
                        fontSize="14px"
                        lineHeight={"16px"}
                        letterSpacing={"8%"}
                        marginTop={"12px"}
                        variant="caption"
                        color="#E25454"
                      >
                        {error && (
                          <>
                            <span>
                              <ErrorMessageIcon />
                            </span>
                            {error}
                          </>
                        )}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      // border: "1px solid red",
                      // gap: { xs: "24px", md: "32px" },
                    }}
                  >
                    {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    gap: "30px",
                  }}
                >
                  <hr
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      border: "none",
                      flex: 1,
                      height: "0.5px",
                    }}
                  />
                  <Typography variant="body2" sx={{ color: "#A2AB9C" }}>
                    OR
                  </Typography>
                  <hr
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      border: "none",
                      flex: 1,
                      height: "0.5px",
                    }}
                  />
                </Box> */}

                    <Button
                      id="signinButton"
                      sx={{
                        width: "100%",
                        height: "56px",
                        textAlign: "center",
                        textTransform: "none",
                        bgcolor: colors.primaryGrey,
                        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                        color: "#FFFFFF",
                        borderRadius: "14px",
                        // paddingY: "16px",
                        paddingY: "0px",
                        "&:hover": {
                          bgcolor: colors.primaryGrey,
                        },
                      }}
                      ref={signinButtonRef}
                      onClick={() => handleGoogleSignIn()}
                      startIcon={
                        <ImageContainer
                          width={"24px"}
                          height={"24px"}
                          title="google"
                        >
                          {GoogleIcon}
                        </ImageContainer>
                      }
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "auto",
                        }}
                        // variant="subtitle1"
                      >
                        {"Log in with Google"}
                      </Typography>
                    </Button>
                  </Box>

                  <Box>
                    <Typography
                      sx={{
                        // border: "1px solid red",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "rgba(255, 255, 255, 0.5)",
                        textAlign: "center",
                      }}
                      // variant="subtitle1"
                    >
                      Already have an account?{" "}
                      <span
                        onClick={() => {
                          navigate("/login");
                        }}
                        style={{
                          fontWeight: "400",
                          color: "#CF0",
                          cursor: "pointer",
                        }}
                      >
                        Sign in
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default SignUp;
