import React from "react";

const SelectedAvtarTickSvg = () => {
  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="140" height="140" fill="black" fill-opacity="0.6" />
      <path
        d="M57 70.9815L64.8107 79.3333L82.6667 61"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SelectedAvtarTickSvg;
