import React, { createContext, useContext, useEffect, useState } from "react";
import "./App.css";
import {
  NavLink,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { darkTheme, lightTheme } from "./utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import Main from "./main/Main";
import Login from "./login/Login";
import SignUp from "./signup/SignUp";
import ResetPassword from "./resetPassword/ResetPassword";
import Cookies from "js-cookie";
import { getUserById, getUserByToken } from "./services/user";
import SearchScreen from "./pages/search/SearchScreen";
import { Box, CssBaseline } from "@mui/material";
import { getAllComponents } from "./services/component";
import DefaultLoading from "./shared/Loading/DefaultLoading";
import { ToastContainer, useToast } from "react-toastify";
import ReactGA from "react-ga4";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import { loginUserByToken } from "./services/authentication";
import EmailVerificationPage from "./resetPassword/EmailVerificationPage";
import PreLoaderHeroSkeleton from "./components/PreLoaderHeroSkeleton";
import { getAllComponentsCollections } from "./services/collection";
import { getAllConstantValues } from "./services/constantValues";
import { HelmetProvider } from "react-helmet-async";

const TRACKING_ID = "G-CECE1E3NSB";
ReactGA.initialize(TRACKING_ID);

interface GlobalContextType {
  setActiveNavigation: any;
  activeNavigation: any;
  navigations: any;
  setNavigations: any;
  breadcrumbs: any;
  setBreadcrumbs: any;
  breadCrumbsLoading: any;
  setBreadCrumbsLoading: any;
  handleBreadCrumbsLoading: any;
  userDetails: any;
  setUserDetails: any;
  searchProductBy: string;
  setSearchProductBy: any;
  activeLoginDialog: any;
  setActiveLoginDialog: any;
  activeSignupDialog: any;
  setActiveSignupDialog: any;
  activeResetDialog: any;
  setActiveResetDialog: any;
}
export const GlobalContext = createContext<GlobalContextType | undefined>(
  undefined
);
export const useGlobalContext = (): any => useContext(GlobalContext)!;

declare global {
  interface Window {
    gtag: any;
  }
}

function App() {
  const [currentTheme] = useState<"light" | "dark">("dark");
  const theme = currentTheme === "light" ? lightTheme : darkTheme;

  const [loading, setLoading] = useState<boolean>(true);

  const [breadCrumbsLoading, setBreadCrumbsLoading] = useState<boolean>(false);
  const [breadcrumbs, setBreadcrumbs] = useState<any>([
    <NavLink to="/">Home</NavLink>,
  ]);

  // User Login, logout and reset password dialogs visiblity handling states
  const [activeLoginDialog, setActiveLoginDialog] = useState<boolean>(false);
  const [activeSignupDialog, setActiveSignupDialog] = useState<boolean>(false);
  const [activeResetDialog, setActiveResetDialog] = useState<boolean>(false);
  const [
    activeEmailSentCongratulationPopup,
    setActiveEmailSentCongratulationPopup,
  ] = useState(false);
  const [emailForRecoveryPassoword, setEmailForRecoveryPassoword] =
    useState<string>("");

  // Loged in user details handling state
  const [userDetails, setUserDetails] = useState("");

  const [collectionsList, setCollectionsList] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [originalCategories, setOriginalCategories] = useState<any>([]); // Add this
  const [originalCollections, setOriginalCollections] = useState<any>([]);
  // const [render, setRender] = useState(true);

  const fetchCollections = async (filters?: any) => {
    setLoading(true);
    await getAllComponentsCollections(filters)
      .then((res: any) => {
        setLoading(false);
        // Sort collections in descending order based on createdAt
        const sortedCollections = res?.paginatedComponentCollections.sort(
          (a: any, b: any) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          }
        );
        setCollectionsList(sortedCollections);
        setOriginalCollections(sortedCollections);
      })
      .catch((err) => {
        console.log("err");
        setLoading(false);
      });
  };

  const fetchCategories = async (filters?: any) => {
    setLoading(true);
    await getAllConstantValues(filters)
      .then((res: any) => {
        setLoading(false);
        // Sort categories alphabetically by the 'value' property
        const sortedCategories = res?.paginatedConstantValues.sort(
          (a: any, b: any) => {
            return a.value.localeCompare(b.value);
          }
        );
        setCategories(sortedCategories);
        setOriginalCategories(sortedCategories);
      })
      .catch((err) => {
        console.log("err");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCollections();
    fetchCategories({ type: "CATEGORY" });
  }, []);

  // State to manage the visibility of the copy success popup
  const [componentCopiedpopupVisible, setComponentCopiedpopupVisible] =
    useState(false);
  const handleCloseComponentCopiedPopup = () => {
    setComponentCopiedpopupVisible(false);
  };

  // All/Active navigation handling states
  const [navigations, setNavigations] = useState<any>([]);
  const [activeNavigation, setActiveNavigation] = useState<any>(() => {
    try {
      const storedValue = window.localStorage.getItem("ACTIVE_NAVIGATION");
      return storedValue ? JSON.parse(storedValue) : navigations;
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
      return navigations;
    }
  });

  // Component Search handling state
  const [searchProductBy, setSearchProductBy] = useState<string>("");
  const handleBreadCrumbsLoading: any = (loading: boolean) => {
    setBreadCrumbsLoading(loading);
  };

  const getComponents = async () => {
    try {
      await getAllComponents({ page: 1, pageSize: 24 })
        .then((res: any) => {
          localStorage.removeItem("componentsList");
          localStorage.setItem("componentsList", JSON.stringify(res));
        })
        .catch((err: any) => {
          console.log("Error in getting components.");
        });
    } catch (error) {
      console.log("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("componentsList")) {
      setLoading(false);
    }

    getComponents();
    const fetchUserDetails = async (token: string) => {
      await loginUserByToken({ token })
        .then((res) => {
          Cookies.set("jstoken", res?.data?.token);
          Cookies.set("user", JSON.stringify(res?.data));
          Cookies.set("userId", JSON.stringify(res?.data?._id));
          setUserDetails(res?.data?.user);
        })
        .catch((err) => {
          console.log("err", "err in logging user by token");
        });
    };

    const userToken: any = localStorage.getItem("jstoken");
    if (userToken) {
      fetchUserDetails(userToken);
    } else {
      Cookies.remove("jstoken");
    }
  }, []);
  const currentPath = window.location.pathname;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: currentPath });
  }, [currentPath]);

  const globalContextProps = {
    setActiveNavigation,
    activeNavigation,
    navigations,
    setNavigations,
    breadCrumbsLoading,
    setBreadCrumbsLoading,
    breadcrumbs,
    setBreadcrumbs,
    handleBreadCrumbsLoading,
    userDetails,
    setUserDetails,
    searchProductBy,
    setSearchProductBy,
    activeLoginDialog,
    setActiveLoginDialog,
    activeSignupDialog,
    setActiveSignupDialog,
    activeResetDialog,
    setActiveResetDialog,
    activeEmailSentCongratulationPopup,
    setActiveEmailSentCongratulationPopup,
    setEmailForRecoveryPassoword,
    emailForRecoveryPassoword,
    handleCloseComponentCopiedPopup,
    componentCopiedpopupVisible,
    setComponentCopiedpopupVisible,
    collectionsList,
    setCollectionsList,
    categories,
    setCategories,
    originalCategories,
    originalCollections,
  };

  // const [reactLoading, setReactLoading] = useState(false);

  // useEffect(() => {
  //   // Check if it's a new tab visit
  //   const navigationType = window.performance.navigation.type;
  //   const isFirstVisitInNewTab = sessionStorage.getItem("isFirstVisitInNewTab");
  //   // console.log("navigationType",navigationType)
  //   if (navigationType === 0 && !isFirstVisitInNewTab) {
  //     // New tab visit, set loading to true
  //     setReactLoading(true);

  //     const timer = setTimeout(() => {
  //       setReactLoading(false);
  //       sessionStorage.setItem("isFirstVisitInNewTab", "true");
  //     }, 100);

  //     return () => clearTimeout(timer);
  //   }
  // }, []);

  // if (reactLoading) {
  //   return (
  //     <div
  //       style={{
  //         height: "100vh",
  //         width: "100vw",
  //         backgroundColor: "#0C0C0C",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     ></div>
  //     // <PreLoaderHeroSkeleton />
  //   );
  // }
  // Remove initial black screen after App mounts for White flash.
  useEffect(() => {
    const initialLoader = document.getElementById('initial-loader');
    if (initialLoader) {
      initialLoader.remove();
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <GlobalContext.Provider value={globalContextProps}>
        <CssBaseline />
        <ToastContainer />
        <Router>
          <Routes>
            <Route path="/*" element={<Main />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify-email" element={<EmailVerificationPage />} />
            <Route path="/request-reset-password" element={<ResetPassword />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/search" element={<SearchScreen />} />
          </Routes>
        </Router>
      </GlobalContext.Provider>
    </ThemeProvider>
  );
}

export default App;
