import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { colors } from "../utils/colors";
import { ImageContainer } from "../shared/components/ImageContainer";
import {
  ArrowRighticon,
  BehanceIcon,
  DribbleIcon,
  InstagramIcon,
  LinkedIn,
  MailIcon,
  WikiLogoBlack,
} from "../assets/icons/icons";
import TextInput from "../shared/components/TextInput";
import MainContainer from "../shared/components/MainContainer";
import {
  toastError,
  toastNotification,
  toastSuccess,
} from "../utils/ToastMessage";
import { subscribeUserToNewsletter } from "../services/user";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../App";
import useEnterKeyPress from "../utils/useEnterKeyPress";
import { isValidEmail } from "../utils/extensions";
import ErrorMessageIcon from "../assets/icons/ErrorMessageIcon/ErrorMessageIcon";

type Props = {};

const Footer = (props: Props) => {
  const navigate = useNavigate();
  const { setSearchProductBy } = useGlobalContext();
  const [email, setEmail] = useState<string>("");
  const [subscribeLoading, setSubscribeLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleSubscribeUserToNewsletter = async () => {
    setError("");
    setSubscribeLoading(true);
    if (!email) {
      // toastError("Please enter your email address to proceed.");
      setError("Please enter your email address.");
      setSubscribeLoading(false);
      return;
    }
    if (!isValidEmail(email)) {
      // toastError("Please enter a valid email address.");
      setError("Please enter a valid email address.");
      setSubscribeLoading(false);
      return;
    }
    try {
      await subscribeUserToNewsletter({
        email: email,
      })
        .then((res: any) => {
          if (res?.isSubscribed) {
            toastSuccess(
              "Subscribed",
              "Thanks for subscribing to the newsletter. We will send some sweet content your way soon."
            );
          } else {
            toastNotification(res?.response?.data?.message);
          }
        })
        .catch((err: any) => {
          if (
            err?.response?.data?.message === "This email is already Subscribed."
          ) {
            // toastNotification(err?.response?.data?.message);
            setError(err?.response?.data?.message);
          } else {
            // toastError(
            //   "Ops! Something went wrong.",
            //   err?.response?.data?.message
            // );
            // setError(err?.response?.data?.message);
            setError("Subscription Failed, Oops! Something went wrong. Please try again later.");
          }
        });
    } catch (error: any) {
      // toastError(
      //   "Ops! Something went wrong.",
      //   "Failed to subscribe. Please try again."
      // );
      // setError("Failed to subscribe. Please try again.");
      setError("Subscription Failed, Oops! Something went wrong. Please try again later.");
    } finally {
      setSubscribeLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubscribeUserToNewsletter();
    }
  };

  const handleIconClick = (link: string) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:contact@uiwiki.co";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bgcolor: colors.primaryGrey,
        gap: "35px",
        width: "100%",
        overflow: "hidden",
        height: "fit-content",
      }}
    >
      <MainContainer
        styleMainContainer={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          gap: { xs: "24px", md: "35px" },
          padding: {
            xs: "24px",
            sm: "33px 40px 0px 40px",
            lg: "33px 80px 0px 80px",
          },
          width: "100%",
          height: "fit-content",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Footer table */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flex: 1,
            flexDirection: { xs: "column", md: "row" },
            flexWrap: "wrap",
            gap: { xs: "24px", md: "35px" },
            width: "90%",
          }}
        >
          {/* Logo */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              width: "54px",
              height: "54px",
            }}
          >
            <ImageContainer
              style={{ width: "54px", height: "54px" }}
              height="54px"
              title="Main Logo"
            >
              {WikiLogoBlack}
            </ImageContainer>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: { xs: "24px", md: "66px" },
            }}
          >
            {/* Navigation table */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: { xs: "12px", md: "44px" },
                flex: 1,
                paddingTop: { xs: "0px", md: "14px" },
              }}
            >
              {/* Column 1 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "32px",
                  minWidth: "152px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "26px",
                  }}
                >
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      setSearchProductBy("");
                      navigate("/search");
                    }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Explore
                  </Typography>

                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      navigate("/faq");
                    }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    FAQs
                  </Typography>

                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      navigate("/pricing");
                    }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Pricing
                  </Typography>
                </Box>
              </Box>

              {/* Column 2 */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "32px",
                  minWidth: "152px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "26px",
                  }}
                >
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      navigate("/licensing");
                    }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Licensing
                  </Typography>

                  <Typography
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      navigate("/terms-of-use");
                    }}
                    sx={{ cursor: "pointer" }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Terms of use
                  </Typography>

                  <Typography
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      navigate("/privacy-policy");
                    }}
                    sx={{ cursor: "pointer" }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Privacy Policy
                  </Typography>
                  <Typography
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      navigate("/refund-policy");
                    }}
                    sx={{ cursor: "pointer" }}
                    color={"rgba(255, 255, 255, 0.4)"}
                    variant="subtitle1"
                  >
                    Refund Policy
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.2)",
                  userSelect: "none", // Prevents text selection
                }}
                fontWeight={"300"}
                variant="body2"
              >
                &copy; 2024 Omega Orion Pvt. Ltd. All rights reserved.
              </Typography>
            </Box>
          </Box>

          {/* Contact and subscribe Links */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "24px", md: "32px" },
              justifyContent: "flex-start",
              alignItems: "flex-start",
              mt: { xs: "16px", md: "0px" },
              width: { xs: "100%", md: "fit-content" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                minWidth: { xs: "100%", md: "260px" },
                width: { xs: "100%", md: "fit-content" },
              }}
            >
              <Typography
                sx={{ lineHeight: "130%" }}
                color={"text.secondary"}
                variant="subtitle1"
              >
                Join our monthly newsletter 
              </Typography>

              <Box>
                <TextInput
                  id="footerEmailInput"
                  inputWrapStyle={{
                    width: "100%",
                    height: "52px",
                    maxWidth: "308px !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  inputStyles={{
                    color: "#FFFFFF",
                    padding: "16px",
                    height: "52px",
                    fontSize:"14px !important",
                    fontWeight:"100"
                  }}
                  value={email}
                  placeholder="Email address"
                  onKeyDown={handleKeyDown}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                  }}
                  disable={true}
                  icon={
                    subscribeLoading ? (
                      <CircularProgress
                        size={16}
                        sx={{
                          color: "black",
                        }}
                      />
                    ) : (
                      ArrowRighticon
                    )
                  }
                  icononclick={() => {
                    !subscribeLoading && handleSubscribeUserToNewsletter();
                  }}
                  iconstyles={{
                    right: "0px",
                  }}
                  iconContainerStyles={{
                    marginRight: "-10px",
                    borderRadius: "9px",
                    backgroundColor: "#CCFF00",
                    width: "36px",
                    height: "36px",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    top: "auto",
                    bottom: "auto",
                  }}
                />
                {error && (
                  <Typography
                    className="err_field"
                    id="emailNotExist"
                    color="#E25454"
                    variant="body2"
                    display={"flex"}
                    gap={"4px"}
                    marginTop={"12px"}
                    alignItems={"center"}
                    sx={{ width: "100%",maxWidth: "308px !important", }}
                    fontWeight={"400"}
                    fontSize="14px"
                    lineHeight={"16px"}
                    letterSpacing={"8%"}
                  >
                    {error && (
                      <>
                        <span>
                          <ErrorMessageIcon />
                        </span>
                        <span style={{ textAlign: "left" }}>{error}</span>
                      </>
                    )}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography color={"text.secondary"} variant="subtitle1">
                Connect with us
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  width: "100%",
                }}
              >
                {/* Dribble */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "54px",
                    height: "54px",
                    borderRadius: "82px",
                    bgcolor: "rgba(255, 255, 255, 0.02)",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    cursor: "pointer",
                  }}
                  onClick={() => handleIconClick("https://dribbble.com/uiwiki")}
                >
                  <ImageContainer
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    title="dribble"
                  >
                    {DribbleIcon}
                  </ImageContainer>
                </Box>

                {/* Behance */}
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "54px",
                    height: "54px",
                    borderRadius: "82px",
                    bgcolor: "rgba(255, 255, 255, 0.02)",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleIconClick("https://www.behance.net/uiwiki")
                  }
                >
                  <ImageContainer
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    title="dribble"
                  >
                    {BehanceIcon}
                  </ImageContainer>
                </Box> */}

                {/* Instagram */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "54px",
                    height: "54px",
                    borderRadius: "82px",
                    bgcolor: "rgba(255, 255, 255, 0.02)",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleIconClick("https://www.instagram.com/uiwiki.co")
                  }
                >
                  <ImageContainer
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    title="dribble"
                  >
                    {InstagramIcon}
                  </ImageContainer>
                </Box>

                {/* Linkdin */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "54px",
                    height: "54px",
                    borderRadius: "82px",
                    bgcolor: "rgba(255, 255, 255, 0.02)",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleIconClick("https://linkedin.com/company/uiwiki")
                  }
                >
                  <ImageContainer
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    title="linkedin"
                  >
                    {LinkedIn}
                  </ImageContainer>
                </Box>

                {/* Mail */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "54px",
                    height: "54px",
                    borderRadius: "82px",
                    bgcolor: "rgba(255, 255, 255, 0.02)",
                    boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
                    cursor: "pointer",
                  }}
                  onClick={() => handleEmailClick()}
                >
                  <ImageContainer
                    style={{
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    title="dribble"
                  >
                    {MailIcon}
                  </ImageContainer>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{}}>
          <Typography
            sx={{
              fontSize: {
                xs: "90px !important",
                sm: "180px !important",
                md: "300px !important",
                lg: "380px !important",
              }, // Adjust font size for different breakpoints
              fontFamily: "Secular One",
              width: "100%",
              color: "rgba(255, 255, 255, 0.1)",
              textAlign: "center",
              lineHeight: "100%",
              marginBottom: {
                xs: "-65px",
                sm: "-75px",
                md: "-120px",
                lg: "-155px",
              }, // Adjust margin based on screen size
              letterSpacing: { xs: "5px", sm: "10px", md: "20px" }, // Responsive letter-spacing
              whiteSpace: "nowrap", // Prevent text from wrapping to the next line
              overflow: "hidden", // Ensure no text overflow
              userSelect: "none", // Prevents text selection
            }}
          >
            uiwiki
          </Typography>
        </Box>
        {/* <Typography
          sx={{
            fontSize: { xs: "108px !important", md: "401px !important" },
            fontFamily: "Secular One",
            width: "100%",
            color: "rgba(255, 255, 255, 0.1)",
            textAlign: "center",
            lineHeight: "100%",
            marginBottom: { xs: "-67px", md: "-170px !important" },
            letterSpacing: "20px",
          }}
          variant="h1"
        >
          uiwiki
        </Typography> */}
      </MainContainer>
    </Box>
  );
};

export default Footer;
