import axios from "axios";
import Cookies from "js-cookie";
import { HOST_NAME } from "../utils/constants";

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Clear entire local storage
      localStorage.clear();

      // Clear all cookies using js-cookie
      Object.keys(Cookies.get()).forEach((cookieName) => {
        Cookies.remove(cookieName);
      });

      // Redirect to login page
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const getAllComponents = (filters?: any) => {
  return new Promise((resolve, reject) => {
    const pageNumber =
      filters?.page !== "" &&
      filters?.page !== undefined &&
      filters?.page !== null
        ? filters?.page
        : "";
    const pageSize =
      filters?.pageSize !== "" &&
      filters?.pageSize !== undefined &&
      filters?.pageSize !== null
        ? filters?.pageSize
        : "";

    const categoriesArray = filters?.categories || [];
    const collectionsArray = filters?.collections || [];
    const licensesArray = filters?.licenses || [];

    const categoriesJSON =
      categoriesArray?.length !== 0
        ? `${categoriesArray?.map((element: any) => `"${element}"`).join(" ")}`
        : "";

    const collectionsJSON =
      collectionsArray?.length !== 0
        ? `${collectionsArray?.map((element: any) => `"${element}"`).join(" ")}`
        : "";

    const licensesJSON =
      licensesArray?.length !== 0
        ? `${licensesArray?.map((element: any) => `"${element}"`).join(" ")}`
        : "";

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component?search=${
        filters?.searchBy || ""
      }&page=${pageNumber}&pageSize=${pageSize}&category=${categoriesJSON}&collection=${collectionsJSON}&license=${licensesJSON}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getRelatedComponents = (component: any) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "post",
      maxBodyLenght: Infinity,
      url: `${HOST_NAME}/related-component`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: component,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteComponent = (id: string) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getComponentByBlurbUrl = (blurb: string) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component-blurb/${blurb}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getComponentSourceCodeByComponentId = (componentId: string) => {
  return new Promise((resolve, reject) => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/component-source/${componentId}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createComponentFeedback = async (data: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/feedback`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const createComponentReport = (data: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/problem`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadDocument = async (file: any) => {
  let formData = new FormData();
  formData.append("files", file);
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/documents/upload`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    const response = await axios.request(config);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getUserSubscriptions = (id: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/subscription/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUserActiveSubscription = (id: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/active-subscription/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createSubscription = (data: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/create-payment`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        console.log("error");
        reject(error);
      });
  });
};

export const getUserPurchases = (id: any) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${HOST_NAME}/transaction/${id}`,
      headers: {
        Authorization: `Bearer ${Cookies.get("jstoken")}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
