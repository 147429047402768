import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInput from "../../../../shared/components/TextInput";
import { ButtonPrimary } from "../../../../shared/components/button/Buttons";
import { EyeLightIcon, EyeOffLightIcon } from "../../../../assets/icons/icons";
import {
  resetPassword,
  updatePassword,
} from "../../../../services/authentication";
import { toastError, toastSuccess } from "../../../../utils/ToastMessage";
import { useGlobalContext } from "../../../../App";
import { validate_if_not_empty } from "../../../../utils/extensions";
import { useNavigate } from "react-router-dom";
import ErrorMessageIcon from "../../../../assets/icons/ErrorMessageIcon/ErrorMessageIcon";

type Props = {};

const Security = (props: Props) => {
  const {
    userDetails,
    setActiveResetDialog,
    activeResetDialog,
    activeLoginDialog,
    activeSignupDialog,
    activeEmailSentCongratulationPopup,
  } = useGlobalContext();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const navigate = useNavigate();

  // Password visibility handling states
  const [currentPasswordVisible, setCurrentPasswordVisible] =
    useState<boolean>(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [confirmNewPasswordVisible, setconfirmNewPasswordVisible] =
    useState<boolean>(false);

  // Error states for each field
  const [currentPasswordErr, setCurrentPasswordErr] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [savePasswordFunctionError, setSavePasswordFunctionError] =
    useState("");

  // Track which field to scroll to
  const [scrollToField, setScrollToField] = useState<string | null>(null);

  useEffect(() => {
    if (scrollToField) {
      const element = document.getElementById(scrollToField);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      setScrollToField(null); // Reset scroll target
    }
  }, [scrollToField]);

  const handleSavePassword = async () => {
    // Reset all error messages
    setCurrentPasswordErr("");
    setNewPasswordErr("");
    setConfirmPasswordErr("");
    setSavePasswordFunctionError("");

    // Validate fields in order
    if (!currentPassword) {
      setCurrentPasswordErr("Current password is required.");
      setScrollToField("currentPassErrId");
      return;
    }
    if (!newPassword) {
      setNewPasswordErr("New password is required.");
      setScrollToField("newPassErrId");
      return;
    }
    if (!confirmNewPassword) {
      setConfirmPasswordErr("Confirm new password is required.");
      setScrollToField("confirmNewPassErrId");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setConfirmPasswordErr(
        "Confirm password does not match your new password."
      );
      setScrollToField("confirmNewPassErrId");
      return;
    }
    // if (
    //   validate_if_not_empty(currentPassword, "currentPassErrId") &&
    //   validate_if_not_empty(newPassword, "newPassErrId") &&
    //   validate_if_not_empty(confirmNewPassword, "confirmNewPassErrId")
    // ) {
    //   if (newPassword === confirmNewPassword) {
    //     setConfirmPasswordErr("");
    await updatePassword({
      oldPassword: currentPassword,
      newPassword: newPassword,
      userId: userDetails?.id,
    })
      .then((res: any) => {
        if (res?.response?.data?.success || res?.success) {
          // toastSuccess("Successfully reset password.", res?.message);
          toastSuccess(
            "Password Changed",
            "Your password was updated successfully. Use your new password next time you log in."
          );
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
          setSavePasswordFunctionError("");
        } else {
          // toastError(
          //   "Ops! Something went wrong.",
          //   res?.response?.data?.message
          // );
          setSavePasswordFunctionError(
            `Password Reset Failed, ${res?.response?.data?.message || "Something went wrong while updating your password. Please try again later."}`
          );
        }
      })
      .catch((err: any) => {
        console.log("err");
        // toastError(
        //   "Ops! Something went wrong.",
        //   "Failed to reset the password."
        // );
        setSavePasswordFunctionError("Password Reset Failed, Something went wrong while updating your password. Please try again later.");
      });

    // setCurrentPassword("");
    // setNewPassword("");
    // setConfirmNewPassword("");
    // } else {
    //   setConfirmPasswordErr(
    //     "Confirm password does not match your new password."
    //   );
    // }
  };
  //Stop scrolling when any popup is open
  useEffect(() => {
    if (
      activeResetDialog ||
      activeLoginDialog ||
      activeSignupDialog ||
      activeEmailSentCongratulationPopup
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [
    activeResetDialog,
    activeLoginDialog,
    activeSignupDialog,
    activeEmailSentCongratulationPopup,
  ]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: "24px", sm: "48px 56px" },
        minWidth: { xs: "100%", sm: "460px" },
        maxWidth: { xs: "100%", sm: "460px" },
        borderRadius: "16px",
        bgcolor: "rgba(255, 255, 255, 0.06)",
        gap: "32px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            color: "#FFFFFF",
            fontWeight: "600",
            lineHeight: "16px",
            letterSpacing: "-2%",
          }}
          // variant="subtitle1"
        >
          Update your password
        </Typography>
        <Typography
          sx={{
            color: "rgba(255, 255, 255, 0.5)",
            fontWeight: "400",
            lineHeight: "24px",
            letterSpacing: "-2%",
            fontSize: "16px",
          }}
          // variant="body1"
        >
          You can update your password below. If you have forgotten your current
          password, please contact support for assistance.
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "5px",
          }}
        >
          <TextInput
            id="accountSettingInputCurrentPassword"
            inputStyles={{
              backgroundColor: "rgba(255, 255, 255, 0.06) !important",
              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "-2%",
              fontWeight: "400",
              color: "#FFFFFF",
              borderRadius: "16px",
              padding: "15px 16px",
            }}
            lableStyles={{
              fontSize: "16px !important",
              fontWeight: "600",
              lineHeight: "22px",
            }}
            label="Current Password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e: any) => {
              setCurrentPassword(e.target.value);
            }}
            icononclick={() =>
              setCurrentPasswordVisible(!currentPasswordVisible)
            }
            iconContainerStyles={{ right: "15px" }}
            iconstyles={{ width: "24px", height: "24px" }}
            icon={!currentPasswordVisible ? EyeLightIcon : EyeOffLightIcon}
            type={!currentPasswordVisible ? "password" : "text"}
          />
          {currentPasswordErr && (
            <Typography
              className="err_field"
              id="currentPassErrId"
              color="#E25454"
              variant="body2"
              display={"flex"}
              gap={"4px"}
              // marginTop={"12px"}
              alignItems={"center"}
              sx={{ width: "100%" }}
              fontWeight={"400"}
              fontSize="14px"
              lineHeight={"16px"}
              letterSpacing={"8%"}
            >
              {currentPasswordErr && (
                <>
                  <span>
                    <ErrorMessageIcon />
                  </span>
                  {currentPasswordErr}
                </>
              )}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "5px",
          }}
        >
          <TextInput
            id="accountSettingInputCurrentPassword"
            inputStyles={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "-2%",
              fontWeight: "400",
              color: "#FFFFFF",
              borderRadius: "16px",
              padding: "15px 16px",
            }}
            lableStyles={{
              fontSize: "16px !important",
              fontWeight: "600",
              lineHeight: "22px",
            }}
            label="New Password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e: any) => {
              setNewPassword(e.target.value);
            }}
            icononclick={() => setNewPasswordVisible(!newPasswordVisible)}
            iconstyles={{ width: "24px", height: "24px" }}
            iconContainerStyles={{ right: "15px" }}
            icon={!newPasswordVisible ? EyeLightIcon : EyeOffLightIcon}
            type={!newPasswordVisible ? "password" : "text"}
          />
          {newPasswordErr && (
            <Typography
              className="err_field"
              id="newPassErrId"
              color="#E25454"
              variant="body2"
              display={"flex"}
              gap={"4px"}
              // marginTop={"12px"}
              alignItems={"center"}
              sx={{ width: "100%" }}
              fontWeight={"400"}
              fontSize="14px"
              lineHeight={"16px"}
              letterSpacing={"8%"}
            >
              {newPasswordErr && (
                <>
                  <span>
                    <ErrorMessageIcon />
                  </span>
                  {newPasswordErr}
                </>
              )}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "5px",
          }}
        >
          <TextInput
            id="accountSettingInputCurrentPassword"
            inputStyles={{
              backgroundColor: "rgba(255, 255, 255, 0.06)",
              boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "-2%",
              fontWeight: "400",
              color: "#FFFFFF",
              borderRadius: "16px",
              padding: "15px 16px",
            }}
            lableStyles={{
              fontSize: "16px !important",
              fontWeight: "600",
              lineHeight: "22px",
            }}
            label="Confirm New Password"
            placeholder="Confirm New Password"
            value={confirmNewPassword}
            onChange={(e: any) => {
              setConfirmNewPassword(e.target.value);
            }}
            icononclick={() => {
              setconfirmNewPasswordVisible(!confirmNewPasswordVisible);
            }}
            iconContainerStyles={{ right: "15px" }}
            iconstyles={{ width: "24px", height: "24px" }}
            icon={!confirmNewPasswordVisible ? EyeLightIcon : EyeOffLightIcon}
            type={!confirmNewPasswordVisible ? "password" : "text"}
          />
          {/* <Typography
            id="confirmNewPassErrId"
            className="err_field"
            color="red"
            variant="body2"
          >
            {confirmPasswordErr}
          </Typography> */}
          {/* <Typography className="err_field" color="red" variant="body2">
            {confirmPasswordErr}
          </Typography> */}
          {confirmPasswordErr && (
            <Typography
              className="err_field"
              id="confirmNewPassErrId"
              color="#E25454"
              variant="body2"
              display={"flex"}
              gap={"4px"}
              // marginTop={"12px"}
              alignItems={"center"}
              sx={{ width: "100%" }}
              fontWeight={"400"}
              fontSize="14px"
              lineHeight={"16px"}
              letterSpacing={"8%"}
            >
              {confirmPasswordErr && (
                <>
                  <span>
                    <ErrorMessageIcon />
                  </span>
                  {confirmPasswordErr}
                </>
              )}
            </Typography>
            // <Typography
            //   className="err_field"
            //   // id="confirmNewPassErrId"
            //   color="#E25454"
            //   variant="body2"
            //   display={"flex"}
            //   gap={"4px"}
            //   // marginTop={"12px"}
            //   alignItems={"center"}
            //   sx={{ width: "100%" }}
            //   fontWeight={"400"}
            //   fontSize="14px"
            //   lineHeight={"16px"}
            //   letterSpacing={"8%"}
            // >
            //   {confirmPasswordErr}
            // </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "5px",
          }}
        >
          <ButtonPrimary
            onClick={() => {
              handleSavePassword();
            }}
            label="Save"
            sx={{
              borderRadius: "16px",
              border: "1px solid #718B08",
            }}
            LabelStyle={{
              fontSize: "16px !important",
              fontWeight: "600",
              lineHeight: "22px",
            }}
          />
          {savePasswordFunctionError && (
            <Typography
              className="err_field"
              id="savepasswordfunctionerrrid"
              color="#E25454"
              variant="body2"
              display={"flex"}
              gap={"4px"}
              // marginTop={"12px"}
              alignItems={"center"}
              sx={{ width: "100%", marginTop: "8px" }}
              fontWeight={"400"}
              fontSize="14px"
              lineHeight={"16px"}
              letterSpacing={"8%"}
            >
              {savePasswordFunctionError && (
                <>
                  <span>
                    <ErrorMessageIcon />
                  </span>
                  {savePasswordFunctionError}
                </>
              )}
            </Typography>
          )}
        </Box>

        <Typography
          onClick={() => {
            setActiveResetDialog(true);
          }}
          // variant="subtitle1"
          sx={{
            color: "#E25454",
            textAlign: "center",
            width: "100%",
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "auto",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Forgot password
        </Typography>
      </Box>
    </Box>
  );
};

export default Security;
