import React, { useEffect, useState } from "react";
import ActiveMembershipCard from "./ActiveMembershipCard";
import { Box, Typography } from "@mui/material";
import { ButtonPrimary } from "../../../../shared/components/button/Buttons";
import { ImageContainer } from "../../../../shared/components/ImageContainer";
import { RainIcon } from "../../../../assets/icons/icons";
import MembershipDialog from "./membershipDialog/MembershipDialog";
import {
  getUserActiveSubscription,
  getUserSubscriptions,
} from "../../../../services/component";
import { useGlobalContext } from "../../../../App";
import DefaultLoading from "../../../../shared/Loading/DefaultLoading";
import RainIconSvg from "../../../../assets/icons/rainSvg/RainIconSvg";

type Props = {};

const Membership = (props: Props) => {
  const { userDetails } = useGlobalContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [membershipDialog, setMembershipDialog] = useState<boolean>(false);
  const [activeMembershipName, setActiveMembershipName] = useState<any>();
  const [userSubscriptions, setUserSubscriptions] = useState<any>();
  //   {
  //   type: "Regular monthly membership",
  //   price: 10,
  //   features: [
  //     {
  //       title: "Components",
  //       list: ["Premium components 40+"],
  //     },
  //     {
  //       title: "Apps and Tools",
  //       list: ["Chrome Extension", "Figma Plugin"],
  //     },
  //     {
  //       title: "Other",
  //       list: ["Priority Support", "Discount to partner tools"],
  //     },
  //   ],
  // }

  const handleCloseMembershipDialog = () => {
    setMembershipDialog(false);
  };
  const handleRefresh = () => {
    setActiveMembershipName("");
  };

  const fetchUserSubsctiptions = async () => {
    if (!userDetails?.id) {
      return;
    }
    await getUserActiveSubscription(userDetails?.id)
      .then((res: any) => {
        setActiveMembershipName(res?.activeSubscriptionName);
        setUserSubscriptions(res?.userSubscriptions);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err");
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchUserSubsctiptions();
  }, []);

  //Stop scrolling when membershipDialog popup is open
  useEffect(() => {
    if (membershipDialog) {
      document.body.style.overflow = "hidden";
      document.body.classList.add("no-scroll");
      document.documentElement.classList.add("no-scroll");
    } else {
      document.body.style.overflow = "auto";
      document.body.classList.remove("no-scroll");
      document.documentElement.classList.remove("no-scroll");
    }

    return () => {
      document.body.style.overflow = "auto";
      document.body.classList.remove("no-scroll");
      document.documentElement.classList.remove("no-scroll");
    };
  }, [membershipDialog]);

  return loading ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // padding: { xs: "24px", sm: "48px 56px 48px 56px", lg: "0px 56px 0px 56px"  },
        minWidth: { xs: "100%", sm: "460px" },
        maxWidth: { xs: "100%", sm: "460px" },
        // height: "70vh",
        minHeight: "700px",
        height: "auto",
        backgroundColor: "rgba(255, 255, 255, 0.06)",
        borderRadius: "16px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "34px",
            height: "34px",
          }}
        >
          <DefaultLoading width="34px" height="34px" />
        </Box>
      </Box>
    </Box>
  ) : activeMembershipName ? (
    <ActiveMembershipCard
      handleRefresh={handleRefresh}
      membershipDialog={membershipDialog}
      activeMembershipName={activeMembershipName}
      setMembershipDialog={setMembershipDialog}
      userSubscriptions={userSubscriptions}
    />
  ) : (
    <Box sx={{ width: "100%" }}>
      {membershipDialog && (
        <MembershipDialog
          handleDialog={handleCloseMembershipDialog}
          activeDialog={membershipDialog}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "32px 24px", sm: "48px 56px", lg: "48px 56px" },
          minWidth: { xs: "100%", sm: "460px" },
          maxWidth: { xs: "100%", sm: "460px" },
          backgroundColor: "rgba(255, 255, 255, 0.06)",
          borderRadius: "16px",
          alignItems: "center",
          gap: { xs: "42px", sm: "0px" },
          // border: "1px solid red",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            textAlign: "center",
            width: "100%",
            color: "#FFFFFF",
            fontSize: "24px !important",
            lineHeight: "auto",
          }}
          // variant="h4"
        >
          Select your plan here
        </Typography>

        <Box
          sx={{
            width: "240px",
            height: "213px",
            aspectRatio: "1/1",
            display: "flex",
            gap: "22px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // border: "1px solid red",
            mt: { xs: "0px", sm: "115px" },
            mb: { xs: "0px", sm: "115px" },
          }}
        >
          <Box sx={{ width: "166px", height: "147px", m: "0px", p: "0px" }}>
            <ImageContainer
              title="rain"
              style={
                {
                  // width: "166px",
                  // height: "147px",
                  // border: "1px solid yellow",
                }
              }
              imageContainerStyles={{
                // border: "1px solid red",
                width: "166px",
                height: "147px",
              }}
            >
              {RainIcon}
            </ImageContainer>
          </Box>
          {/* <Box>
            <RainIconSvg/>
          </Box> */}

          <Typography
            // variant="h5"
            sx={{
              textAlign: "center",
              width: "100%",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "22px",
              // border:"1px solid red",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            I can{" "}
            <span
              style={
                {
                  // color: "#CCFF00",
                }
              }
            >
              smile
            </span>
            , but you need to select my plan first
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: { xs: "42px", sm: "48px" },
            alignItems: "center",
            width: "100%",
          }}
        >
          <ButtonPrimary
            sx={{
              width: "100%",
              maxWidth: "348px",
              borderRadius: "16px",
              border: "1px solid #718B08",
            }}
            LabelStyle={{
              color: "#000000",
              fontSize: "16px",
              lineHeight: "22px",
              fontWeight: "600",
            }}
            onClick={() => {
              setMembershipDialog(true);
            }}
            label="Choose a plan"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Membership;
