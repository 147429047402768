import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactMarkdown from "react-markdown";
import ExpandIconDown from "../../assets/icons/Expand Icon Faq/ExpandIconDown";

type Props = {
  question: string;
  description: string | string[];
  panel: string;
  expanded: string | false;
  handleChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
};

const FaqQuestion = (props: Props) => {
  const { panel, expanded, handleChange } = props;
  const [expandedCategory, setExpandedCategory] = useState(false);

  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpandedCategory(isExpanded);
  };
  return (
    <Accordion
      // expanded={expandedCategory}
      // onChange={handleAccordionChange}
      expanded={expanded === panel}
      onChange={handleChange(panel)}
      sx={{
        width: "100%",
        bgcolor: "#1B1B1B",
        border: "1px solid #3D3D3D",
        backgroundImage: "none",
        padding: { xs: "24px 24px 24px 0px", md: "0px 0px 0px 0px" },
        borderRadius: "14px !important",
        minHeight: "0px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
        marginTop: "0px !important",
        margin: "0px !important",
        "&::before": {
          height: "0px !important", // Remove the line
          display: "none !important", // Hide the pseudo-element
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          margin: "0px",
          minHeight: "0px !important",
        },
      }}
    >
      {/* MuiPaper-root- */}
      <AccordionSummary
        sx={{
          position: "relative",
          // border:"1px solid red",
          // padding: { xs: "0px 0px 0px 0px", md: "26px 32px 0px 0px" },
          width: "100%",
          bgcolor: "transparent",
          color: "white",
          // padding: "0px",
          fontSize: { xs: "18px", md: "18px" },
          fontWeight:"500",
          lineHeight:"28px",
          // borderLeft: "6px solid #CCFF00",
          minHeight: "28px !important",
          paddingLeft: { xs: "24px", md: "24px" },
          paddingRight: { xs: "0px", md: "24px" },
          mt: "0px",
          // "& .Mui-expanded": {
          //   paddingY: "6px",
          //   margin: "0px",
          // },
          "& .MuiSvgIcon-root": {
            width: "24px",
            height: "24px",
          },
          "& .MuiAccordionSummary-content": {
            margin: "0px !important",
          },
          display: "flex",
          alignItems: "center",
        }}
        expandIcon={<ExpandIconDown />}
        // aria-controls="panel1-content"
        // id="panel1-header"
        aria-controls={`${panel}-content`}
        id={`${panel}-header`}
      >
        <Box
          sx={{
            minHeight: "100%",
            // mb:"26px",
            display: "flex",
            alignItems: "center",
            // margin: { md: "32px 0px 32px 0px" },
            // margin: { md: "24px 0px 24px 0px" },
            margin: { md: expanded === panel ? "24px 0px 16px 0px" : "24px 0px 24px 0px" },
            marginY: "auto",
            // border:"1px solid red"
            transition: "margin 0.2s ease",
          }}
        >
          <Box
            sx={{
              borderLeft: "6px solid #CCFF00",
              minHeight: "28px !important",
              position: "absolute",
              left: "0px",
            }}
          ></Box>
          {props?.question}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          padding: "0px",
          color: "rgba(255, 255, 255, 0.5)",
          lineHeight: "120%",
          paddingLeft: { xs: "24px", md: "24px" },
          paddingRight: { xs: "24px", md: "24px" },
          mt: { xs: "10px", md: "0px" },
          // mb: "4px",
          fontSize: "16px !important",
          fontWeight: "400",
          pb: { xs: "0px", md: "24px" },
          // border:"1px solid red",
          // mt:"-11px"
        }}
      >
        {/* <ReactMarkdown>{props?.description}</ReactMarkdown> */}

        {/* Render description as a list if it's an array, otherwise as a string */}
        {Array.isArray(props.description) ? (
          <ul
            style={{
              margin: 0,
              paddingLeft: "18px",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              // border:"1px solid red"
            }}
          >
            {props.description.map((item, index) => (
              <li
                key={index}
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "auto",
                  letterSpacing: "0%",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        ) : (
          <ReactMarkdown>{props.description}</ReactMarkdown>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqQuestion;
