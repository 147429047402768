import React from "react";

const NewPricingCardTickIconSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625Z"
        fill="#CCFF00"
      />
      <path
        d="M7.42956 11.6253C7.56237 11.7581 7.74289 11.8342 7.93312 11.8342C8.12336 11.8342 8.30388 11.7581 8.43669 11.6253L12.6892 7.37278C12.9653 7.09671 12.9653 6.64173 12.6892 6.36565C12.4131 6.08957 11.9581 6.08957 11.6821 6.36565L7.93312 10.1146L6.31419 8.49565C6.03811 8.21957 5.58314 8.21957 5.30706 8.49565C5.03098 8.77173 5.03098 9.22671 5.30706 9.50278L7.42956 11.6253Z"
        fill="#181818"
        stroke="#181818"
        stroke-width="0.3"
      />
    </svg>
  );
};

export default NewPricingCardTickIconSvg;
