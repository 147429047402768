import React from "react";

const CrackerIconSvg = ({width="122",height="122"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.12">
        <path
          d="M20.3996 80.122L28.9879 54.3572C32.8818 42.6755 34.8288 36.8347 39.4356 35.7471C44.0424 34.6596 48.3959 39.0131 57.103 47.7202L74.2795 64.8967C82.9865 73.6038 87.3401 77.9573 86.2525 82.5641C85.165 87.1709 79.3242 89.1179 67.6424 93.0118L41.8776 101.6C27.8201 106.286 20.7913 108.629 17.0811 104.919C13.3709 101.208 15.7138 94.1796 20.3996 80.122Z"
          stroke="white"
          strokeWidth="4.60377"
          strokeLinecap="round"
        />
        <path
          d="M62.1964 93.2597C62.1964 93.2597 58.3424 81.664 58.3424 73.9895C58.3424 66.3149 62.1964 54.7192 62.1964 54.7192M40.9991 99.0408C40.9991 99.0408 37.7441 85.052 37.1451 75.9165C36.1553 60.8228 40.9991 37.376 40.9991 37.376"
          stroke="white"
          strokeWidth="4.60377"
          strokeLinecap="round"
        />
        <path
          d="M73.75 50.8636L74.4819 47.2039C75.2209 43.5088 77.8832 40.493 81.4581 39.3014C85.033 38.1098 87.6953 35.094 88.4343 31.3989L89.1662 27.7393"
          stroke="white"
          strokeWidth="4.60377"
          strokeLinecap="round"
        />
        <path
          d="M35.2283 20.03C36.2931 18.9652 38.0195 18.9652 39.0843 20.03C40.1491 21.0948 40.1491 22.8212 39.0843 23.886C38.0195 24.9508 36.2931 24.9508 35.2283 23.886C34.1635 22.8212 34.1635 21.0948 35.2283 20.03Z"
          fill="white"
        />
        <path
          d="M61.7986 36.3816C62.8634 35.3168 64.5898 35.3168 65.6546 36.3816C66.7194 37.4464 66.7194 39.1728 65.6546 40.2376C64.5898 41.3024 62.8634 41.3024 61.7986 40.2376C60.7338 39.1728 60.7338 37.4464 61.7986 36.3816Z"
          fill="white"
        />
        <path
          d="M87.2166 51.6316C88.2814 50.5668 90.0077 50.5668 91.0725 51.6316C92.1373 52.6964 92.1373 54.4228 91.0725 55.4876C90.0077 56.5524 88.2814 56.5524 87.2166 55.4876C86.1518 54.4228 86.1518 52.6964 87.2166 51.6316Z"
          fill="white"
        />
        <path
          d="M96.865 77.8425C97.9298 76.7777 99.6562 76.7777 100.721 77.8425C101.786 78.9073 101.786 80.6337 100.721 81.6985C99.6562 82.7633 97.9298 82.7633 96.865 81.6985C95.8002 80.6337 95.8002 78.9073 96.865 77.8425Z"
          fill="white"
        />
        <path
          d="M98.4036 39.2124C94.9964 42.6195 97.4414 52.4984 97.4414 52.4984C97.4414 52.4984 107.32 54.9435 110.727 51.5362C114.336 47.9276 112.294 43.1912 106.734 43.206C106.749 37.6456 102.012 35.6037 98.4036 39.2124Z"
          stroke="white"
          strokeWidth="4.60377"
          strokeLinejoin="round"
        />
        <path
          d="M77.21 17.3717L77.0697 17.8654C76.9157 18.4076 76.8386 18.6787 76.8751 18.9448C76.9115 19.211 77.0573 19.442 77.3489 19.9042L77.6144 20.3249C78.6405 21.9512 79.1536 22.7643 78.804 23.4293C78.4545 24.0944 77.4738 24.171 75.5125 24.3241L75.005 24.3637C74.4477 24.4072 74.169 24.429 73.9254 24.557C73.6818 24.6851 73.5008 24.9049 73.1388 25.3446L72.8092 25.7449C71.5353 27.2922 70.8984 28.0658 70.1718 27.9663C69.4452 27.8668 69.1103 26.9601 68.4403 25.1467L68.2669 24.6775C68.0766 24.1622 67.9814 23.9046 67.7943 23.7176C67.6073 23.5305 67.3497 23.4353 66.8344 23.245L66.3652 23.0716C64.5518 22.4016 63.6451 22.0666 63.5456 21.3401C63.4461 20.6135 64.2197 19.9766 65.767 18.7027L66.1673 18.3731C66.607 18.0111 66.8268 17.8301 66.9549 17.5865C67.0829 17.3429 67.1047 17.0642 67.1482 16.5069L67.1878 15.9994C67.3409 14.0381 67.4175 13.0574 68.0826 12.7079C68.7476 12.3583 69.5607 12.8714 71.187 13.8975L71.6077 14.163C72.0698 14.4546 72.3009 14.6004 72.5671 14.6368C72.8332 14.6733 73.1043 14.5962 73.6465 14.4421L74.1402 14.3019C76.0482 13.7597 77.0023 13.4886 77.5128 13.9991C78.0233 14.5096 77.7522 15.4637 77.21 17.3717Z"
          stroke="white"
          strokeWidth="4.60377"
        />
      </g>
      <path
        d="M89.3164 66.4816L90.3984 67.1063C93.7387 69.0348 97.9384 68.6095 100.824 66.0505C103.438 63.7331 107.159 63.1453 110.36 64.5446L111.841 65.1925"
        stroke="#CCFF00"
        strokeWidth="4.60377"
        strokeLinecap="round"
      />
      <path
        d="M49.7713 13.2394C48.0558 16.0457 48.4857 19.6619 50.8115 21.9877L51.3091 22.4852C53.3076 24.4838 54.0446 27.4198 53.2268 30.1253"
        stroke="#CCFF00"
        strokeWidth="4.60377"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CrackerIconSvg;
