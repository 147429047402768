import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import PopupDialog from "../../../../../shared/components/PopupScreen/Dialog";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../../../shared/components/button/Buttons";
import DeleteProfileIconSvg from "../../../../../assets/icons/deleteProfileIcon/DeleteProfileIconSvg";
import CrossIcon from "../../../../../assets/icons/Cross Icon/CrossIcon";

interface DeleteDialogProps {
  deleteAction: () => void;
  title: string;
  setIsDeleteDialogVisible?: any;
}

const DeleteDialog = (props: DeleteDialogProps) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const handleDelete = () => {
    setShowLoading(true);
    props?.deleteAction();
  };

  return (
    // <PopupDialog
    //   style={{
    //     width: "376px !important",
    //     maxHeight: "fi-content",
    //     height: "100%",
    //     overflow: "hidden !important",
    //     display: "flex",
    //     gap: "36px",
    //     justifyContent: "center",
    //     backgroundColor: "#1B1B1B",
    //     boxShadow: "inset 0px 0px 0px 1px #3D3D3D",
    //   }}
    //   open={true}
    //   title="Delete"
    //   titleStyle={{
    //     color: "white",
    //   }}
    //   onClose={() => {
    //     props?.setIsDeleteDialogVisible(false);
    //   }}
    //   children={
    //     <Box
    //       className="HideScrollbar"
    //       sx={{
    //         display: "flex",
    //         gap: "36px",
    //         flexDirection: "column",
    //         width: "100%",
    //         overflow: "scroll",
    //       }}
    //     >
    //       <Typography
    //         sx={{ textAlign: "center", color: "rgba(255, 255, 255, 0.5)" }}
    //         variant="body2"
    //       >
    //         {props?.title}
    //       </Typography>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           gap: "10px",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <ButtonSecondary
    //           onClick={() => {
    //             props?.setIsDeleteDialogVisible(false);
    //           }}
    //           LabelStyle={{ fontSize: "14px !important", fontWeight: "600" }}
    //           sx={{
    //             borderRadius: "30px !important",
    //             border: "1px solid rgba(0, 0, 0, 0.20)",
    //             width: "115px",
    //             height: "52px",
    //           }}
    //           label="Cancel"
    //         />
    //         <ButtonPrimary
    //           onClick={() => {
    //             handleDelete();
    //           }}
    //           isLoading={showLoading}
    //           loadingColor = {"#FFFFFF"}
    //           LabelStyle={{ fontSize: "14px !important", fontWeight: "600" }}
    //           sx={{
    //             borderRadius: "30px !important",
    //             bgcolor: "#E03137",
    //             width: "115px",
    //             color: "white",
    //             height: "52px",
    //             ":hover": {
    //               backgroundColor: "#E03137",
    //               boxShadow: "none",
    //             },
    //             ":disabled": {
    //               boxShadow: "none",
    //               backgroundColor: "#E03137",
    //             },
    //           }}
    //           label="Delete"
    //         />
    //       </Box>
    //     </Box>
    //   }
    // />

    <>
      <Box
        sx={{
          position: "fixed", // Ensure the backdrop covers the entire screen
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          bgcolor: "rgba(0, 0, 0, 0.60)",
          zIndex: 5000,
        }}
      >
        {/* Apply blur effect to only this background */}
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            border: "1px solid red",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            filter: "blur(62px)",
          }}
        />
      </Box>

      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 6000,
        }}
      >
        {/* ------Dialog card------ */}
        <Box
          sx={{
            width: { xs: "90vw", sm: "448px", lg: "448px" },
            maxWidth: { xs: "90vw", sm: "448px", lg: "448px" },
            padding: { xs: "24px", sm: "24px", lg: "24px" },
            height: "auto",
            bgcolor: "#1B1B1B",
            borderRadius: "24px",
            border: "1px solid #3D3D3D",
            cursor: "default",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            // gap: "16px",
          }}
        >
          {/* Cancel Icon  */}
          <Box
            onClick={(e) => {
              e.stopPropagation();
              props?.setIsDeleteDialogVisible(false);
            }}
            sx={{
              position: "absolute",
              right: "24px",
              top: "24px",
              cursor: "pointer",
              color: "white",
            }}
          >
            <CrossIcon />
          </Box>

          {/* Icon  */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: { xs: "0px", sm: "28px", lg: "28px" },
            }}
          >
            <DeleteProfileIconSvg />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "32px",
              maxWidth: "448px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  color: "#FFFFFF",
                  lineHeight: "auto",
                }}
              >
                Delete
              </Typography>
              <Typography
                sx={{
                  mt: "16px",
                  fontSize: "16px !important",
                  fontWeight: "400",
                  color: "rgba(255, 255, 255, 0.5)",
                  textAlign: "center",
                }}
              >
                {props?.title}
              </Typography>
            </Box>

            {/* ------Action buttons container------ */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row-reverse" },
                alignItems: "center",
                gap: "12px",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <ButtonPrimary
                onClick={() => {
                  handleDelete();
                }}
                isLoading={showLoading}
                loadingColor={"#FFFFFF"}
                LabelStyle={{
                  fontSize: "16px !important",
                  fontWeight: "600",
                  lineHeight: "auto",
                }}
                sx={{
                  borderRadius: "16px !important",
                  bgcolor: "#E25454",
                  width: "100%",
                  color: "white",
                  ":hover": {
                    backgroundColor: "#e74242",
                    boxShadow: "none",
                  },
                  ":disabled": {
                    boxShadow: "none",
                    backgroundColor: "#E25454",
                  },
                }}
                label="Delete"
              />

              <ButtonSecondary
                onClick={() => {
                  props?.setIsDeleteDialogVisible(false);
                }}
                sx={{
                  background: "transparent",
                  // border: "1px solid #3D3D3D",
                  borderRadius: "16px !important",
                  width: "100%",
                  ":hover": {
                    bgcolor: "#1B1B1B",
                    boxShadow: "none",
                  },
                }}
                LabelStyle={{
                  color: "#FFFFFF",
                  fontSize: "16px !important",
                  fontWeight: "600",
                  lineHeight: "auto",
                }}
                label="Cancel"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DeleteDialog;
