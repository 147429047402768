import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import SettingsSidenavigation from "./settingsSidenavigation/SettingsSidenavigation";
import SettingsRoutes from "./settingsSidenavigation/SettingsRoutes";
import { useTheme } from "@mui/material/styles";
import Seperator2 from "../../shared/components/Seperator2";
import NewsletterSection from "../../main/NewsletterSection";

interface SettingsContextType {
  activeTab: any;
  setActiveTab: any;
}
export const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);
export const useSettingsContext = (): any => useContext(SettingsContext)!;
type Props = {};

const Setting = (props: Props) => {
  const [activeTab, setActiveTab] = useState(1);

  const theme = useTheme();
  const breakpoint_down_500 = useMediaQuery(theme.breakpoints.down(500));

  const settingsContextProps = {
    activeTab,
    setActiveTab,
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingY: { xs: "24px", sm: "40px", md: "80px" },
          paddingBottom: { xs: "0px" },
          alignItems: "center",
          gap: { xs: "32px", md: "80px" },
          width: "100%",
          // height:"1100px",
          // height: { xs: "100vh", sm: "", md: "1100px", lg: "1100px" },
          height: { xs: "auto", sm: "", md: "1100px", lg: "1100px" },
          // marginBottom:"0px"
        }}
      >
        {/* ------Header Section------ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: { xs: "left", sm: "center", lg: "center" },
            // gap: { xs: "12px", sm: "16px", lg: "16px" },
            gap: { xs: "12px", sm: "24px", lg: "24px" },
            width: "100%",
            paddingX: { xs: "24px", sm: "40px", md: "80px" },
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontWeight: "600",
              fontSize: "32px",
              background:
                "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              // width:"100%",
            }}
            // variant="h1"
          >
            Account Settings
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "rgba(255, 255, 255, 0.5)",
              fontWeight: "400",
              fontSize: "16px !important",
            }}
          >
            Manage your profile, security, payment and notification settings.
          </Typography>
        </Box>

        {/* ------Setting tab pannel------ */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            // border:"1px solid red",
            "@media screen and (min-width: 500px) and (max-width: 600px)": {
              marginBottom: "24px",
            },
          }}
        >
          <SettingsContext.Provider value={settingsContextProps}>
            <Box
              sx={{
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                gap: { xs: "32px", md: "48px" },
                minWidth: "100%",
              }}
              display="flex"
              justifyContent="center"
              width="100%"
              maxWidth="100%"
            >
              <SettingsSidenavigation />

              <Box
                sx={{
                  paddingX: { xs: "24px", sm: "40px", md: "0px" },
                  "@media screen and (min-width: 600px) and (max-width: 959px)":
                    {
                      margin: "0 auto",
                    },
                }}
              >
                <SettingsRoutes />
              </Box>
            </Box>
          </SettingsContext.Provider>
        </Box>
      </Box>

      {breakpoint_down_500 && (
        <Box
          sx={{
            mb: "48px",
            marginX: "24px",
            // mt: "40px",
          }}
        >
          {/* --------Seperator -------- */}
          <Box
            sx={{
              width: "100%",
              paddingY: { xs: "40px" },
              // marginBottom: { xs: "48px" },
              // border:"1px solid red"
            }}
          >
            <Seperator2 />
          </Box>

          {/* ---------Newsletter section--------- */}
          <NewsletterSection />
        </Box>
      )}
    </>
  );
};

export default Setting;
