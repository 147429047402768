import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Chip from "../../shared/components/Chip";
import NewsletterSection from "../../main/NewsletterSection";
import FaqQuestion from "./FaqQuestion";
import {
  componentsFaqs,
  faqs,
  generalFaqs,
  PricingFaqs,
  SupportAndFeedbackFaqs,
} from "../../utils/constants";

type Props = {};

const FaqScreen = (props: Props) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: { xs: "24px", sm: "80px", lg: "80px" },
          paddingBottom: { xs: "40px", sm: "80px" },
          alignItems: "center",
          gap: { xs: "40px", sm: "80px", lg: "80px" },
          width: "100%",
        }}
      >
        {/* ------Header Section------ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: "16px", sm: "24px", lg: "24px" },
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Chip
            sx={{
              height: "auto",
              borderRadius: "79px",
              // width: "160px",
              padding: "7px 20px",
              backgroundColor: "#1B1B1B",
            }}
            titleStyles={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "24px",
              letterSpacing: "0%",
              color: "#8B8B8B",
            }}
            title="FAQs"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: "16px",
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: "600",
                fontSize: { xs: "32px", sm: "32px", lg: "32px" },
                width: "100%",
                // maxHeight: { xs: "auto", sm: "64px", lg: "64px" },
                maxHeight: { xs: "auto", sm: "auto", lg: "auto" },
                height: "auto",
                // lineHeight: { xs: "auto", sm: "64px", lg: "64px" },
                lineHeight: { xs: "auto", sm: "auto", lg: "auto" },
                background:
                  "linear-gradient(81deg, rgba(255,255,255,1) 0%, rgba(48,48,48,1) 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                m: "0",
                p: "0",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                whiteSpace: "normal",
              }}
              // variant="h1"
            >
              Your Guide to UI Wiki
            </Typography>
            <Typography
              // variant="h4"
              sx={{
                color: "rgba(255, 255, 255, 0.5)",
                fontWeight: "400",
                fontSize: {
                  xs: "16px !important",
                  sm: "16px !important",
                  lg: "16px !important",
                },
                // fontSize: "24px !important",
                lineHeight: "auto",
                letterSpacing: "0%",
                m: "0",
                p: "0",
              }}
            >
              Find quick answers to help you get the most out of UI Wiki.
            </Typography>
          </Box>
        </Box>

        {/* ------Content Section------ */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "50px",
          }}
        >
          {/* -----General----- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              width: "100%",
              maxWidth: "900px",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "24px",
                width: "100%",
                height: "auto",
                lineHeight: "auto",
              }}
            >
              General
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              {generalFaqs?.map((faq: any, index: number) => (
                <FaqQuestion
                  key={index}
                  panel={`general-${index}`}
                  question={faq?.question}
                  description={faq?.description}
                  expanded={expanded}
                  handleChange={handleChange}
                />
              ))}
            </Box>
          </Box>

          {/* -----Components----- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              width: "100%",
              maxWidth: "900px",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "24px",
                width: "100%",
                height: "auto",
                lineHeight: "auto",
              }}
            >
              Components
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              {componentsFaqs?.map((faq: any, index: number) => (
                <FaqQuestion
                  key={index}
                  panel={`components-${index}`}
                  question={faq?.question}
                  description={faq?.description}
                  expanded={expanded}
                  handleChange={handleChange}
                />
              ))}
            </Box>
          </Box>

          {/* -----Pricing----- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              width: "100%",
              maxWidth: "900px",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "24px",
                width: "100%",
                height: "auto",
                lineHeight: "auto",
              }}
            >
              Pricing
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              {PricingFaqs?.map((faq: any, index: number) => (
                <FaqQuestion
                  key={index}
                  panel={`pricing-${index}`}
                  question={faq?.question}
                  description={faq?.description}
                  expanded={expanded}
                  handleChange={handleChange}
                />
              ))}
            </Box>
          </Box>

          {/* -----Support & Feedbacks----- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              width: "100%",
              maxWidth: "900px",
            }}
          >
            <Typography
              sx={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "24px",
                width: "100%",
                height: "auto",
                lineHeight: "auto",
              }}
            >
              Support & Feedback
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              {SupportAndFeedbackFaqs?.map((faq: any, index: number) => (
                <FaqQuestion
                  key={index}
                  panel={`support-${index}`}
                  question={faq?.question}
                  description={faq?.description}
                  expanded={expanded}
                  handleChange={handleChange}
                />
              ))}
            </Box>
          </Box>
        </Box>

        {/* --------Seperator -------- */}
        {/* <Box sx={{ width: "100%" }}>
        <Seperator2 />
      </Box> */}

        {/* -----Newsletter Section----- */}
        <Box sx={{ width: "100%" }}>
          <NewsletterSection />
        </Box>
      </Box>
    </>
  );
};

export default FaqScreen;
