import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ButtonPrimary } from "../../../../../shared/components/button/Buttons";
import { useGlobalContext } from "../../../../../App";
import {
  MONTHLY_SUBSCRIPTION_PLAN_ID,
  PADDLE_CLIENT_TOKEN,
  PADDLE_ENVIRONMENT,
  YEARLY_SUBSCRIPTION_PLAN_ID,
  LIFETIME_SUBSCRIPTION_PLAN_ID,
} from "../../../../../utils/constants";
import Cookies from "js-cookie";
import { getUserIdFromCookies } from "../../../../../utils/extensions";
import {
  updateSubscription,
  updateSubscriptionPreview,
} from "../../../../../services/user";
import ConfirmSwitchPlanDialog from "../membershipDialog/ConfirmSwitchPlanDialog";
import Chip from "../../../../../shared/components/Chip";
import NewPricingCardTickIconSvg from "../../../../../assets/icons/PricingPageSvgImages/NewPricingCardTickIconSvg";

type Props = {
  membershipCardsGap?: string;
  activePlan?: string;
  activeSubscriptions?: any;
};

declare global {
  interface Window {
    Paddle?: any;
  }
}
const NewPricingPageMembershipPlanCards = (props: Props) => {
  const {
    userDetails,
    setActiveLoginDialog,
    activeLoginDialog,
    activeSignupDialog,
    activeResetDialog,
    activeEmailSentCongratulationPopup,
  } = useGlobalContext();

  const { activePlan, activeSubscriptions } = props;

  const theme = useTheme();
  const userId = getUserIdFromCookies();
  const breakpoint_down_769 = useMediaQuery(theme.breakpoints.down(769));
  const breakpoint_Up_800 = useMediaQuery(theme.breakpoints.down(800));
  const breakpoint_up_900 = useMediaQuery(theme.breakpoints.up(900));
  const breakpoint_down_1100 = useMediaQuery(theme.breakpoints.down(1100));
  const isXs = useMediaQuery(theme.breakpoints.down(600));
  const carouselRef: any = useRef(null);

  const [loadingPlan, setLoadingPlan] = useState<string | null>(null);
  const [chooseRegularPlanLoading, setChooseRegularPlanLoading] =
    useState<boolean>(false);
  const [chooseLifetimePlanLoading, setChooseLifetimePlanLoading] =
    useState<boolean>(false);

  const [activeSubscription, setActiveSubscription] = useState<any>({});

  const [activeSwitchPlan, setActiveSwitchPlan] = useState<boolean>(false);
  const [planPreviewData, setPlanPreviewData] = useState<any>({});

  const handleConfirmUpdatePlan = async () => {
    setLoadingPlan("updating");
    await updateSubscription({
      userId,
      updatedPlanId: planPreviewData?.items[0]?.price?.id,
      currentSubscriptionId: activeSubscription?._id,
      updatedPlanName: planPreviewData?.items[0]?.price?.name,
    })
      .then((res: any) => {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        setLoadingPlan(null);
      })
      .catch((err) => {
        console.log("err");
        setLoadingPlan(null);
      });
  };

  const handleShowSubscriptionPreview = async (
    planName: string,
    planId: string
  ) => {
    await updateSubscriptionPreview({
      userId,
      updatedPlanName: planName,
      updatedPlanId: planId,
      currentSubscriptionId: activeSubscription?._id,
    })
      .then((res: any) => {
        setPlanPreviewData(res);
        setActiveSwitchPlan(true);
      })
      .catch((err) => console.log("err"));
  };

  const openPaddleCheckout = async (planId: string, planName: string) => {
    if (!Cookies.get("jstoken")) {
      setActiveLoginDialog(true);
      return;
    }

    setLoadingPlan(planName);

    if (
      (activePlan === "MONTHLY" && planName === "YEARLY") ||
      (activePlan === "YEARLY" && planName === "MONTHLY")
    ) {
      if (activeSubscription?.status !== "CANCELLED") {
        handleShowSubscriptionPreview(planName, planId);
        setLoadingPlan("");
        return;
      }
    }

    //@ts-ignore
    if (window.Paddle) {
      //@ts-ignore
      Paddle.Checkout.open({
        items: [
          {
            priceId: planId,
            quantity: 1,
          },
        ],
        settings: {
          displayMode: "overlay",
          theme: "light",
          variant: "one-page",
          locale: "en",
        },
        customData: {
          userId: userId,
          planName: planName,
        },
      });
    }
    setLoadingPlan("");
  };

  useEffect(() => {
    //@ts-ignore
    if (window.Paddle) {
      try {
        //@ts-ignore
        Paddle.Environment.set(PADDLE_ENVIRONMENT);
        //@ts-ignore
        Paddle.Initialize({
          token: PADDLE_CLIENT_TOKEN,
          eventCallback: function (data: any) {
            if (data.name === "checkout.completed") {
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          },
        });
      } catch (error) {
        console.error("Failed to initialize Paddle:", error);
      }
    } else {
      console.error("Paddle is not available");
    }
  }, []);

  useEffect(() => {
    //if activeSubscriptions is an array
    if (Array.isArray(activeSubscriptions)) {
      for (let subscription of activeSubscriptions) {
        if (subscription?.planType === activePlan) {
          setActiveSubscription(subscription);
        }
      }
    }
  }, [activeSubscriptions]);

  let monthlyPlanButtonOpacity: any = "100%";
  let yearlyPlanButtonOpacity: any = "100%";
  let lifetimePlanButtonOpacity: any = "100%";
  let monthlyPlanButtonLabel: any = "Get Monthly";
  let yearlyPlanButtonLabel: any = "Get Yearly";
  let lifetimePlanButtonLabel: any = "Get Lifetime";

  if (activePlan === "MONTHLY") {
    monthlyPlanButtonOpacity = "30%";
    monthlyPlanButtonLabel = "Active Plan";
    yearlyPlanButtonOpacity = "100%";
    yearlyPlanButtonLabel = "Switch to Yearly";
    lifetimePlanButtonOpacity = "100%";
    lifetimePlanButtonLabel = "Switch to Lifetime";
  } else if (activePlan === "YEARLY") {
    monthlyPlanButtonOpacity = "100%";
    monthlyPlanButtonLabel = "Switch to Monthly";
    yearlyPlanButtonOpacity = "30%";
    yearlyPlanButtonLabel = "Active Plan";
    lifetimePlanButtonOpacity = "100%";
    lifetimePlanButtonLabel = "Switch to Lifetime";
  } else if (activePlan === "LIFETIME") {
    monthlyPlanButtonOpacity = "30%";
    monthlyPlanButtonLabel = "Choose Plan";
    yearlyPlanButtonOpacity = "30%";
    yearlyPlanButtonLabel = "Choose Plan";
    lifetimePlanButtonOpacity = "30%";
    lifetimePlanButtonLabel = "Active Plan";
  }
  //Stop scrolling when popup is open
  useEffect(() => {
    if (
      activeLoginDialog ||
      activeSignupDialog ||
      activeResetDialog ||
      activeSwitchPlan ||
      activeEmailSentCongratulationPopup
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [
    activeLoginDialog,
    activeSignupDialog,
    activeResetDialog,
    activeSwitchPlan,
    activeEmailSentCongratulationPopup,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        // flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        // maxHeight:"554px",
        // height:"100%",
        // gap:"0px",
        // border:"1px solid red",
      }}
    >
      {activeSwitchPlan && (
        <ConfirmSwitchPlanDialog
          activeDialog={activeSwitchPlan}
          setActiveSwitchPlan={setActiveSwitchPlan}
          data={planPreviewData}
          handleConfirmUpdatePlan={handleConfirmUpdatePlan}
        />
      )}

      {/* Pricing Cards  */}
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          //   border:"1px solid red",
        }}
      > */}
      {/* --------pricing cards container-------- */}
      <Box
        sx={{
          display: "flex",
          // alignItems: "flex-start",
          // alignItems: "stretch",
          justifyContent: "center",
          width: "100%",
          gap: "10px",
          //   border: "1px solid red",
          flexDirection: breakpoint_Up_800 ? "column" : "row",
        }}
      >
        {/* ------Price card 1 for 10$ per month ------ */}
        <Box
          sx={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            // padding: { xs: "42px", sm: "52px", lg: "52px" },
            borderRadius: "20px",
            bgcolor: "#181818",
            padding: "52px",
            "@media (max-width: 1100px)": {
              padding: "24px", // 1100px se neeche
            },
            "@media (max-width: 799px)": {
              padding: "42px", // 800px se neeche
            },
          }}
        >
          {/* -----Price section----- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "12px", sm: "16px", lg: "16px" },
              // border:"1px solid red",
              // margin:"0 auto"
            }}
          >
            <Box>
              <Typography
                sx={{
                  letterSpacing: "0%",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  fontSize: { xs: "16px", sm: "18px", lg: "18px" },
                  lineHeight: "24px",
                }}
              >
                Monthly
              </Typography>
            </Box>

            <Typography
              sx={{
                fontSize: "58px",
                color: "#FFFFFF",
                fontWeight: "700",
                lineHeight: "66px",
                letterSpacing: "0%",
                "@media (min-width: 800px) and (max-width: 900px)": {
                  fontSize: "49px",
                },
              }}
            >
              $10
            </Typography>

            <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              <Chip
                sx={{
                  height: "auto",
                  borderRadius: "7px",
                  // width: "73px",
                  padding: "6px 14px",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  boxShadow: "none",
                }}
                titleStyles={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0%",
                  color: "#8B8B8B",
                }}
                title="Paid monthly"
              />
              {/* This one for dummy to increase height for all card at same to alignment  */}
              {!breakpoint_Up_800 && (
                <Chip
                  sx={{
                    borderRadius: "7px",
                    width: "167.8px",
                    height: "36px",
                    padding: "6px 14px",
                    background: "Transparent",
                    boxShadow: "none",
                  }}
                  title=""
                />
              )}
            </Box>
          </Box>

          {/* ------Pricing Details------ */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: breakpoint_Up_800 ? "fit-content" : "100%",
              mt: breakpoint_Up_800 ? "42px" : "52px",
              mb: breakpoint_Up_800 ? "42px" : "none",
              // ml:breakpoint_Up_800 ?"auto" :"none",
              // mr:breakpoint_Up_800 ?"auto" :"none",
              // border:"1px solid red"
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                2500+ UI components
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                1 month access
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                Cancel anytime
              </Typography>
            </Box>
          </Box>

          {/* <Box sx={{ border: "1px solid red", widht: "100%", height: "100%" }}> */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              // border: "1px solid red",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <ButtonPrimary
              // isLoading={chooseRegularPlanLoading}
              isLoading={loadingPlan === "MONTHLY"}
              onClick={() => {
                if (activePlan === "MONTHLY" || activePlan === "LIFETIME") {
                  return;
                }
                openPaddleCheckout(MONTHLY_SUBSCRIPTION_PLAN_ID, "MONTHLY");
              }}
              sx={{
                maxWidth: "195px",
                width: "100%",
                height: "60px",
                padding: "15px 35px",
                borderRadius: "49px",
                opacity: monthlyPlanButtonOpacity,
              }}
              label={monthlyPlanButtonLabel}
              LabelStyle={{
                fontSize: "16px", // in figma 18px
                fontWeight: "600",
                lineHeight: "auto",
                letterSpacing: "0%",
                color: "#0C0C0C",
              }}
            />
          </Box>
          {/* </Box> */}
        </Box>

        {/* ------Price card 2 for 6$ per month for year------ */}
        <Box
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            // padding: { xs: "42px", sm: "52px", lg: "52px" },
            borderRadius: "20px",
            bgcolor: "#181818",
            padding: "52px",
            "@media (max-width: 1100px)": {
              padding: "24px", // 1100px se neeche
            },
            "@media (max-width: 799px)": {
              padding: "42px", // 800px se neeche
            },
          }}
        >
          {/* -----Price section----- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "12px", sm: "16px", lg: "16px" },
              // border:"1px solid red",
            }}
          >
            <Box>
              <Typography
                sx={{
                  letterSpacing: "0%",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  fontSize: { xs: "16px", sm: "18px", lg: "18px" },
                  lineHeight: "24px",
                }}
              >
                Yearly
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: { xs: "18px", sm: "15px", lg: "15px" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "58px",
                  color: "#FFFFFF",
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                  lineHeight: "66px",
                  letterSpacing: "0%",
                  gap: "12px",
                  "@media (min-width: 800px) and (max-width: 900px)": {
                    fontSize: "49px",
                  },
                }}
              >
                $72
              </Typography>
              <span
                style={{
                  fontSize: isXs ? "18px" : "22px",
                  fontWeight: "600",
                  color: "#3B3B3B",
                  lineHeight: "auto",
                  letterSpacing: "0%",
                  height: "27px",
                  // border:"1px solid red",
                  marginTop: "8px",
                }}
              >
                $6/mo
              </span>
            </Box>

            <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              <Chip
                sx={{
                  height: "auto",
                  borderRadius: "7px",
                  // width: "73px",
                  padding: "6px 14px",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  boxShadow: "none",
                }}
                titleStyles={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0%",
                  color: "#8B8B8B",
                }}
                title="Paid yearly"
              />
              <Chip
                sx={{
                  height: "auto",
                  borderRadius: "7px",
                  // width: "73px",
                  padding: "6px 14px",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  boxShadow: "none",
                }}
                titleStyles={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0%",
                  color: "#CF0",
                }}
                title="Save 40%"
              />
              {/* This one for dummy to increase height for all card at same to alignment  */}
              {breakpoint_up_900 && (
                <Chip
                  sx={{
                    width: "82.5px",
                    height: "35px",
                    // padding: "6px 14px",
                    background: "transparent",
                    boxShadow: "none",
                  }}
                  title=""
                />
              )}
            </Box>
          </Box>

          {/* ------Pricing Details------ */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              mt: breakpoint_Up_800 ? "42px" : "52px",
              mb: breakpoint_Up_800 ? "42px" : "none",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                2500+ UI components
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                1 year access
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                Cancel anytime
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              // margin: "0 auto",
              // widht: "100%",
              display: "flex",
              alignItems: "flex-end",
              // border: "1px solid red",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <ButtonPrimary
              // isLoading={chooseRegularPlanLoading}
              isLoading={loadingPlan === "YEARLY"}
              sx={{
                maxWidth: "195px",
                width: "100%",
                height: "60px",
                padding: "15px 35px",
                borderRadius: "49px",
                opacity: yearlyPlanButtonOpacity,
              }}
              onClick={() => {
                if (activePlan === "YEARLY" || activePlan === "LIFETIME") {
                  return;
                }

                openPaddleCheckout(YEARLY_SUBSCRIPTION_PLAN_ID, "YEARLY");
              }}
              label={yearlyPlanButtonLabel}
              LabelStyle={{
                fontSize: "16px", // in figma 18px
                fontWeight: "600",
                lineHeight: "auto",
                letterSpacing: "0%",
                color: "#0C0C0C",
              }}
            />
          </Box>
        </Box>

        {/* ------Price card 3 for lifetime ------ */}
        <Box
          sx={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            // padding: { xs: "42px", sm: "52px", lg: "52px" },
            borderRadius: "20px",
            bgcolor: "#181818",
            padding: "52px",
            "@media (max-width: 1100px)": {
              padding: "24px", // 1100px se neeche
            },
            "@media (max-width: 799px)": {
              padding: "42px", // 800px se neeche
            },
          }}
        >
          {/* -----Price section----- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "12px", sm: "16px", lg: "16px" },
              // border:"1px solid red",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: "400",
                  color: "#CF0",
                  fontSize: { xs: "16px", sm: "18px", lg: "18px" },
                  lineHeight: "24px",
                  letterSpacing: "0%",
                }}
              >
                Lifetime
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: { xs: "18px", sm: "15px", lg: "15px" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "58px",
                  // height: "66px",
                  color: "#FFFFFF",
                  fontWeight: "700",
                  lineHeight: "66px",
                  letterSpacing: "0%",
                  // border:"1px solid red"
                  "@media (min-width: 800px) and (max-width: 900px)": {
                    fontSize: "49px",
                  },
                }}
              >
                $99
              </Typography>
              <span
                style={{
                  fontSize: isXs ? "18px" : "22px",
                  fontWeight: "600",
                  color: "#3B3B3B",
                  lineHeight: "auto",
                  letterSpacing: "0%",
                  height: "27px",
                  // border:"1px solid red",
                  textDecoration: "line-through",
                  marginTop: "7px",
                }}
              >
                $250
              </span>
            </Box>

            <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              <Chip
                sx={{
                  height: "auto",
                  borderRadius: "7px",
                  // width: "73px",
                  padding: "6px 14px",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  boxShadow: "none",
                }}
                titleStyles={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0%",
                  color: "#8B8B8B",
                }}
                title="One-time payment"
              />
              <Chip
                sx={{
                  height: "auto",
                  borderRadius: "7px",
                  // width: "73px",
                  padding: "6px 14px",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  boxShadow: "none",
                }}
                titleStyles={{
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  letterSpacing: "0%",
                  color: "#CF0",
                }}
                title="Limited time offer"
              />
            </Box>
          </Box>

          {/* ------Pricing Details------ */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
              mt: breakpoint_Up_800 ? "42px" : "52px",
              mb: breakpoint_Up_800 ? "42px" : "52px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                2500+ UI components
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                Lifetime access
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                Access to beta features
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <NewPricingCardTickIconSvg />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#8B8B8B",
                  lineHeight: "22px",
                  letterSpacing: "0%",
                }}
              >
                Priority support
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              // margin: "0 auto",
              // widht: "100%",
              display: "flex",
              alignItems: "flex-end",
              // border: "1px solid red",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <ButtonPrimary
              sx={{
                maxWidth: "195px",
                width: "100%",
                height: "60px",
                padding: "15px 35px",
                borderRadius: "49px",
                opacity: lifetimePlanButtonOpacity,
              }}
              label={lifetimePlanButtonLabel}
              LabelStyle={{
                fontSize: "16px", // in figma 18px
                fontWeight: "600",
                lineHeight: "auto",
                letterSpacing: "0%",
                color: "#0C0C0C",
              }}
              onClick={() => {
                if (activePlan === "LIFETIME") {
                  return;
                }
                openPaddleCheckout(LIFETIME_SUBSCRIPTION_PLAN_ID, "LIFETIME");
              }}
              // isLoading={chooseLifetimePlanLoading}
              isLoading={loadingPlan === "LIFETIME"}
            />
          </Box>
        </Box>
      </Box>
      {/* </Box> */}
    </Box>
  );
};

export default NewPricingPageMembershipPlanCards;
