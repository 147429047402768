import React from "react";

const NewsletterNewIconSvg = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6825_8803)">
        <path
          d="M54.3345 24.1055C54.6645 25.5905 54.8558 27.1167 54.8558 28.6842C54.8558 40.7892 44.5808 50.8805 31.0508 53.048C33.912 54.203 37.1183 54.878 40.5345 54.878C43.7558 54.878 46.797 54.2855 49.542 53.2542C53.0183 54.4392 56.8133 54.7467 59.952 54.7467C58.5347 53.047 57.3342 51.1777 56.3783 49.1817C59.8583 46.0767 62.0033 41.963 62.0033 37.433C62.0033 32.078 59.0145 27.3042 54.3345 24.1055Z"
          fill="#3D3D3D"
        />
        <path
          d="M4.75625 49.6656C5.0825 50.2844 5.72375 50.6706 6.4175 50.6706C6.4475 50.6706 6.48125 50.6706 6.5075 50.6669C8.49125 50.5731 12.4363 49.2569 16.6138 46.6056C18.8488 46.9994 23.855 47.7419 25.6025 47.7419C38.7913 47.7419 49.1188 38.5581 49.1188 26.8319C49.1188 15.3006 38.5738 5.92188 25.6025 5.92188C12.6313 5.92188 2.09375 15.2969 2.09375 26.8281C2.09375 32.1944 4.45625 37.3806 8.615 41.2619C7.67536 43.5453 6.43706 45.694 4.9325 47.6519C4.71424 47.9358 4.58169 48.2762 4.55046 48.633C4.51923 48.9898 4.59063 49.3481 4.75625 49.6656ZM5.84375 26.8281C5.84375 17.3669 14.7087 9.66812 25.61 9.66812C36.5113 9.66812 45.3763 17.3669 45.3763 26.8281C45.3763 36.6081 36.8788 43.9881 25.61 43.9881C24.1288 43.9881 18.2338 43.1294 16.6138 42.7881C16.3705 42.7373 16.1196 42.7353 15.8756 42.7823C15.6317 42.8293 15.3994 42.9244 15.1925 43.0619C13.7803 44.0019 12.2941 44.8258 10.7488 45.5256C11.465 44.1794 12.0838 42.7844 12.6013 41.3444C12.8675 40.5981 12.6388 39.7656 12.0313 39.2631C8.04125 35.9819 5.84375 31.5644 5.84375 26.8281Z"
          fill="#CCFF00"
        />
        <path
          d="M19 23H28"
          stroke="#CCFF00"
          stroke-width="3"
          stroke-linecap="round"
        />
        <path
          d="M19 31H33"
          stroke="#CCFF00"
          stroke-width="3"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6825_8803">
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewsletterNewIconSvg;
