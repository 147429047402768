import React from "react";

const EditComponentSvgIcon = () => {
  return (
    <svg
      width="140"
      height="136"
      viewBox="0 0 140 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90.4167 72.986L50.925 113.786C49.3416 115.432 47.4268 116.744 45.2993 117.641C43.1719 118.538 40.8774 119 38.5583 118.999H23.3333C21.7862 118.999 20.3025 118.402 19.2085 117.34C18.1146 116.277 17.5 114.836 17.5 113.333V100.866C17.4996 98.6862 17.9426 96.5278 18.8035 94.5148C19.6644 92.5018 20.9263 90.6738 22.5167 89.136L64.8667 48.166L90.4167 72.986Z"
        stroke="#212121"
        stroke-width="6.625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M117.3 46.4191L105.634 57.7525L108.842 60.8691C110.455 62.9502 111.247 65.5247 111.072 68.1244C110.897 70.7241 109.767 73.1765 107.888 75.0358C106.01 76.8951 103.507 78.0378 100.834 78.2561C98.1617 78.4745 95.4973 77.7541 93.3253 76.2258L90.1753 73.1091L61.592 45.3425C60.0352 43.8288 58.9751 41.9009 58.5458 39.8022C58.1164 37.7035 58.337 35.5283 59.1798 33.5514C60.0225 31.5745 61.4495 29.8846 63.2805 28.6953C65.1115 27.5059 67.2644 26.8704 69.467 26.8691C72.4295 26.8593 75.2778 27.9782 77.4003 29.9858L80.6087 33.1591L92.2753 21.8258C95.6016 18.5946 100.113 16.7793 104.817 16.7793C109.521 16.7793 114.032 18.5946 117.359 21.8258C120.685 25.057 122.554 29.4395 122.554 34.0091C122.554 38.5788 120.685 42.9612 117.359 46.1925L117.3 46.4191Z"
        stroke="#212121"
        stroke-width="6.625"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EditComponentSvgIcon;
