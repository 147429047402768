import React, { useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CloseSharp } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../shared/components/button/Buttons";
import RightTick from "../assets/icons/RightTickIcon/RightTick";
import useEnterKeyPress from "../utils/useEnterKeyPress";
import { useGlobalContext } from "../App";
import { useTheme } from "@mui/material/styles";

type Props = {
  activeDialog: any;
  handleDialog?: any;
  setActiveDialog: any;
  email?: string;
  redirecturl?: string;
};

const ResetPasswordRecoveryMailSentPopup: React.FC<Props> = (props) => {
  const {
    setActiveLoginDialog,
    setActiveResetDialog,
    emailForRecoveryPassoword,
  } = useGlobalContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleContinue = async () => {
    props?.handleDialog?.();
    setActiveLoginDialog(false);
    setActiveResetDialog(false);
    navigate("/");
    props?.setActiveDialog(false);
  };

  const handleEnterKeyPress = () => {
    handleContinue();
  };

  // Use the custom hook
  useEnterKeyPress(handleEnterKeyPress);

  return (
    props?.activeDialog && (
      <>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.60)",
            zIndex: 5000,
            // border:"1px solid red",
          }}
        >
          {/* Apply blur effect to only this background */}
          <Box
            sx={{
              width: "100vw",
              height: "100%",
              bgcolor: "rgba(0, 0, 0, 0.60)",
              filter: "blur(62px)",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 6000,
            maxWidth: { xs: "90%", sm: "448px", lg: "448px" },
            width: { xs: "100%", sm: "100%", lg: "100%" },
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "100%", sm: "448px", lg: "448px" },
              width: "448px",
              height: "auto",
              // height: "394px",
              bgcolor: "#1B1B1B",
              borderRadius: "24px",
              border: "1px solid #3D3D3D",
              cursor: "default",
              position: "relative",
              padding: "24px",
              margin: "0 auto",
            }}
          >
            {/* Dialog content */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                zIndex: 999999999,
                // gap:{xs:"0px",sm:"9.5px",lg:"9.5px"}
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <CloseSharp
                  onClick={(e) => {
                    e.stopPropagation();
                    props?.setActiveDialog(false);
                  }}
                  sx={{
                    position: "absolute",
                    right: "24px",
                    top: "24px",
                    cursor: "pointer",
                    color: "white",
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginTop: { xs: "28px", sm: "36px", lg: "36px" },
                  marginBottom: { xs: "24px", sm: "21.5px", lg: "21.5px" },
                }}
              >
                {isXs ? (
                  <RightTick width={120} height={120} />
                ) : (
                  <RightTick width={123.5} height={123.5} />
                )}
              </Box>

              <Box
                sx={{
                  // height: "50%",
                  // maxWidth: "516px",
                  //   maxWidth: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "24px", sm: "32px", lg: "32px" },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      m:"0px",
                      p:"0px",
                      fontSize: { xs: "24px", sm: "24px", lg: "24px" },
                      lineHeight: { xs: "28px", sm: "auto", lg: "auto" },
                      fontWeight: "600",
                      color: "#FFFFFF",
                      mb: { xs: "12px", sm: "12px", lg: "12px" },
                      textAlign: "center",
                      // border:"1px solid red"
                    }}
                  >
                    Recovery email sent!
                  </Typography>
                  <Typography
                    sx={{
                      m:"0px",
                      p:"0px",
                      fontSize: "16px !important",
                      fontWeight: "400",
                      lineHeight:"auto",
                      color: "#FFFFFF",
                      opacity: "50%",
                      // width:"353px",
                      // paddingX: { xs: "24px",sm: "0px",lg: "0px" },
                      width: { xs: "100%",sm: "90%",lg: "90%" },
                      // padding: "0px 41px",
                      textAlign: "center",
                      // border:"1px solid red"
                    }}
                  >
                    A Link to reset your password has been sent to you on{" "}
                    {emailForRecoveryPassoword ? emailForRecoveryPassoword : ""}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    // height: "300%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    // gap: "10px",
                  }}
                >
                  <ButtonPrimary
                    label="Continue"
                    sx={{
                      maxWidth: { xs: "100%", sm: "166px", lg: "166px" },
                      width: "100%",
                      fontSize: "16px !important",
                      fontWeight: "600",
                      lineHeight:"auto",
                      color: "#000000",
                      // margin: {
                      //   // xs: "0px 24px 24px 24px",
                      //   // sm: "32px 40px 40px 40px",
                      //   // lg: "32px 40px 40px 40px",
                      //   sm: "0px 0px 0px 0px",
                      //   lg: "px 0px 0px 0px",
                      // },
                      borderRadius: "16px",
                      paddingY: "16px",
                      border: "1px solid #526600",
                    }}
                    LabelStyle={{
                      fontSize: "16px !important",
                      fontWeight: "600",
                      color: "#000000",
                    }}
                    onClick={() => {
                      handleContinue();
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  );
};

export default ResetPasswordRecoveryMailSentPopup;
